import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';
import { getArea } from '../../store/actions/area';

import BookingForm from '../../components/BookingForm';

import Translate from '../../components/Translate';
import NotFound from '../error';

class Booking extends Component {
  state = {
    showErrorPage: false,
  };

  componentDidMount() {
    this.props.setUIParam('page', 'booking');
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isDataLoaded) {
      Loader.hideGroup('pageLoader');
      if (!this.props.area?.bookable) {
        this.props.setUIParam('page', 'error');
      }
    }
  }

  render() {
    const { showErrorPage } = this.state;
    const { area } = this.props;

    if (showErrorPage || !area?.bookable) {
      return <NotFound />;
    }

    return (
      <>
        <div className="container-header">
          <h1>
            <Translate>Booking</Translate>
          </h1>
        </div>
        <div className="container-inner">
          <div className="row flex-row-reverse">
            <div className="col col-12 col-lg-4 col-xl-4 mb-4">
              <div className="d-block p-4 background-zanah">
                <h2 className="text-center mb-3 w-100">
                  <Translate>Your Booking Details</Translate>
                </h2>
                <div className="card w-100">
                  <div className="img-wrapper">
                    <img
                      src={area?.image_url}
                      className="card-img-top mx-auto left-0 right-0 h-100"
                      style={{ width: 'auto', maxWidth: '100%' }}
                      alt=""
                    />
                  </div>

                  <div className="card-body px-0">
                    <p className="card-text color-dark opacity-1 text-left">
                      <Translate>You are booking a visit to Khosrov Forest SR Area.</Translate>
                    </p>
                  </div>
                </div>
                <div className="color-dark">
                  <p className="font-size-18 mb-0">
                    <Translate>Your booking includes</Translate>:
                  </p>
                  <p className="font-size-18 mb-0">
                    - <Translate>Entry ticket</Translate>
                  </p>
                  <p className="font-size-18 mb-0">
                    - <Translate>Guidet tour</Translate>
                  </p>
                  <p className="font-size-18 mb-0">
                    - <Translate>Guidet tour line 2</Translate>
                  </p>
                </div>
              </div>
            </div>

            <div className="col col-12 col-lg-8 col-xl-8">
              <BookingForm area={area} />
            </div>
          </div>
        </div>
      </>
    );
  }

  loadData = () => {
    Loader.addToGroup(Loader.show(), 'bookingPage');

    const { areaId } = this.props.match.params;

    return Promise.all([this.props.getArea(areaId)])
      .finally(() => Loader.hideGroup('bookingPage'))
      .then(d => {
        console.log('data: ', d);
        for (let i = 0; i < d.length; i++) {
          if (d[i].statusCode === 404) {
            this.setState({
              showErrorPage: true,
            });
            break;
          }
        }
      });
  };
}

function mapStateToProps(state) {
  return {
    isDataLoaded: state.area.current.loaded,
    area: state.area.current.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUIParam(key, val) {
      return dispatch(setParam(key, val));
    },
    getArea(areaId) {
      return dispatch(getArea(areaId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
