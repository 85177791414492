import AFRAME, { THREE, registerComponent } from "aframe";

export default registerComponent("placement", {
  schema: {
    autoPlacement: {
      type: "boolean",
      default: false
    },
    refresh: {}
  },

  init: function init() {
    this.camera = this.el.sceneEl.camera;

    this.pivot = new THREE.Object3D();
    this.pivot.add(this.el.object3D);
    this.el.sceneEl.object3D.add(this.pivot);
  },

  update: function update({ refresh }) {
    // because when we don't assign value to attribute react by default set it true
    if (this.data === true) {
      this.data = this.nextData;
    }

    if (this.data.refresh !== refresh) {
      this.placeObject();
    }
  },

  tick: function tick() {
    if (this.data.autoPlacement) {
      this.placeObject();
    }
    this.pivot.position.copy(this.camera.getWorldPosition(new THREE.Vector3()));
  },

  placeObject: function placeObject() {
    const direction = new THREE.Vector3(0, 0, 1);
    direction.applyQuaternion(this.camera.parent.quaternion);

    direction.y = 0;

    this.pivot.quaternion.setFromUnitVectors(
      new THREE.Vector3(0, 0, 1),
      direction.normalize()
    );
  }
});
