import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';
import Translate from '../../components/Translate';
import { withLocalize } from 'react-localize-redux';

const termsContentByLang = {
  en: (
    <>
      <p className="font-weight-semi-bold font-size-18">Terms of Service</p>
      <p className="font-size-18">Thank you for visiting our website.</p>
      <p className="font-size-18">
        The following terms (hereinafter &quot;Terms&quot;) apply to users and visitors of the www.protectedareas.am
        website (hereinafter &quot;Website&quot;). By accessing and using this Website, you accept the Terms, Cookie and
        Privacy Policy. If you do not agree to abide by these terms, please do not use the Website.
      </p>
      <p className="font-size-18">
        The Website is operated by the Ministry of Environment of the Republic of Armenia (hereinafter
        &quot;Ministry&quot;). Terms of Service may be changed by the Ministry at any time without prior notice.
      </p>
      <p className="font-weight-semi-bold font-size-18">Terms</p>
      <p className="font-size-18">Right to Property</p>
      <p className="font-size-18">
        The photos, videos, audio materials, texts and other materials posted on the Website belong to the RA Ministry
        of Environment and the World Wide Fund for Nature Armenian Branch. Their use is restricted by copyright. They can only be used
        when:
      </p>

      <ul className="list-unstyled pl-4">
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>indicating the source of materials,</span>
        </li>
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>linking to the Webiste,</span>
        </li>
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>protecting copyright and trademarks.</span>
        </li>
      </ul>

      <p className="font-size-18">Use of Materials</p>
      <p className="font-size-18">
        No materials posted on the Website may be used or downloaded without informing the Ministry of Environment of
        Armenia and the World Wide Fund for Nature Armenian Branch and obtaining their express written consent.
      </p>
      <p className="font-size-18">
        The materials on the Website may not be used for commercial or advertising purposes without written consent of
        the above-mentioned organizations.
      </p>
      <p className="font-size-18">
        Failure to comply with the above-mentioned conditions may result in criminal liability.
      </p>
      <p className=" font-size-18">Rules of Conduct</p>

      <p className="font-size-18">
        It is forbidden to use the Website for purposes other than that intended, that is to subject it to attacks,
        intentionally infect it with viruses, logic bombs or other materials that may be technologically harmful. You
        must not attempt to gain unauthorised access to the Website, the server or the database. You must not attempt to
        access this Website, server or database without authorization. Otherwise, you will incur criminal liability.
      </p>
      <p className="font-size-18">Links to Third-Party Websites</p>
      <p className="font-size-18">
        This Website may contain links to third-party sites that are not controlled by the Ministry. These links are
        provided to enhance the effectiveness and convenience of the page and they are in no way considered advertising
        or sponsorship materials. The Ministry is not responsible for the content and security of these pages.
      </p>
      <p className="font-size-18">User-Created Content</p>
      <p className="font-size-18">
        There is a &quot;Forum&quot; section on this Website for comments. The purpose of this component is to create an
        environment for open discussion where healthy debate can take place among various people. The Ministry reserves
        the right to monitor and delete comments that do not comply with etiquette rules.
      </p>
      <p className="font-weight-semi-bold font-size-18">Privacy Policy</p>
      <p className="font-size-18">
        By visiting this Website, you agree that the information you provide may be collected and used for analysis,
        such as the number of visitors to the Website or the pages viewed. This information is used to improve this
        Website.
      </p>
      <p className="font-size-18">
        This website uses cookies to improve your user experience. Cookies cannot be used to obtain personal data, they
        simply store information such as your password or the process and the sequence of your actions on the website.
      </p>
      <p className="font-size-18">
        When shopping online, we ask for personal information to process your purchases and check your payment card
        details. We receive your personal credit card details by using SSL technology, which prevents your information
        from being disclosed to third-parties. SSL encrypts all your personal data, including credit card number, name
        and address.
      </p>
      <p className="font-size-18">
        We work with financial institutions of one or more third-parties to carry out payment card transactions. They
        receive payment card information and other personal data to verify the accuracy of payment card information and
        to carry out card transactions in their secure environment.
      </p>
      <p className="font-size-18">
        Our online payment system is managed by our partner bank, which collects your name, address, phone, card number
        and expiration date to process your transaction. Their encryption technology provides a high level of security.
        Every transaction is confidential.
      </p>
    </>
  ),
  hy: (
    <>
      <p className="font-weight-semi-bold font-size-18">Օգտագործման կանոններ</p>
      <p className="font-size-18">Շնորհակալություն մեր կայք այցելելու համար:</p>
      <p className="font-size-18">
        Հետևյալ պայմանները (այսուհետ՝ «Պայմաններ») տարածվում են www.protectedareas.am կայքի (այսուհետ՝ «Կայքի»)
        օգտատերերի և այցելուների վրա: Մուտք գործելով այս Կայք և դրանից օգտվելով՝ դուք համաձայնվում եք սույն Պայմանների,
        անձնական տվյալների պաշտպանության կանոնների և cookie ֆայլերի օգտագործման քաղաքականության հետ: Եթե համաձայն չեք և
        չեք ընդունում այս Պայմանները, ապա խնդրում ենք լքել կայքը։
      </p>
      <p className="font-size-18">
        Կայքը շահագործվում է ՀՀ շրջակա միջավայրի նախարարության (այսուհետ՝ «Նախարարության») կողմից։ Օգտագործման կանոնները
        ցանկացած պահի կարող են փոփոխվել Նախարարության կողմից առանց ծանուցման։
      </p>
      <p className="font-weight-semi-bold font-size-18">Պայմաններ</p>
      <p className="font-size-18">Սեփականության իրավունք</p>
      <p className="font-size-18">
        Կայքում տեղադրված լուսանկարները, տեսանյութերը, լսողական նյութերը, տեքստերը, այլ նյութերը պատկանում են ՀՀ շրջակա
        միջավայրի նախարարությանը և Բնության համաշխարհային հիմնադրամի հայաստանյան մասնաճյուղին։ Դրանց օգտագործումը
        սահմանափակվում է հեղինակային իրավունքով։ Դրանք կարող են օգտագործվել միայն՝
      </p>
      <ul className="list-unstyled pl-4">
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>նշելով նյութերի աղբյուրը,</span>
        </li>
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>հղում տալով Կայքին,</span>
        </li>
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>պահպանելով հեղինակային իրավունքները և առևտրային նշանները։</span>
        </li>
      </ul>
      <p className="font-size-18">Նյութերի օգտագործման կանոններ</p>
      <p className="font-size-18">
        Կայքում տեղադրված նյութերը չեն կարող ներբեռնվել և օգտագործվել առանց ՀՀ շրջակա միջավայրի նախարարությանը և
        Բնության համաշխարհային հիմնադրամի հայաստանյան մասնաճյուղին տեղյակ պահելու և վերջիններիս գրավոր թույլտվությունը
        ստանալու։
      </p>
      <p className="font-size-18">
        Կայքի նյութերը չեն կարող օգտագործվել կոմերցիոն կամ գովազդային նպատակներով առանց վերոնշյալ կազմակերպությունների
        գրավոր համաձայնության։
      </p>
      <p className="font-size-18">
        Վերոնշյալ կանոնները չպահպանելու դեպքում կարող եք ենթարկվել քրեական պատասխանատվության։
      </p>
      <p className=" font-size-18">Վարքագծի կանոններ</p>
      <p className="font-size-18">
        Արգելվում է Կայքն օգտագործել ոչ նպատակային կերպով՝ ենթարկելով գրոհների, գիտակցաբար ներմուծելով վիրուսներ,
        տրամաբանական ռումբեր կամ այլ նյութեր, որոնք կարող են վնասակար լինել տեխնոլոգիական տեսանկյունից: Դուք չպետք է
        փորձեք չարտոնված մուտք գործել սույն Կայք, սերվեր կամ տվյալների շտեմարան։ Հակառակ դեպքում կարող եք ենթարկվել
        քրեական պատասխանատվության։
      </p>
      <p className="font-size-18">Երրորդ կողմին պատկանող էջեր</p>
      <p className="font-size-18">
        Սույն կայքում կարող են լինել երրորդ կողմին պատկանող էջերին տանող հղումներ, որոնք չեն վերահսկվում Նախարարության
        կողմից: Տվյալ հղումները ներկայացված են էջի արդյունավետությունը և հարմարավետությունը բարձրացնելու համար և ոչ մի
        կերպ չեն հանդիսանում գովազդային կամ հովանավորչական նյութեր։ Տվյալ էջերի բովանդակության, ինչպես նաև անվտանգության
        համար Նախարարությունը պատասխանատվություն չի կրում։
      </p>
      <p className="font-size-18">Օգտատիրոջ կողմից ստեղծված նյութեր</p>
      <p className="font-size-18">
        Սույն Կայքում առկա է Forum բաժին, որը նախատեսված է մեկնաբանությունների համար։ Այս բաղադրիչի նպատակն է ձևավորել
        բաց քննարկման միջավայր, որտեղ կծավալվեն առողջ քննարկումներ տարբեր մարդկանց միջև։ Նախարարությունն իրեն իրավունք է
        վերապահում վերահսկել և ջնջել ոչ պատշաճ և քաղաքավարության կանոններին չհամապատասխանող մեկնաբանությունները։
      </p>
      <p className="font-weight-semi-bold font-size-18">Գաղտնիության քաղաքականություն</p>
      <p className="font-size-18">
        Սույն Կայք այցելելիս՝ դուք հայտնում եք ձեր համաձայնությունն առ այն, որ ձեր կողմից տրամադրված տվյալները կարող են
        հավաքագրվել և օգտագործվել այնպիսի վերլուծության համար, ինչպիսին է օրինակ Կայքի այցելուների քանակը կամ դիտվող
        էջերը: Այս տեղեկատվությունն օգտագործվում է սույն Կայքի բարելավման համար:
      </p>{' '}
      <p className="font-size-18">
        Սույն Կայքը օգտագործում է cookie ֆայլեր՝ օգտագործողի հարմարավետությունը բարելավելու համար: Cookie-ն չի կարող
        օգտագործվել անձնական տվյալների հավաքագրման համար, այն պարզապես պահպանում է այնպիսի տեղեկատվություն, ինչպիսիք են
        ձեր գաղտնաբառը կամ գործողությունների ընթացքը, հերթականությունը կայքում:
      </p>
      <p className="font-size-18">
        Առցանց գնումներ կատարելիս մենք հարցնում ենք անձնական տվյալներ՝ ձեր գնումները մշակելու և վճարային քարտի տվյալները
        ստուգելու համար: Մենք ստանում ենք ձեր անձնական և վճարային քարտի տվյալները՝ օգտագործելով SSL տեխնոլոգիա, որը
        կանխում է ձեր տեղեկատվության հրապարակումը երրորդ կողմերին: SSL-ը կոդավորում է ձեր բոլոր անձնական տվյալները,
        ներառյալ վարկային քարտի համարը, անունը և հասցեն:
      </p>
      <p className="font-size-18">
        Մենք աշխատում ենք մեկ կամ մի քանի երրորդ կողմի ֆինանսական հաստատությունների հետ՝ վճարային քարտով գործարքներն
        իրականացնելու համար: Նրանք ստանում են վճարային քարտի տվյալները և այլ անձնական տեղեկատվություն` վճարային քարտի
        տվյալների ճշգրտությունը հաստատելու և քարտով գործարքներն իրենց անվտանգ միջավայրում իրականացնելու համար:
      </p>
      <p className="font-size-18">
        Վճարային քարտերի հաստատման մեր առցանց համակարգը կառավարվում է մեր գործընկեր բանկի կողմից, որը հավաքում է ձեր
        անունը, հասցեն, հեռախոսը և վճարային քարտի համարը, ժամկետի ավարտը` ձեր գործարքը մշակելու համար: Նրանց գաղտնագրման
        տեխնոլոգիան ապահովում է անվտանգության բարձր մակարդակ: Յուրաքանչյուր գործարք գաղտնի է:
      </p>
    </>
  ),
  ru: (
    <>
      <p className="font-weight-semi-bold font-size-18">Правила пользования</p>
      <p className="font-size-18">Спасибо, что посетили наш сайт.</p>
      <p className="font-size-18">
        Следующие условия (далее – «Условия») распространяются на пользователей и посетителей сайта
        www.protectedareas.am (далее – «Сайт»). Заходя на данный Сайт и пользуясь им, вы соглашаетесь с настоящими
        Условиями, политикой конфиденциальности и использования файлов cookie. Если вы не согласны и не принимаете
        настоящие Условия, то просим вас покинуть сайт.
      </p>
      <p className="font-size-18">
        Сайт находится в ведении Министерства окружающей среды Республики Армения (далее – «Министерство»). Правила
        пользования могут быть изменены Министерством в любое время без предварительного уведомления.
      </p>
      <p className="font-weight-semi-bold font-size-18">Условия</p>
      <p className="font-size-18">Право на собственность</p>
      <p className="font-size-18">
        Размещенные на сайте фотографии, видео- и аудиоматериалы, тексты и иные материалы принадлежат Министерству
        окружающей среды РА и армянскому филиалу Всемирного фонда дикой природы: их использование ограничивается
        авторским правом. Использование вышеуказанных материалов возможно только при:
      </p>

      <ul className="list-unstyled pl-4">
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>указании источника материалов,</span>
        </li>
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>указании ссылки на Сайт,</span>
        </li>
        <li className="d-flex">
          <i className="d-inline-block mr-2">-</i>
          <span>сохранении авторских прав и товарных знаков.</span>
        </li>
      </ul>

      <p className="font-size-18">Правила пользования материалами</p>
      <p className="font-size-18">
        Размещенные на сайте материалы нельзя скачивать и использовать без уведомления Министерства окружающей среды
        Армении и армянского филиала Всемирного фонда дикой природы, а также без получения письменного согласия
        последних.
      </p>
      <p className="font-size-18">
        Материалы сайта не могут быть использованы в коммерческих или рекламных целях без письменного согласия
        вышеотмеченных организаций.
      </p>
      <p className="font-size-18">
        Несоблюдение вышеуказанных правил может повлечь за собой уголовную ответственность.
      </p>
      <p className=" font-size-18">Правила поведения</p>

      <p className="font-size-18">
        Запрещается использовать Сайт не по предназначению, подвергая его атакам, осознанно внедряя вирусы, логические
        бомбы или другие материалы, которые могут нанести вред с технологической точки зрения. Запрещены попытки
        получения нелицензионного доступа к этому Сайту, серверу или к базе данных. В противном случае вас могут
        привлечь к уголовной ответственности.
      </p>
      <p className="font-size-18">Сторонние страницы</p>
      <p className="font-size-18">
        Этот сайт может содержать ссылки, принадлежащие третьей стороне, которые не контролируются Министерством. Данные
        ссылки предназначены для повышения эффективности и удобности страницы и никоим образом не являются рекламными
        или спонсорскими материалами. Министерство не несет ответственность за содержание, а также безопасность
        указанных страниц.
      </p>
      <p className="font-size-18">Пользовательские материалы</p>
      <p className="font-size-18">
        На данном сайте есть раздел Forum, который предназначен для комментариев. Цель этого компонента – сформировать
        открытую дискуссионную среду, где будут разворачиваться здоровые обсуждения между разными людьми. Министерство
        оставляет за собой право контролировать, удалять неуместные, не соответствующие правилам этикета комментарии.
      </p>
      <p className="font-weight-semi-bold font-size-18">Политика конфиденциальности</p>
      <p className="font-size-18">
        Посещая данный сайт, вы подтверждаете свое согласие на сбор и обработку предоставленных вами данных для анализа
        таких показателей как, например, число посетителей Сайта или просматриваемые страницы. Данная информация
        используется для улучшения настоящего Сайта.
      </p>
      <p className="font-size-18">
        Данный Сайт использует файлы cookie для повышения удобства пользователей. Файлы Cookie не предназначены для
        сбора персональных данных, а просто хранят такую информацию, как ваш пароль или порядок действий на сайте.
      </p>
      <p className="font-size-18">
        При совершении онлайн-покупок мы запрашиваем персональные данные для обработки ваших покупок и проверки данных
        платежной карты. Мы получаем данные вашей личной и платежной карты, используя технологию SSL, которая
        предотвращает передачу ваших данных третьим сторонам. SSL кодирует все ваши персональные данные, включая номер
        кредитной карты, имя и адрес.
      </p>
      <p className="font-size-18">
        Мы работаем с финансовыми учреждениями одной или нескольких третьих сторон для осуществления транзакций
        посредством платежных карт. Они получают данные платежной карты и другую персональную информацию для проверки
        точности данных платежной карты и осуществления транзакций в безопасной среде.
      </p>
      <p className="font-size-18">
        Наша онлайн-система проверки платежных карт управляется банком-партнером, который собирает ваши данные (имя,
        адрес, телефон, номер и срок действия платежной карты) для обработки вашей транзакции. Технология шифрования
        данных обеспечивает высокий уровень безопасности: каждая транзакция является банковской тайной.
      </p>
    </>
  ),
};

class Terms extends Component {
  componentDidMount() {
    // document.querySelector('html').classList.remove('a-fullscreen');
    this.props.setUIParam('page', 'Terms');
    Loader.hideGroup('pageLoader');
  }

  componentDidUpdate() {
    Loader.hideGroup('pageLoader');
  }

  render() {
    const { activeLanguage } = this.props;

    const terms_content = termsContentByLang[activeLanguage.code] || null;

    return (
      <>
        <div className="container-header">
          <h1>
            <Translate>Policy and Terms</Translate>
          </h1>
        </div>
        <div className="container-inner">
          <div className="row">
            <div className="col col-12 col-lg-8 col-xl-8">{terms_content}</div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUIParam(key, val) {
      dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Terms));
