import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Loader } from '../assets/loader.svg';
import Translate from './Translate';

class BigLoader extends Component {
  render() {
    const { show } = this.props;

    return (
      <div
        className={`page-loader position-fixed vw-100 vh-100 background-green-dark top-0 left-0 ${
          show ? 'd-block' : 'd-none'
        }`}
        style={{ zIndex: 9999 }}>
        <div
          className="position-absolute"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <p className="color-white font-size-18 text-uppercase text-center m-0">
            <Translate>Going the distance...</Translate>
          </p>
          <p className="text-center m-0">
            <Loader />
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    show: state.ui.pageLoader,
  };
}

export default connect(mapStateToProps)(BigLoader);
