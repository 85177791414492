import React from 'react';

import './index.scss';

import gc from './assets/gc.png';
import kwf from './assets/kwf.svg';
import ministory from './assets/ministory.svg';
import tjs from './assets/tjs.svg';
import wwf from './assets/wwf.png';

const Sponsors = ({ mode = 'block', className = '' }) => {
  let template;

  if (mode === 'block') {
    template = (
      <div className={`sponsors-list sponsors-list-block ${className}`}>
        <div className="row">
          <div className="col">
            <img className="gc" src={gc} alt="" />
          </div>
          <div className="col">
            <img className="kwf" src={kwf} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <img className="tjs" src={tjs} alt="" />
          </div>
          <div className="col">
            <img className="wwf" src={wwf} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <img className="ministory" src={ministory} alt="" />
          </div>
        </div>
      </div>
    );
  } else if (mode === 'inline') {
    template = (
      <ul
        className={`list-group sponsors-list sponsors-list-inline list-group-horizontal background-zanah ${className}`}>
        <li className="list-group-item">
          <a href="http://mnp.am/" target="_blank" rel="noopener noreferrer">
            <img height="30px" src={ministory} alt="" />
          </a>
        </li>
        {/* <li className="list-group-item">
          <img height="30px" src={gc} alt="" />
        </li>
        <li className="list-group-item">
          <img height="30px" src={kwf} alt="" />
        </li>
        <li className="list-group-item">
          <img height="30px" src={tjs} alt="" />
        </li>
        <li className="list-group-item">
          <img height="30px" src={wwf} alt="" />
        </li> */}
      </ul>
    );
  } else if (mode === 'in-header') {
    template = (
      <ul
        className={`list-group sponsors-list sponsors-list-inline list-group-horizontal background-zanah ${className}`}>
        <li className="list-group-item">
          <a href="http://mnp.am/" target="_blank" rel="noopener noreferrer">
            <img height="30px" src={ministory} alt="" />
          </a>
        </li>
        <li className="list-group-item">
          <img height="30px" src={gc} alt="" />
        </li>
        <li className="list-group-item">
          <img height="30px" src={kwf} alt="" />
        </li>
        <li className="list-group-item">
          <img height="30px" src={tjs} alt="" />
        </li>
        <li className="list-group-item">
          <img height="30px" src={wwf} alt="" />
        </li>
      </ul>
    );
  } else {
    template = <div>Provided mode does not exists</div>;
  }

  return template;
};

export default Sponsors;
