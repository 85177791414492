import React, { Component } from 'react';
import 'aframe';

import { Entity } from 'aframe-react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setMapActiveArea, setMapElement } from '../../store/actions/vr';

import { getIcon } from '../../utils';

import '../../components/aframe/components/model-loader';
import '../../components/aframe/components/model-material';

import VRPointer from '../../components/aframe/vrPointer';
import VRScene from '../../components/aframe/vrScene';
import VRCloud from '../../components/aframe/vrCloud';
import VRMap from '../../components/aframe/vrMap';

const id_to_area = {
  2: 'area_arpi',
  4: 'area_khosrov',
  5: 'area_erebuni',
  11: 'area_zangezur',
  8: 'area_shikahogh',
  7: 'area_arevik',
  12: 'area_sev_lich',
  6: 'area_goris', // tatev
  10: 'area_sosu_purak',
  9: 'area_khustup',
  1: 'area_sevan',
  3: 'area_dilijan',
};

class VRHome extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     pointers: [
  //       {
  //         id: 5072,
  //         title: `Sevan
  //                 National
  //                 Park`,
  //         image: '#vr-icon-gull',
  //         area: 'area_sevan',
  //         position: {
  //           x: -1,
  //           y: 0.55,
  //           z: -3,
  //         },
  //       },
  //       {
  //         id: 10113,
  //         title: `Dilijan
  //                 National
  //                 Park`,
  //         image: '#vr-icon-bear',
  //         area: 'area_dilijan',
  //         position: {
  //           x: -3,
  //           y: 0.55,
  //           z: -6.6,
  //         },
  //       },
  //       {
  //         id: 2,
  //         title: `Lake Arpi
  //                 National
  //                 Park`,
  //         image: '#vr-icon-gull',
  //         area: 'area_arpi',
  //         position: {
  //           x: -14,
  //           y: 0.55,
  //           z: -10,
  //         },
  //       },
  //       {
  //         id: 3,
  //         title: `Khosrov
  //                 Forest
  //                 State Reserve`,
  //         image: '#vr-icon-bear',
  //         area: 'area_khosrov',
  //         position: {
  //           x: -2.9,
  //           y: 0.55,
  //           z: 1,
  //         },
  //       },
  //       {
  //         id: 4,
  //         title: `Erebuni
  //                 State
  //                 Reserve`,
  //         image: '#vr-icon-tiger',
  //         area: 'area_erebuni',
  //         position: {
  //           x: -5.4,
  //           y: 0.6,
  //           z: -0.1,
  //         },
  //       },
  //       {
  //         id: 5,
  //         title: `Zangezur
  //                 State
  //                 Sanctuary`,
  //         image: '#vr-icon-tiger',
  //         area: 'area_zangezur',
  //         position: {
  //           x: 6,
  //           y: 0.9,
  //           z: 10,
  //         },
  //       },
  //       {
  //         id: 6,
  //         title: `Shikahogh
  //                 State
  //                 Reserve`,
  //         image: '#vr-icon-tiger',
  //         area: 'area_shikahogh',
  //         position: {
  //           x: 8.5,
  //           y: 0.7,
  //           z: 11,
  //         },
  //       },
  //       {
  //         id: 7,
  //         title: `Arevik
  //                 National
  //                 Park`,
  //         image: '#vr-icon-bear',
  //         area: 'area_arevik',
  //         position: {
  //           x: 7,
  //           y: 0.9,
  //           z: 12,
  //         },
  //       },
  //       {
  //         id: 8,
  //         title: `Sev Lich
  //                 State
  //                 Sanctuary`,
  //         image: '#vr-icon-chamois',
  //         area: 'area_sev_lich',
  //         position: {
  //           x: 7.3,
  //           y: 0.9,
  //           z: 5.9,
  //         },
  //       },
  //       {
  //         id: 9,
  //         title: `Goris
  //                 State
  //                 Sanctuary`,
  //         image: '#vr-icon-bear',
  //         area: 'area_goris',
  //         position: {
  //           x: 7.8,
  //           y: 0.55,
  //           z: 7.9,
  //         },
  //       },
  //       {
  //         id: 10,
  //         title: `Plane Grove
  //                 State
  //                 Sanctuary`,
  //         image: '#vr-icon-bear',
  //         area: 'area_sosu_purak',
  //         position: {
  //           x: 9.7,
  //           y: 0.4,
  //           z: 11,
  //         },
  //       },
  //       {
  //         id: 11,
  //         title: `Khustup
  //                 State
  //                 Sanctuary`,
  //         image: '#vr-icon-chamois',
  //         area: 'area_khustup',
  //         position: {
  //           x: 8,
  //           y: 0.65,
  //           z: 10,
  //         },
  //       },
  //     ],
  //   };
  // }

  render() {
    const { areas, mapActiveArea, mapElement, setMapActiveArea, setMapElement } = this.props;

    return (
      <VRScene controlTo={mapElement}>
        <VRMap setMapElement={setMapElement} mapActiveArea={mapActiveArea} />

        <Entity className="scene-wrapper">
          {areas.map(area => (
            <VRPointer
              key={area.id}
              title={area.name}
              image={area.image_url}
              area={id_to_area[area.id]}
              position={area.position}
              setMapActiveArea={setMapActiveArea}
              events={{
                click: () => {
                  this.handleClick(area);
                },
              }}
            />
          ))}

          <Entity className="scene-objects">{/*<VRCloud />*/}</Entity>
        </Entity>
      </VRScene>
    );
  }

  handleClick = area => {
    this.props.setMapActiveArea(null);
    this.props.history.push(`/${area.url}/${area.default_point.url}`);
  };
}

function mapStateToProps(state) {
  return {
    mapActiveArea: state.vr.mapActiveArea,
    mapElement: state.vr.mapElement,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMapActiveArea: area => {
      dispatch(setMapActiveArea(area));
    },
    setMapElement: elem => {
      dispatch(setMapElement(elem));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VRHome));
