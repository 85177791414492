import './index.scss';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { convertDMSToDD } from '../../utils';

import Map from '../Map';

import human from '../../assets/human.svg';
import clock from '../../assets/clock.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import Translate from '../Translate';

class TrailDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const { buttonLabel, className, buttonClass, trail, area } = this.props;
    const { modal } = this.state;

    let center;

    if (trail.latitude || trail.longitude) {
      center = {
        lat: convertDMSToDD(trail.latitude),
        lng: convertDMSToDD(trail.longitude),
      };
    }

    const closeBtn = (
      <button type="button" onClick={this.toggle} className="close" aria-label="Close">
        <Close />
      </button>
    );
    return (
      <Fragment>
        <Button className={buttonClass} onClick={this.toggle}>
          {buttonLabel}
        </Button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          size="xl"
          scrollable
          centered
          wrapClassName="App-modal"
          className={className}>
          <ModalHeader close={closeBtn}>{trail.title}</ModalHeader>
          <ModalBody>
            <div className="modal-body-content">
              <div className="row mx-0">
                <div className="col col-12 col-lg-8 col-xl-8">
                  <div className="d-block  mb-3">
                    {/*<img width="100%" src={map} alt="" />*/}
                    <Map height="430px" center={center} zoom={trail.map_zoom_level} kmlUrl={trail.kml_url} />
                  </div>

                  <p>{trail.description}</p>

                  <h4>
                    <Translate>How to get there</Translate>
                  </h4>
                  <p>{trail.how_to_get_there}</p>
                </div>

                <div className="col col-12 col-lg-4 col-xl-4">
                  <div className="fragment pre-wrap mb-4">
                    <h4 className="font-weight-bold font-size-18">
                      <Translate>Further Information</Translate>:
                    </h4>
                    {trail.further_information}
                  </div>
                  <div className="fragment mb-4">
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item pr-3">
                        <div className="media">
                          <img src={human} className="mr-1" alt="" />
                          <div className="media-body">
                            <h5 className="m-0 font-size-14 color-green">
                              <Translate>Skill level</Translate>:
                            </h5>
                            <p className="font-weight-bold font-size-16 text-uppercase mb-0">{trail.skill_level}</p>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item pl-3">
                        <div className="media">
                          <img src={clock} className="mr-1" alt="" />
                          <div className="media-body">
                            <h5 className="m-0 font-size-14 color-green">
                              <Translate>Season</Translate>:
                            </h5>
                            <p className="font-weight-bold font-size-16 text-uppercase mb-0">{trail.season}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="fragment pre-wrap mb-4">
                    <h4 className="font-weight-bold font-size-18">
                      <Translate>Quick Facts</Translate>:
                    </h4>
                    {trail.quick_facts}
                  </div>
                  {trail.files ? (
                    <div className="fragment mb-4">
                      <h4 className="font-weight-bold font-size-18">
                        <Translate>DOWNLOADS</Translate>:
                      </h4>
                      <div className="d-block">
                        <ul className="list-group list-group-flush">
                          {trail.files.map(file => (
                            <li className="list-group-item" key={file.url}>
                              <a
                                href={file.url}
                                target="_blank"
                                download={file.name}
                                rel="noopener noreferrer"
                                className="color-dark">
                                <i className="icon-download" />
                                {file.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    trail: state.trail.current.data,
    area: state.area.current.data,
  };
}

export default connect(mapStateToProps)(TrailDetails);
