import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import Translate from '../Translate';

import { ReactComponent as Arrow } from '../../assets/arrow-left.svg';

const AreaMenu = ({ areas }) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <nav className="sub-menu position-absolute p-0">
      <ul className="list-group">
        <li className="list-group-item sub-menu-breadcrumb clearfix">
          <Link className="nav-link" to="/">
            <Arrow className="mr-1" />
            <Translate>Back to Map</Translate>
          </Link>
          <button onClick={() => setOpen(!isOpen)} className="btn p-0 m-0 w-100 h-100 shadow-none">
            <Arrow
              className={`${isOpen ? 'rotate-up' : 'rotate-down'} float-right mr-4 position-relative`}
              style={{ top: '-15px' }}
            />
          </button>
        </li>
        <li className="navbar list-group-item">
          <Collapse isOpen={isOpen}>
            <ul className="nav d-block pt-0 limit-height">
              <li className="nav-item">
                <span className="color-yellow-light text-uppercase font-size-12">
                  <Translate>Areas</Translate>
                </span>
              </li>
              {areas.map(item => (
                <li className="nav-item" key={item.id}>
                  <NavLink className="nav-link" to={`/${item.url}`}>
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Collapse>
        </li>
      </ul>
    </nav>
  );
};

function mapStateToProps(state) {
  return {
    areas: state.area.list.data,
    area: state.area.current.data,
  };
}

export default connect(mapStateToProps)(AreaMenu);
