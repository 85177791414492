import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getStory, getSubtitles } from '../../store/actions/story';

import isEmpty from 'lodash/isEmpty';

import 'aframe';
import { Entity } from 'aframe-react';

import Player from '../../lib/Player';
import VRPlayer from './vrPlayer';
import VRSubtitles from './vrSubtitles';
import VRButton from './vrButton';
import VRGallery from './vrGallery';
import thirdPartyVideoService from '../../services/third-party-video-service';
import { getUrlParamsFromString } from '../../utils';
import VideoModal from '../Modals/Video';

class VRStoryPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeImage: 0,
      videoThumbnail: null,
      videoModalShow: false,
    };

    this.galleryList = [];

    this.player = new Player('audio', { crossorigin: undefined });

    this.updatePlayer();
  }

  close = () => {
    this.props.closeStory();
  };

  updatePlayer() {
    const { story } = this.props;
    if (story) {
      this.player.setSrc(story.audio_url);
    }
  }

  componentDidUpdate(prevProps) {
    this.updatePlayer();

    const { story } = this.props;

    if (story?.video_url !== prevProps.story?.video_url || (prevProps.story?.video_url && !this.state.videoThumbnail)) {
      if (story?.video_url) {
        thirdPartyVideoService
          .getInfo(story.video_url)
          .then(video => {
            let imgSrc = video.thumbnail + '?videoEmbedSrc=' + encodeURIComponent(video.embedUrl);
            this.setState({ videoThumbnail: imgSrc });
          })
          .catch(data => {
            console.log('ThirdPartyVideo.getInfo:reject', data);
          });
      } else {
        this.setState({ videoThumbnail: null });
      }
    }
  }

  componentDidMount() {
    const { storyId } = this.props;
    this.props.getStory(storyId);
    this.props.getSubtitles(storyId).then(dd => console.log(dd));
  }

  componentWillUnmount() {
    this.player.destroy();
    delete this.player;
  }

  render() {
    const { story, subtitles = {} } = this.props;

    const { videoThumbnail, activeImage, videoModalShow } = this.state;

    const position = {
      x: 0,
      y: 0,
      z: -2,
    };

    const rotation = {
      x: 0,
      y: -story.ath,
      z: 0,
    };

    if (isEmpty(story)) {
      return null;
    }

    this.galleryList = story.images.map(img => ({
      id: img.url,
      thumbnail: img.url,
      image: img.url,
    }));

    if (videoThumbnail) {
      this.galleryList.push({
        id: videoThumbnail,
        thumbnail: videoThumbnail,
        image: videoThumbnail,
        isVideo: true,
      });
    }
    const videoEmbedSrc = getUrlParamsFromString(this.galleryList[activeImage]?.image).get('videoEmbedSrc');

    return (
      <Entity className="story-detail">
        {videoModalShow ? (
          <VideoModal close={this.handleModalClose} url={decodeURIComponent(videoEmbedSrc)} show={videoModalShow} />
        ) : null}

        <Entity className="story-detail-pivot" rotation={rotation}>
          <Entity className="story-detail-rig" position={position} rotation={{ x: -10 }}>
            <Entity className="story-detail-player">
              <VRGallery
                mode="zoom"
                position={{ y: -0.4 }}
                list={this.galleryList}
                onImageClick={this.handleImageClick}
              />

              <VRSubtitles position={{ y: -0.8 }} list={subtitles.fragments} player={this.player} />

              <VRPlayer target="audio" player={this.player} position={{ y: -1, z: 0 }} />

              <VRButton
                events={{ click: this.close }}
                position={{ y: -1.25 }}
                style={{
                  color: '#DFC78B',
                }}
                primitive="a-circle"
                radius="0.07">
                <Entity
                  primitive="a-plane"
                  width="0.04"
                  height="0.04"
                  position="0 0 0.001"
                  material={{
                    shader: 'flat',
                    transparent: true,
                    src: '#vr-close',
                  }}
                />
              </VRButton>
            </Entity>
          </Entity>
        </Entity>
      </Entity>
    );
  }

  handleImageClick = index => {
    if (index !== null) {
      const videoModalShow = this.galleryList[index]?.isVideo;

      this.setState({ activeImage: index, videoModalShow });
    }
  };

  handleModalClose = () => {
    this.setState({ videoModalShow: false });
  };
}

function mapStateToProps(state) {
  return {
    story: state.story.current.data,
    subtitles: state.story.current.subtitles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStory(storyId) {
      return dispatch(getStory(storyId));
    },
    getSubtitles(storyId) {
      return dispatch(getSubtitles(storyId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VRStoryPlayer);
