import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */
export const GET_AREA = 'GET_AREA';
export const GET_AREAS = 'GET_AREAS';

/**
 * THUNKS
 */
export const getAreas = () => dispatch => {
  return dispatch(
    createAction(GET_AREAS, ApiService.getAreas(), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getArea = areaId => dispatch => {
  return dispatch(
    createAction(GET_AREA, ApiService.getArea(areaId), {
      __PARSABLE_ERROR: true,
    })
  );
};
