import { ANIME, registerComponent, THREE } from 'aframe';
import Interactive from '../../../services/Interactive';

const MAX_Z = 28;
const MIN_Z = 16;
const MAX_X = 3.5;
const MIN_X = -3.1;

export default registerComponent('map-controls', {
  dependencies: ['position', 'rotation'],

  schema: {
    enabled: { default: true },
    target: { type: 'selector', default: '' },
  },

  init() {
    this.editableState = {
      mode: null,
      direction: 'left',
      store: {
        x: 0,
        y: 0,
      },
      isMouseDown: false,
      focused: false,
      onObject: false,
      spherical: new THREE.Spherical(),
      vector: new THREE.Vector3(),
      positionVector: new THREE.Vector3(),
    };

    this.rig = this.el.parentNode.object3D;

    this.rig.position.set(0, 20, 20);

    this.target = null;

    this.__bindMethods();
  },
  update(oldData) {
    if (this.data.target !== oldData.target) {
      this.target = this.data.target;
      this.__toggleEvents(false);
      this.__toggleEvents(true);
    }
  },

  remove() {
    this.__toggleEvents(false);
  },

  moveWithInertia: function moveWithInertia() {
    if (this.InertiaAnimation || !this.deltaMovement) {
      return;
    }

    const { rig } = this;
    const { x, z } = this.deltaMovement;

    if (!x && !z) {
      return;
    }

    const animProps = {
      z: rig.position.z,
      x: rig.position.x,
    };

    let newZ = Math.min(rig.position.z + z, MAX_Z); // / bottom 49.5
    newZ = Math.max(newZ, MIN_Z); // / top 33

    // let newX = this.rig.position.x - deltaX * 10;

    let newX = Math.min(rig.position.x + x, MAX_X); // / right
    newX = Math.max(newX, MIN_X); // / left

    this.InertiaAnimation = ANIME({
      targets: animProps,

      x: newX,
      z: newZ,

      duration: 500,
      autoplay: true,
      easing: 'easeOutQuart',
      update: anim => {
        rig.position.x = animProps.x;
        rig.position.z = animProps.z;
      },
    });

    this.deltaMovement = {
      x: 0,
      z: 0,
    };
  },

  __toggleEvents(add = false) {
    if (!this.target) {
      return false;
    }

    if (add) {
      Interactive.addObject(this.target);

      this.target.addEventListener('mouseenter', this.___mouseEnter);
      this.target.addEventListener('mouseleave', this.___mouseLeave);
      this.target.addEventListener('mousedown', this.___mouseDown);
      window.addEventListener('mouseup', this.___mouseUp);
      window.addEventListener('mousemove', this.___mouseMove);

      // this.target.addEventListener('touchstart', this.___mouseEnter);
      // this.target.addEventListener('touchstart', this.___mouseDown);
      // this.target.addEventListener('touchend', this.___mouseLeave);
      // this.target.addEventListener('touchcancel', this.___mouseLeave);
      window.addEventListener('touchstart', this.___mouseTouchStart);
      window.addEventListener('touchend', this.___mouseUp);
      window.addEventListener('touchcancel', this.___mouseUp);
      window.addEventListener('touchmove', this.___mouseMove);
    } else {
      Interactive.removeObject(this.target);

      this.target.removeEventListener('mouseenter', this.___mouseEnter);
      this.target.removeEventListener('mouseleave', this.___mouseLeave);
      this.target.removeEventListener('mousedown', this.___mouseDown);
      window.removeEventListener('mouseup', this.___mouseUp);
      window.removeEventListener('mousemove', this.___mouseMove);

      // this.target.removeEventListener('touchstart', this.___mouseEnter);
      // this.target.removeEventListener('touchstart', this.___mouseDown);
      // this.target.removeEventListener('touchend', this.___mouseLeave);
      // this.target.removeEventListener('touchcancel', this.___mouseLeave);
      window.removeEventListener('touchstart', this.___mouseTouchStart);
      window.removeEventListener('touchend', this.___mouseUp);
      window.removeEventListener('touchcancel', this.___mouseUp);
      window.removeEventListener('touchmove', this.___mouseMove);
    }
    return true;
  },

  __bindMethods() {
    this.___mouseTouchStart = this.___mouseTouchStart.bind(this);
    this.___mouseEnter = this.___mouseEnter.bind(this);
    this.___mouseLeave = this.___mouseLeave.bind(this);
    this.___mouseDown = this.___mouseDown.bind(this);
    this.___mouseUp = this.___mouseUp.bind(this);
    this.___mouseMove = this.___mouseMove.bind(this);
  },

  ___mouseTouchStart(evt) {
    const vector = this.editableState.vector;

    const clientX = typeof evt.clientX === 'undefined' ? evt?.touches[0].clientX || 0 : evt.clientX;
    const clientY = typeof evt.clientY === 'undefined' ? evt?.touches[0].clientY || 0 : evt.clientY;

    vector.set((clientX / window.innerWidth) * 2 - 1, -(clientY / window.innerHeight) * 2 + 1, 0.5);

    this.editableState.store.x = vector.x;
    this.editableState.store.y = vector.y;
  },

  ___mouseEnter(evt) {
    this.editableState.onObject = true;
  },
  ___mouseLeave(evt) {
    this.editableState.onObject = false;
    this.editableState.isMouseDown = false;
  },
  ___mouseDown(evt) {
    if (this.InertiaAnimation) {
      this.InertiaAnimation.pause();
      delete this.InertiaAnimation;
    }

    this.editableState.isMouseDown = true;
    this.editableState.focused = true;
  },
  ___mouseUp(evt) {
    this.editableState.isMouseDown = false;

    this.editableState.focused = this.editableState.onObject;

    this.moveWithInertia();
  },
  ___mouseMove(evt) {
    const vector = this.editableState.vector;

    const clientX = typeof evt.clientX === 'undefined' ? evt?.touches[0].clientX || 0 : evt.clientX;
    const clientY = typeof evt.clientY === 'undefined' ? evt?.touches[0].clientY || 0 : evt.clientY;

    vector.set((clientX / window.innerWidth) * 2 - 1, -(clientY / window.innerHeight) * 2 + 1, 0.5);

    if (this.editableState.isMouseDown) {
      // evt.preventDefault();
      evt.stopPropagation();

      const deltaX = vector.x - this.editableState.store.x;
      const deltaY = vector.y - this.editableState.store.y;

      // let newZ = this.rig.position.z + deltaY * 10;
      // let newX = this.rig.position.x - deltaX * 10;

      let newZ = Math.min(this.rig.position.z + deltaY * 10, MAX_Z); // / bottom 49.5
      newZ = Math.max(newZ, MIN_Z); // / top 33

      let newX = Math.min(this.rig.position.x - deltaX * 10, MAX_X); // / right
      newX = Math.max(newX, MIN_X); // / left

      this.deltaMovement = {
        x: newX - this.rig.position.x,
        z: newZ - this.rig.position.z,
      };

      this.rig.position.x = newX;
      this.rig.position.z = newZ;
    } else if (this.editableState.onObject) {
      this.el.sceneEl.canvas.style.cursor = 'grab';
    } else {
      // this.el.sceneEl.canvas.style.cursor = "default";
    }

    this.editableState.store.x = vector.x;
    this.editableState.store.y = vector.y;
  },
});
