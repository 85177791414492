import React from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';

import { LocalizeProvider } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import globalTranslations from '../translations/global.json';

import AppRoutes from './routes';

import Assets from '../components/aframe/assets';
import storage from '../lib/storage.js';

// window.missingKeys = new Set();

const Root = props => (
  <ReduxProvider store={props.store}>
    <LocalizeProvider
      store={props.store}
      initialize={{
        languages: [
          { name: 'English', code: 'en' },
          { name: 'Armenian', code: 'hy' },
          { name: 'Russian', code: 'ru' },
        ],
        translation: globalTranslations,
        options: {
          defaultLanguage: storage.get('_locale') || 'en',
          renderInnerHtml: false,
          renderToStaticMarkup,
          onMissingTranslation: ({ translationId, languageCode }) => {
            // window.missingKeys.add(translationId)

            // console.log(Array.from(window.missingKeys).join("\n"));

            //  console.log(
            // `%cMissing translationId: "${translationId}" for language: ${languageCode}`,
            // "color: #ffffff; background-color: #444; padding: 3px 7px; margin-left: -7px;"
            // );
            return '';
          },
        },
      }}>
      <AppRoutes history={props.history} />
      <div className="d-none">
        <Assets />
      </div>
    </LocalizeProvider>
  </ReduxProvider>
);

Root.propTypes = {
  store: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default process.env.NODE_ENV === 'development' ? hot(Root) : Root;
