import React, { Component } from "react";

import "aframe";

import { Entity } from "aframe-react";

class VRCloud extends Component {
  static defaultProps = {
    type: "transparent"
  };

  render() {
    let { type } = this.props;

    const src = type === "transparent" ? "#vr-cloud-transparent" : "#vr-cloud";

    return (
      <Entity
        primitive="a-image"
        src={src}
        position="-10 5 0"
        rotation="-45 0 0"
        scale="3 3 3"
        animation__move={{
          property: "position",
          to: "10 5 10",
          dur: 25000,
          easing: "linear",
          dir: "alternate",
          loop: true
        }}
      />
    );
  }
}

export default VRCloud;
