import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { ReactComponent as Close } from '../assets/close.svg';
import { ReactComponent as Share } from '../assets/share.svg';
import { ReactComponent as Fb } from '../assets/fb.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';
import { isMobile } from '../utils';

class Social extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  render() {
    const { className } = this.props;
    const { collapse } = this.state;

    const content = isMobile() ? (
      <div className={`header-dropdown social-dropdown ${className}`}>
        <div className="btn-group" role="group">
          <a target="blank" href="https://twitter.com" className="btn btn-light p-0 rounded-circle text-uppercase">
            <Twitter width="25px" height="25px" />
          </a>
          <a target="blank" href="https://fb.com" className="btn btn-light p-0 rounded-circle text-uppercase">
            <Fb height="25px" />
          </a>
        </div>
      </div>
    ) : (
      <div className={`header-dropdown social-dropdown ${className}`}>
        <Dropdown direction="left" isOpen={collapse} toggle={this.toggle}>
          <DropdownToggle tag="div" className="d-block btn-toggle ">
            <button type="button" className="btn btn-light align-middle rounded-circle text-uppercase p-2">
              {collapse ? (
                <Close className="normalize-svg hoverable" />
              ) : (
                <Share className="hoverable" width="25px" height="25px" />
              )}
            </button>
          </DropdownToggle>
          <DropdownMenu>
            <div className="btn-group" role="group">
              <a target="blank" href="https://twitter.com" className="btn btn-light p-0 rounded-circle text-uppercase">
                <Twitter width="25px" height="25px" />
              </a>
              <a target="blank" href="https://fb.com" className="btn btn-light p-0 rounded-circle text-uppercase">
                <Fb height="25px" />
              </a>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );

    return content;
  }
}

export default Social;
