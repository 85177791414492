import React, { Component } from 'react';

import 'aframe';
import { Entity } from 'aframe-react';
// import { numberToShow } from '../../utils';
// import isNaN from 'lodash/isNaN';

class VRSubtitles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
    };

    if (props.player) {
      this.updatePlayer();
    }
  }

  componentDidUpdate(prevProps) {
    const { player } = this.props;
    if (player && player !== prevProps.player) {
      this.updatePlayer();
    }
  }

  componentWillUnmount() {
    this.unregisterEvents();
  }

  render() {
    const { activeIndex } = this.state;
    const { position = '0 0 0', list = [] } = this.props;

    const text = list[activeIndex] ? list[activeIndex].lines[0] : '...';

    return (
      <Entity className="subtitles-wrapper" position={position}>
        <Entity
          primitive="a-plane"
          color="#000000"
          height="0.1"
          width="1.4"
          material="shader: flat; transparent: true; opacity: 0.5"
        />
        <Entity
          text={{
            width: 1.2,
            value: text,
            align: 'center',
            wrapCount: 40,
            color: '#ddeede',
            font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
          }}
        />
      </Entity>
    );
  }

  updatePlayer() {
    this.unregisterEvents();
    this.registerEvents();
  }

  registerEvents() {
    const { player } = this.props;

    player.on('timeupdate', this.__handleTimeupdate);
  }

  unregisterEvents() {
    const { player } = this.props;
    player.off('timeupdate', this.__handleTimeupdate);
  }

  __handleTimeupdate = ({ seconds }) => {
    const { list = [] } = this.props;
    let activeIndex = 0;

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const begin = Number(item.begin);
      const end = Number(item.end);

      if (seconds <= end && seconds >= begin) {
        activeIndex = i;
        break;
      }
    }

    this.setState(prevState => ({
      ...prevState,
      activeIndex,
    }));
  };
}

export default VRSubtitles;
