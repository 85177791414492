import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getArea } from '../../store/actions/area';
import { getPoint, getPoints } from '../../store/actions/point';
import { getTrails } from '../../store/actions/trail';
import { getStories } from '../../store/actions/story';
import RightBar from '../../components/RightBar';
import VRPoint from './vr';
import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';

import NotFound from '../error';

import { ReactComponent as Close } from '../../assets/close.svg';

class Point extends Component {
  state = {
    showErrorPage: false,
    view360Enabled: false,
  };

  componentDidMount() {
    this.props.setUIParam('page', 'point');
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isDataLoaded, match } = this.props;

    if (match.params.pointId !== prevProps.match.params.pointId) {
      this.loadData();
    } else if (isDataLoaded) {
      Loader.hideGroup('pageLoader');
    }
  }

  render() {
    const { showErrorPage, view360Enabled } = this.state;

    if (showErrorPage) {
      return <NotFound />;
    }

    const { point, stories, isDataLoaded } = this.props;

    if (!isDataLoaded) return null;

    return (
      <>
        <div className={classNames('w-100 h-100', { 'position-fixed z-index-99': view360Enabled })}>
          {view360Enabled ? (
            <button
              type="button"
              onClick={() => this.setState({ view360Enabled: false })}
              className="close position-absolute d-block z-index-99 p-3 rounded-circle background-green"
              style={{ right: '0.75rem', top: '0.75rem' }}>
              <Close />
            </button>
          ) : null}
          <VRPoint loaded={isDataLoaded} stories={stories} point={point} />
        </div>
        <div className="container layout-container position-absolute top-0 left-4 right-4 mx-auto">
          {isDataLoaded ? (
            <RightBar className="right-8 top-3" onEnable360={() => this.setState({ view360Enabled: true })} />
          ) : null}
        </div>
      </>
    );
  }

  loadData = () => {
    Loader.addToGroup(Loader.show(), 'pointPage');

    const { match, getPoint, getStories, getArea, getPoints, getTrails } = this.props;
    const { pointId, areaId } = match.params;

    return Promise.all([getPoint(pointId), getStories(pointId), getArea(areaId), getPoints(areaId), getTrails(areaId)])
      .finally(() => Loader.hideGroup('pointPage'))
      .then(d => {
        for (let i = 0; i < d.length; i++) {
          if (d[i].statusCode === 404) {
            this.setState({
              showErrorPage: true,
            });
            break;
          }
        }
      });
  };
}

function mapStateToProps(state) {
  // console.log('state.point.current', state.point.current);

  return {
    isDataLoaded: state.point.current.loaded && state.story.list.loaded,
    point: state.point.current.data,
    stories: state.story.list.data,
    // ui: state.ui,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getArea(areaId) {
      return dispatch(getArea(areaId));
    },
    getPoint(pointId) {
      return dispatch(getPoint(pointId));
    },
    getPoints(areaId) {
      return dispatch(getPoints(areaId));
    },
    getTrails(areaId) {
      return dispatch(getTrails(areaId));
    },
    getStories(pointId) {
      return dispatch(getStories(pointId));
    },
    setUIParam(key, val) {
      return dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Point));
