import React, { Component } from 'react';

import 'aframe';
import { Entity } from 'aframe-react';

import { mergeEventObjects, getIconFromLocal } from '../../utils';

import './components/interactive';
// import "./components/renderOrder";

class VRHotspot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };

    this.defaultData = {
      mainOpacity: 0.8,
    };

    this.activeData = {
      mainOpacity: 1,
    };
  }

  handleMouseEnter = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: true,
    }));
  };

  handleMouseLeave = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: false,
    }));
  };

  render() {
    const { data, events } = this.props;

    // const { isActive } = this.state;

    // const { defaultData, activeData } = this;

    // const mainOpacity = isActive ? activeData.mainOpacity : defaultData.mainOpacity;

    const eventsList = mergeEventObjects(
      {
        mouseenter: this.handleMouseEnter,
        mouseleave: this.handleMouseLeave,
      },
      events
    );

    return (
      <Entity
        className="item-pivot"
        rotation={{
          x: -data.atv,
          y: -data.ath,
        }}>
        <Entity
          className="item"
          rotation={
            {
              // y: -data.atv,
            }
          }>
          <Entity
            className="item-image"
            position="0 0 -5"
            geometry={{ primitive: 'plane' }}
            scale="0.5 0.5 0.5"
            material={{ side: 'double', shader: 'flat', transparent: true, src: getIconFromLocal(data.icon_url) }}
            interactive
            events={eventsList}
          />
        </Entity>
      </Entity>
    );
  }
}

export default VRHotspot;
