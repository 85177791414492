import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import Sponsors from '../../../components/Sponsors';

import Translate from '../../../components/Translate';
import { connect } from 'react-redux';
import { ReactComponent as Arrow } from '../../../assets/arrow-left.svg';

class AppFooter extends Component {
  state = {
    isMinimized: true,
  };

  render() {
    const { ui } = this.props;
    const { isMinimized } = this.state;

    const year = new Date().getFullYear();

    let minimize;

    switch (ui.page) {
      case 'point':
      case 'trail':
        minimize = true;
        break;
      default:
        minimize = false;
        break;
    }

    return (
      <footer className={`App-footer px-4 bottom-0 w-100 ${minimize && isMinimized ? 'footer-minimized' : ''}`}>
        <nav className="navbar navbar-expand-lg navbar-footer p-0 pl-3 background-green-light">
          <span className="navbar-text copyright-text">
            &copy; {year}{' '}
            {minimize ? (
              <>
                <Translate>MoNP</Translate>
                <button type="button" className="btn mx-3 p-0" onClick={this.handleClick}>
                  <Arrow className={`mr-1 ${isMinimized ? 'rotate-right' : 'rotate-left'}`} />
                </button>
              </>
            ) : (
              <Translate>Ministry of Nature Protection</Translate>
            )}
          </span>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                <Translate>About the project</Translate>
              </NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://forum.protectedareas.am/" target="_blank" rel="noopener noreferrer">
                <Translate>Forum</Translate>
              </a>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/terms">
                <Translate>Policy/Terms page</Translate>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                <Translate>Contacts</Translate>
              </NavLink>
            </li>
          </ul>
          <Sponsors mode="inline" />
        </nav>
      </footer>
    );
  }

  handleClick = () => {
    this.setState(prevState => ({
      isMinimized: !prevState.isMinimized,
    }));
  };
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
  };
}

export default connect(mapStateToProps)(AppFooter);
