import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import Translate from '../Translate';

import { ReactComponent as Arrow } from '../../assets/arrow-left.svg';

const DefaultMenu = () => {
  const [isOpen, setOpen] = useState(true);

  return (
    <nav className="sub-menu position-absolute p-0">
      <ul className="list-group">
        <li className="list-group-item sub-menu-breadcrumb clearfix">
          <Link className="nav-link" to="/">
            <Arrow className="mr-1" />
            <Translate>Back to Map</Translate>
          </Link>
          <button onClick={() => setOpen(!isOpen)} className="btn p-0 m-0 w-100 h-100 shadow-none">
            <Arrow
              className={`${isOpen ? 'rotate-up' : 'rotate-down'} float-right mr-4 position-relative`}
              style={{ top: '-15px' }}
            />
          </button>
        </li>
        <li className="navbar list-group-item">
          <Collapse isOpen={isOpen}>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  <Translate>About the project</Translate>
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://forum.protectedareas.am/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Translate>Forum</Translate>
                </a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/terms">
                  <Translate>Policy/Terms page</Translate>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  <Translate>Contacts</Translate>
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </li>
      </ul>
    </nav>
  );
};

export default DefaultMenu;
