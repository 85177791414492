import React, { Component } from 'react';
import 'aframe';

import { Entity } from 'aframe-react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setSpacePlayer } from '../../store/actions/vr';

import '../../components/aframe/components/placement';

import VRScene from '../../components/aframe/vrScene';
import VRExperience from '../../components/aframe/vrExperience';

class VRTrail extends Component {
  render() {
    return (
      <VRScene>
        <VRExperience {...this.props} />
      </VRScene>
    );
  }
}

function mapStateToProps(state) {
  return {
    player: state.vr.spacePlayer,
    sky: state.vr.sky,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpacePlayer: player => {
      dispatch(setSpacePlayer(player));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VRTrail));
