import React, { Component } from 'react';

import 'aframe';
import { Entity } from 'aframe-react';

import { mergeEventObjects } from '../../utils';

import './components/interactive';

class VRStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };

    this.defaultData = {
      borderColor: '#649482',
      iconSrc: '#vr-play',
    };
    this.activeData = {
      borderColor: '#649482',
    };
  }

  handleMouseEnter = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: true,
    }));
  };

  handleMouseLeave = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: false,
    }));
  };

  render() {
    const { title = '', image = '', position = {}, rotation = {}, events = {}, visible = true } = this.props;

    const { isActive } = this.state;

    const { defaultData, activeData } = this;

    const borderColor = isActive ? activeData.borderColor : defaultData.borderColor;
    const iconSrc = defaultData.iconSrc; //isActive ? activeData.arrowSrc : defaultData.arrowSrc;

    const eventsList = mergeEventObjects(
      {
        mouseenter: this.handleMouseEnter,
        mouseleave: this.handleMouseLeave,
      },
      events
    );

    return (
      <Entity visible={visible} className="item">
        <Entity className="item-pivot" rotation={rotation}>
          <Entity className="item-rig" position={position}>
            <Entity
              className="item-circle"
              geometry={{ primitive: 'circle', radius: 0.35, segments: 32 }}
              material={{ side: 'double', shader: 'flat', color: borderColor }}
              position="0 0 0.01"
              interactive
              events={eventsList}>
              <Entity
                className="item-image"
                position="0 0 0.005"
                geometry={{ primitive: 'circle', radius: 0.31, segments: 64 }}
                material={{ side: 'double', shader: 'flat', src: image }}
              />
              <Entity
                visible={isActive}
                className="item-image-mask"
                position="0 0 0.01"
                geometry={{ primitive: 'circle', radius: 0.31, segments: 64 }}
                material={{
                  transparent: true,
                  shader: 'flat',
                  opacity: 0.5,
                  color: '#000000',
                }}
              />
              <Entity
                visible={isActive}
                className="item-play"
                position="0 0 0.015"
                geometry={{ primitive: 'plane', width: 0.15, height: 0.15 }}
                material={{
                  side: 'double',
                  shader: 'flat',
                  src: iconSrc,
                  transparent: true,
                }}
              />
            </Entity>
            <Entity
              text={{
                width: 1.2,
                value: title,
                baseline: 'top',
                align: 'center',
                wrapCount: 15,
                font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
              }}
              position="0 -0.4 0"
              className="item-text"
            />
          </Entity>
        </Entity>
      </Entity>
    );
  }
}

export default VRStory;
