import React, { Component } from 'react';

import './index.scss';

import { cleanProps } from '../../utils';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

class Marker extends Component {
  render() {
    const { label, active, ...props } = this.props;

    return (
      <MarkerWithLabel
        position={{ lat: props.lat, lng: props.lng }}
        icon={{ url: 'test' }}
        onClick={props.onClick}
        labelAnchor={new window.google.maps.Point(0, 0)}>
        <div className={`map-marker ${active ? 'map-marker_active' : ''}`} {...cleanProps(props)}>
          <div className="map-marker__icon" />
          <span className="map-marker__label family-montserrat">{label}</span>
        </div>
      </MarkerWithLabel>
    );
  }
}

export default Marker;
