import React, { Component } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, KmlLayer } from 'react-google-maps';
import ErrorBoundary from '../../components/error-boundary';
import { Card, CardBody } from 'reactstrap';

const Map1 = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      center={props.center}
      options={props.options}
      mapTypeId={'hybrid'}
      defaultZoom={14}
      zoom={props.zoom}
      defaultCenter={{ lat: 0, lng: 0 }}>
      {!!props.kmlUrl && <KmlLayer url={props.kmlUrl} options={{ preserveViewport: true }} />}
      {props.children}
    </GoogleMap>
  ))
);

class Map extends Component {
  static defaultProps = {
    width: '100%',
    height: '250px',
    zoom: 14,
    center: {
      lat: 0,
      lng: 0,
    },
  };

  render() {
    const { width, height, center, zoom, children, kmlUrl } = this.props;

    return (
      <ErrorBoundary>
        <Card className="p-0 border-0 card-component">
          <CardBody className="p-0 position-relative" style={{ width, height }}>
            <Map1
              center={center}
              kmlUrl={kmlUrl}
              children={children}
              zoom={zoom}
              options={this.getMapOptions()}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD3BbOC25dfJSJywmbTrzAiTUw09R_5mRg&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ width: '100%', height: '100%' }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </CardBody>
        </Card>
      </ErrorBoundary>
    );
  }

  getMapOptions = map => {
    return {
      //mapTypeId: map.MapTypeId.HYBRID,
      // mapTypeId: map.MapTypeId.TERRAIN,
      overviewMapControl: false,
      streetViewControl: false,
      rotateControl: false,
      mapTypeControl: false,

      keyboardShortcuts: false,
      disableDoubleClickZoom: true,
      zoomControl: true,
      scaleControl: false,
      centerAroundCurrentLocation: false,
      panControl: false,
      fullscreenControl: false,
      scrollwheel: false,
      disableDefaultUI: false,
      clickableIcons: true,

      // styles: mapConfig,
    };
  };
}

export default Map;
