import isFunction from "lodash/isFunction";

import React, { Component } from "react";

import "aframe";
import { Entity } from "aframe-react";

import "./components/interactive";
import { mergeEventObjects } from "../../utils";
import VRButton from "./vrButton";

class VRSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      selected: props.selected || null
    };
  }

  handleClick = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: !prevState.isActive
    }));
  };

  handleChange = item => {
    this.setState(prevState => ({
      ...prevState,
      isActive: false,
      selected: item.key
    }));

    if (isFunction(this.props.handleChange)) {
      this.props.handleChange(item);
    }
  };

  render() {
    const { isActive } = this.state;

    const {
      disable = false,
      style,
      styleActive,
      position,
      options = []
    } = this.props;

    const defaultStyle = {
      width: 0.1,
      height: 0.1,
      ...style
    };
    const activeStyle = {
      ...defaultStyle,
      ...styleActive
    };

    const width = 0.25;
    const height = options.length * 0.1;

    const pos = { x: 0, y: 0, z: 0.001, ...position };

    return (
      <Entity position={pos} className="select-wrapper">
        <VRButton
          events={{ click: this.handleClick }}
          style={defaultStyle}
          styleActive={activeStyle}
          disable={disable}
        />
        <Entity
          visible={isActive}
          className="select-options"
          position={{
            x: -width / 2 + defaultStyle.width,
            y: pos.y + height / 2 + defaultStyle.height + 0.01,
            z: 0.002
          }}
        >
          <Entity
            primitive="a-plane"
            material="shader: flat; transparent: true; opacity: 0.8; color: #000000"
            width={width}
            height={height}
          />

          <Entity className="options-wrapper" position="0 0 0.001">
            {options.map((opt, i) => (
              <Entity
                interactive
                events={{
                  click: () => {
                    this.handleChange(opt);
                  }
                }}
                position={{
                  y: -(height / 2) + i * 0.1 + (i === 0 ? 0.05 : 0.05)
                }}
                key={opt.key}
                text={{
                  value: opt.value,
                  align: "center",
                  wrapCount: 5,
                  width: 0.2,
                  color: "#ddeede"
                }}
              />
            ))}
          </Entity>
        </Entity>
      </Entity>
    );
  }
}

export default VRSelect;
