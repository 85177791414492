export const facesInfo = [
  {
    pos: "l",
    distance: 100,
    position: {
      x: -100,
      y: 0,
      z: 0
    },
    rotation: {
      x: 0,
      y: 90,
      z: 0
    }
  },
  {
    pos: "r",
    distance: 100,
    position: {
      x: 100,
      y: 0,
      z: 0
    },
    rotation: {
      x: 0,
      y: -90,
      z: 0
    }
  },
  {
    pos: "u",
    distance: 100,
    position: {
      x: 0,
      y: 100,
      z: 0
    },
    rotation: {
      x: 90,
      y: 0,
      z: 0
    }
  },
  {
    pos: "d",
    distance: 100,
    position: {
      x: 0,
      y: -100,
      z: 0
    },
    rotation: {
      x: -90,
      y: 0,
      z: 0
    }
  },
  {
    pos: "f",
    distance: 100,
    position: {
      x: 0,
      y: 0,
      z: -100
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0
    }
  },
  {
    pos: "b",
    distance: 100,
    position: {
      x: 0,
      y: 0,
      z: 100
    },
    rotation: {
      x: 180,
      y: 0,
      z: 180
    }
  }
];
