import React, { Component, Fragment } from 'react';

import 'aframe';

import {
  VR_ARROW_LEFT,
  VR_ARROW_RIGHT,
  VR_CLOSE,
  VR_CLOUD,
  VR_CLOUD_TRANSPARENT,
  VR_ICON_BEAR,
  VR_ICON_CHAMOIS,
  VR_ICON_GULL,
  VR_ICON_TIGER,
  VR_ITEM_IMG,
  VR_LOADER,
  VR_MAP,
  VR_MAP_LAYER,
  VR_MAP_MATERIAL,
  VR_MAP_TREE_MATERIAL,
  VR_PLAY,
  VR_POINTER_TRIANGLE,
  VR_POINTER_TRIANGLE_ACTIVE,
  VR_QUALITY,
  // VR_AIR_BALLOON,
  // VR_STORK,
  VR_VOLUME_OFF,
  VR_VOLUME_ON,
  VR_HOTSPOT_DEFAULT,
  VR_HOTSPOT_GROUND,
  VR_HOTSPOT_AIR,
} from '../../../constants/assets';

import Loader from '../../../services/Loader';

import map from './models/map.glb';
import mapLayer from './models/map_layer_new.glb';
import mapMaterial from './models/map_material_small_new.jpg';
import mapTreeMaterial from './models/map_tree_small.jpg';
import smallCloud from './images/small-cloud.png';
import smallCloudTransparent from './images/small-cloud-transparent.png';
// import stork from "./models/stork.glb";
// import airBalloon from "./models/balloon.glb";

import itemImg from './images/item.jpg';

import loader from './images/loader_.gif';

import playIcon from './images/play.png';

import qualityIcon from './images/quality.png';

import closeIcon from './images/close.png';

import volumeOnIcon from './images/volume-on.png';
import volumeOffIcon from './images/volume-off.png';

import arrowLeftIcon from './images/arrow-left.png';
import arrowRightIcon from './images/arrow-right.png';

import pointerTriangle from './images/pointer-triangle.png';
import pointerTriangleActive from './images/pointer-triangle-active.png';

import hotspotDefault from './images/hotspot-default.png';
import hotspotGround from './images/hotspot-ground.png';
import hotspotAir from './images/hotspot-air.png';

import gull from './images/gull.png';
import bear from './images/bear.png';
import tiger from './images/tiger.png';
import chamois from './images/chamois.png';
import { Scene } from 'aframe-react';

// const fntSemiLight = '/assets/fonts/WeblySleek_UI_Semilight_Regular.fnt';
// const fntSemiBold = '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt';

class VRAssets extends Component {
  constructor(props) {
    super(props);

    this.containerElm = React.createRef();
  }

  loader = 'vrSceneAssets';

  componentDidMount() {
    // this.loader = Loader.show(this.loader);
    // Loader.addToGroup(this.loader, "vrScene");
    // this.sceneEl.current.el.addEventListener("loaded", this.handleLoad);
  }

  componentWillUnmount() {
    Loader.hide(this.loader);
    // this.sceneEl.current.el.removeEventListener("loaded", this.handleLoad);
  }

  render() {
    return (
      <Fragment>
        <a-asset>
          <a-asset-item id={VR_MAP} src={map} response-type="arraybuffer" />
          <a-asset-item id={VR_MAP_LAYER} src={mapLayer} response-type="arraybuffer" />

          <img src={mapMaterial} alt="" id={VR_MAP_MATERIAL} />
          <img src={mapTreeMaterial} alt="" id={VR_MAP_TREE_MATERIAL} />

          <img src={smallCloud} alt="" id={VR_CLOUD} />
          <img src={smallCloudTransparent} alt="" id={VR_CLOUD_TRANSPARENT} />

          {/* <a-asset-item id={VR_STORK} src={stork} /> */}
          {/* <a-asset-item id={VR_AIR_BALLOON} src={airBalloon} /> */}

          <img src={itemImg} alt="" id={VR_ITEM_IMG} />

          <img src={loader} alt="" id={VR_LOADER} />

          <img src={pointerTriangle} alt="" id={VR_POINTER_TRIANGLE} />
          <img src={pointerTriangleActive} alt="" id={VR_POINTER_TRIANGLE_ACTIVE} />

          <img src={playIcon} alt="" id={VR_PLAY} />

          <img src={qualityIcon} alt="" id={VR_QUALITY} />

          <img src={closeIcon} alt="" id={VR_CLOSE} />

          <img src={volumeOnIcon} alt="" id={VR_VOLUME_ON} />
          <img src={volumeOffIcon} alt="" id={VR_VOLUME_OFF} />

          <img src={arrowLeftIcon} alt="" id={VR_ARROW_LEFT} />
          <img src={arrowRightIcon} alt="" id={VR_ARROW_RIGHT} />

          <img src={gull} alt="" id={VR_ICON_GULL} />
          <img src={bear} alt="" id={VR_ICON_BEAR} />
          <img src={tiger} alt="" id={VR_ICON_TIGER} />
          <img src={chamois} alt="" id={VR_ICON_CHAMOIS} />

          <img src={hotspotDefault} alt="" id={VR_HOTSPOT_DEFAULT} />
          <img src={hotspotGround} alt="" id={VR_HOTSPOT_GROUND} />
          <img src={hotspotAir} alt="" id={VR_HOTSPOT_AIR} />

          {/* <a-mixin id="Semilight-font" text={`font: ${fntSemiLight}`}></a-mixin> */}
          {/* <a-mixin id="SemiBold-font" text={`font: ${fntSemiBold}`}></a-mixin> */}
        </a-asset>
      </Fragment>
    );
  }

  handleLoad = () => {
    Loader.hide(this.loader);
  };
}

export default VRAssets;
