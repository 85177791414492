import isElement from "lodash/isElement";
import isString from "lodash/isString";
import kebabCase from "lodash/kebabCase";

class Player {
  constructor(element, options = {}) {
    options = {
      webkitPlaysinline: "",
      playsinline: "",
      preload: "auto",
      crossorigin: "anonymous",
      ...options
    };

    if (isElement(element)) {
      this.media = element;
    } else if (isString(element)) {
      this.media = document.createElement(element);
    } else {
      console.error("Please provide valid parameter.");
      return;
    }

    Object.keys(options).map(key => {
      if (options[key] !== undefined) {
        this.media.setAttribute(kebabCase(key), options[key]);
      }
    });

    this.subscribers = {};

    this._registerEvents();
  }

  destroy() {
    this.emit("destroy");
    this._unregisterEvents();
    this.subscribers = {};
    this.media.setAttribute("src", "");
    this.media.load();
    const media = this.media.cloneNode();
    this.media.remove();
    this.media = media;
  }

  off(eventName, handler) {
    const list = this.subscribers[eventName];

    const index = list ? list.indexOf(handler) : -1;

    if (index !== -1) {
      this.subscribers[eventName].splice(index, 1);
    }
  }

  on(eventName, handler) {
    if (this.subscribers[eventName]) {
      this.subscribers[eventName].push(handler);
    } else {
      this.subscribers[eventName] = [handler];
    }
  }

  emit(eventName, data, ...args) {
    const list = this.subscribers[eventName];
    if (list) {
      list.map(fn => fn.call(this.media, data, ...args));
    }
  }

  setSrc(url = "") {
    if (this.media.getAttribute("src") === url) {
      return;
    }

    this.emit("state-change:src", {
      currentTime: this.media.currentTime,
      duration: this.media.duration,
      prevSrc: this.media.src
    });

    this.media.setAttribute("src", url);
  }

  play() {
    return this.media.play();
  }

  pause() {
    return this.media.pause();
  }

  getPaused() {
    return this.media.paused;
  }

  getCurrentTime() {
    return this.media.currentTime;
  }

  setCurrentTime(time) {
    this.media.currentTime = time;
    this.emit("state-change:time");
  }

  getDuration() {
    return this.media.volume * 100;
  }

  getVolume() {
    return this.media.currentTime;
  }

  setVolume(value) {
    this.media.volume = value / 100;
    this.emit("state-change:volume");
  }

  mute() {
    this.media.muted = true;
    this.emit("state-change:mute");
  }

  unmute() {
    this.media.muted = false;
    this.emit("state-change:mute");
  }

  getMuted() {
    return this.media.muted;
  }

  getLoop() {
    return this.media.loop;
  }

  setLoop(value) {
    this.media.loop = value;
    this.emit("state-change:loop");
  }

  __handleReady = evt => {
    if (this.media.readyState >= 1) {
      this.emit("ready", evt);
      this.__handleTimeupdate();
    } else {
      this.emit("error", evt);
    }
  };

  __handleError = evt => {
    this.emit("error", evt);
  };

  __handlePlaying = () => {
    this.emit("play");
  };

  __handlePause = () => {
    this.emit("pause");
  };

  __handleEnded = () => {
    this.emit("ended");
  };

  __handleTimeupdate = () => {
    this.emit("timeupdate", {
      seconds: this.media.currentTime,
      duration: this.media.duration
    });
  };

  __handleProgress = () => {
    this.emit("buffered", {
      percent: this.media.buffered.length
    });
  };

  _registerEvents() {
    this.media.addEventListener("playing", this.__handlePlaying);
    this.media.addEventListener("pause", this.__handlePause);
    this.media.addEventListener("ended", this.__handleEnded);
    this.media.addEventListener("timeupdate", this.__handleTimeupdate);
    this.media.addEventListener("progress", this.__handleProgress);

    this.media.addEventListener("loadedmetadata", this.__handleReady);
    this.media.addEventListener("error", this.__handleError);
  }

  _unregisterEvents() {
    this.media.removeEventListener("playing", this.__handlePlaying);
    this.media.removeEventListener("pause", this.__handlePause);
    this.media.removeEventListener("ended", this.__handleEnded);
    this.media.removeEventListener("timeupdate", this.__handleTimeupdate);
    this.media.removeEventListener("progress", this.__handleProgress);

    this.media.removeEventListener("loadedmetadata", this.__handleReady);
    this.media.removeEventListener("error", this.__handleError);
  }
}

export default Player;
