import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';

import ContactForm from '../../components/ContactForm';
import Translate from '../../components/Translate';

class Contact extends Component {
  componentDidMount() {
    this.props.setUIParam('page', 'contact');
    Loader.hideGroup('pageLoader');
  }

  componentDidUpdate() {
    Loader.hideGroup('pageLoader');
  }

  render() {
    return (
      <>
        <div className="container-header">
          <h1>
            <Translate>Contact</Translate>
          </h1>
        </div>
        <div className="container-inner">
          <div className="row flex-row-reverse">
            <div className="col col-12 col-lg-4 col-xl-4">
              <div className="fragment mb-4">
                <h4 className="font-weight-bold font-size-18">
                  <Translate>Phones</Translate>:
                </h4>
                <p>(0234) 21352</p>
              </div>
              <div className="fragment mb-4">
                <h4 className="font-weight-bold font-size-18">
                  <Translate>Emails</Translate>:
                </h4>
                <p>info@treasureofnature.org</p>
              </div>
            </div>
            <div className="col col-12 col-lg-8 col-xl-8">
              <ContactForm />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUIParam(key, val) {
      dispatch(setParam(key, val));
    },
  };
}

export default connect(undefined, mapDispatchToProps)(Contact);
