import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */
export const GET_POINT = 'GET_POINT';
export const GET_POINTS = 'GET_POINTS';

/**
 * THUNKS
 */
export const getPoints = areaId => dispatch => {
  return dispatch(
    createAction(GET_POINTS, ApiService.getPoints(areaId), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getPoint = (pointId, areaId) => dispatch => {
  return dispatch(
    createAction(GET_POINT, ApiService.getPoint(pointId, areaId), {
      __PARSABLE_ERROR: true,
    })
  );
};
