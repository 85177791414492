import * as actionTypes from '../actions/vr';

const initialState = {
  mapActiveArea: null,
  mapElement: null,
  spacePlayer: null,
  sky: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SKY_ELEMENT:
      return {
        ...state,
        sky: action.payload,
      };
    case actionTypes.SET_MAP_ACTIVE_AREA:
      return {
        ...state,
        mapActiveArea: action.payload,
      };
    case actionTypes.SET_MAP_ELEMENT:
      return {
        ...state,
        mapElement: action.payload,
      };
    case actionTypes.SET_SPACE_PLAYER:
      return {
        ...state,
        spacePlayer: action.payload,
      };
    default:
      return state;
  }
};
