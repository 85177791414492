import React, { Component } from 'react';
import 'aframe';
import 'aframe-extras';

import { Entity, Scene } from 'aframe-react';

import VRCamera from './vrCamera';

import Interactive from '../../services/Interactive';

import './components/scene-controller';
import Loader from '../../services/Loader';
import { setSkyElement } from '../../store/actions/vr';
import { connect } from 'react-redux';
import { isMobile } from '../../utils';

class VRScene extends Component {
  constructor(props) {
    super(props);

    this.skySphere = React.createRef();
    this.loader = 'vrScene';
  }

  componentDidMount() {
    this.props.setSkyElement(this.skySphere.current.el);

    this.loader = Loader.show(this.loader);
    Loader.addToGroup(this.loader, 'vrScene');
  }

  componentWillUnmount() {
    Loader.hideGroup('vrScene');
  }

  render() {
    const { children, controlTo, lookAt } = this.props;

    return (
      <Scene
        embedded
        debug={false}
        vr-mode-ui={{ enabled: false }}
        cursor={{ rayOrigin: 'mouse' }}
        raycaster={{
          far: 300,
          objects: `.${Interactive.className}`,
          autoRefresh: false,
        }}
        light={{ defaultLightsEnabled: false }}
        renderer={{ sortObjects: true }}
        device-orientation-permission-ui={{ enabled: isMobile() }}
        loading-screen="enabled: false;"
        scene-controller>
        <Entity light="type: hemisphere; intensity: 2" position="0 0 0" />

        <Entity
          ref={this.skySphere}
          interactive={{ cursor: '-webkit-grab' }}
          /* events={{
            mouseup: ({
              target: {
                sceneEl: { canvas }
              }
            }) => {
              canvas.style.cursor = "-webkit-grab";
            }
          }} */
          primitive="a-sphere"
          radius="250"
          segments-width="6"
          segments-height="4"
          material={{
            shader: 'flat',
            transparent: true,
            opacity: 0,
            side: 'back',
          }}
        />

        {children}

        <VRCamera controlTo={controlTo} lookAt={lookAt} />
      </Scene>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSkyElement: elem => {
      dispatch(setSkyElement(elem));
    },
  };
}

export default connect(null, mapDispatchToProps)(VRScene);
