import AFRAME, { registerComponent, THREE } from 'aframe';

import isElement from 'lodash/isElement';

export default registerComponent('model-material', {
  schema: {
    side: { default: THREE.DoubleSide },
    color: { default: '#ffffff' },
    textures: {
      default: '',
      parse(value) {
        const obj = {};
        const arr = value.split(',');

        if (arr.length > 1) {
          arr.map((v, i) => {
            if (i % 2 === 0) {
              obj[v.trim()] = String(arr[i + 1]).trim();
            }
          });
        }

        return obj;
      },
      stringify(value) {
        const arr = [];

        Object.keys(value).map(v => {
          arr.push(v);
          arr.push(value[v]);
        });

        return arr.join(', ');
      },
    },
  },
  init() {
    this.object3D = this.el.object3D;
    this.model = null;

    this.el.addEventListener('model-loaded', ({ detail }) => {
      // Grab the mesh / scene.
      this.model = detail.model;

      this._updateModel();
    });
  },
  update() {
    if (this.model) {
      this._updateModel();
    }
  },

  _updateModel() {
    const { side, textures, color } = this.data;

    this.model.traverse(node => {
      if (node.isMesh) {
        const mt = new THREE.MeshBasicMaterial({
          color,
          map: node.material.map,
          side,
        });

        if (Object.keys(textures).length) {
          let src = textures[node.name];

          if (!src && textures['*']) {
            src = textures['*'];
          }

          if (src) {
            src = AFRAME.schema.parseProperty(src, AFRAME.schema.processPropertyDefinition({ type: 'asset' }));

            if (isElement(src)) {
              mt.map = new THREE.Texture(src);

              src.onload = () => {
                mt.map.needsUpdate = true;
              };

              if (src.complete) {
                src.onload();
              }
            } else {
              mt.map = new THREE.TextureLoader().load(src);
            }
          }
        }

        if (mt.map) {
          mt.map.flipY = false;
        }

        node.material = mt;
      }
    });
  },
});
