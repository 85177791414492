import { registerComponent } from 'aframe';

export default registerComponent('map-layout-controller', {
  schema: {
    highlightedArea: {
      type: 'string',
      default: '',
    },
  },

  init() {
    this._bindMethods();

    this.object3D = this.el.object3D;
    this.model = null;

    this.el.addEventListener('set-highlighted-area', this._handleSetHighlightedArea);
    this.el.addEventListener('model-loaded', this._handleLoad);

    this.el.addEventListener('animationcomplete', this._handleAnimationEnd);
  },
  update() {
    if (this.model) {
      if (this.data.highlightedArea) {
        this._updateModel();
      } else {
        this.el.emit('highlight-area:hide');
      }
    }
  },

  _updateModel() {
    this.model.traverse(node => {
      if (node.isMesh) {
        node.visible = node.name === this.data.highlightedArea;
      }
    });
    if (this.data.highlightedArea) {
      this.el.emit('highlight-area:show');
    }
  },

  _handleLoad: function _handleLoad({ detail }) {
    // Grab the mesh / scene.
    this.model = detail.model;

    this.model.traverse(node => {
      if (node.isMesh && node.name === 'area_arpi') {
        node.position.x = 0.2;
        node.position.y = 0;
        node.position.z = 0.4;
      }
    });

    this._updateModel();
  },
  _handleSetHighlightedArea: function _handleSetHighlightedArea({ detail }) {
    this.el.setAttribute(this.name, {
      highlightedArea: detail.name,
    });
  },
  _handleAnimationEnd: function _handleAnimationEnd({ detail }) {
    if (detail.name === 'animation__move_down') {
      this._updateModel();
    }
  },

  _bindMethods: function _bindMethods() {
    this._handleLoad = this._handleLoad.bind(this);
    this._handleSetHighlightedArea = this._handleSetHighlightedArea.bind(this);
    this._handleAnimationEnd = this._handleAnimationEnd.bind(this);
  },
});
