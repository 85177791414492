import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/arrow-left.svg';
import { Card, CardBody, Collapse } from 'reactstrap';
import Translate from '../Translate';

const PointMenu = ({ area, point, points }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <nav className="sub-menu position-absolute p-0">
      <ul className="list-group">
        <li className="list-group-item sub-menu-breadcrumb clearfix">
          <Link className="nav-link" to="/">
            <Arrow className="mr-1" />
            <Translate>Back to Map</Translate>
          </Link>
        </li>
        <li className="navbar list-group-item">
          <ul className="nav flex-column">
            <li className="nav-item">
              <div className="media clearfix">
                <div className="media-body family-cuprum font-weight-bold color-zanah">
                  {area.name || <Translate>Loading</Translate>}
                </div>
                <img width="60" src={area.image_url} alt={area.name} className="img float-right" />
              </div>
              <p className="mb-2 mt-1">{area.slogan}</p>
            </li>
            <li className="nav-item nav-item-collapse">
              <button type="button" className="btn clearfix" onClick={() => setOpen(!isOpen)} id="sub-menu-collapse">
                {point.name || <Translate>Loading</Translate>}
                <Arrow className={`${isOpen ? 'rotate-up' : 'rotate-down'} float-right`} />
              </button>
              <Collapse isOpen={isOpen}>
                <Card>
                  <CardBody className="p-0">
                    <ul className="nav p-0 m-0 d-block limit-height">
                      {points.map(pt => {
                        // if (pt.id === point.id) return null;

                        return (
                          <li className="nav-item" key={pt.id}>
                            <NavLink className="nav-link" to={`/${pt.area.url}/${pt.url}`}>
                              {pt.name}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </CardBody>
                </Card>
              </Collapse>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

function mapStateToProps(state) {
  return {
    isDataLoaded: state.area.current.loaded && state.point.current.loaded && state.point.list.loaded,
    area: state.area.current.data,
    point: state.point.current.data,
    points: state.point.list.data,
  };
}

export default connect(mapStateToProps)(PointMenu);
