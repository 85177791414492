import React, { Component } from "react";

import "aframe";

import { Entity } from "aframe-react";

import PhotoSpace from "./photoSpace";
import VideoSpace from "./videoSpace";

class VRSpace extends Component {
  render() {
    const { space = "video", data, setPlayer } = this.props;

    return (
      <Entity className="space-wrapper">
        {space === "video" ? (
          <VideoSpace setPlayer={setPlayer} />
        ) : (
          <PhotoSpace data={data} />
        )}
      </Entity>
    );
  }
}

export default VRSpace;
