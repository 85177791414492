import React, { Component } from 'react';
import './index.scss';
import { setParam } from '../../store/actions/ui';
import { connect } from 'react-redux';
import Loader from '../../services/Loader';
import Translate from '../../components/Translate';
import { Link } from 'react-router-dom';

class NotFound extends Component {
  componentDidMount() {
    this.props.setUIParam('page', 'error');
    Loader.hideGroup('pageLoader');
  }

  componentDidUpdate() {
    Loader.hideGroup('pageLoader');
  }

  render() {
    return (
      <div className="inner-content">
        <h2 className="mb-4">
          <Translate>Ups… something went wrong.</Translate>
          <small className="d-block">404 ERROR</small>
        </h2>
        <p>
          <Translate>Seeems the link you used doesn’t exist or the page was moveed.</Translate>
        </p>
        <p>
          <Translate>Please check the link or go to</Translate>{' '}
          <Link to="/">
            <Translate>HOMEPAGE</Translate>
          </Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUIParam(key, val) {
      dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
