/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';

import { Form, Field } from 'react-final-form';
import Validator, { composeValidators } from '../services/Validator';
import Translate, { translateManually } from './Translate';
import { withLocalize } from 'react-localize-redux';
import ApiService from '../services/api-service';

import countryList from '../constants/country';

class BookingForm extends Component {
  state = {
    successMessage: null,
    errorMessage: null,
  };

  render() {
    const { successMessage, errorMessage } = this.state;
    const { area } = this.props;

    return (
      <div className="d-block">
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <h2 className="text-left text-capitalize w-100">
                  <Translate>Contact info</Translate>
                </h2>
              </div>

              <Field name="name" validate={Validator.required}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="booking_form_person_name">
                        <Translate>Contact person name</Translate>
                      </label>
                      <input
                        {...input}
                        type="text"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="booking_form_person_name"
                        placeholder={translateManually({ text: 'Contact name' }, this.props.translate)}
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="email" validate={composeValidators(Validator.required, Validator.email)}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="booking_form_email">
                        <Translate>Email</Translate>
                      </label>
                      <input
                        {...input}
                        type="email"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="booking_form_email"
                        placeholder="e.g. john.smith@gmail.com"
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="phone_number" validate={composeValidators(Validator.required, Validator.phone)}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100 w-100">
                      <label htmlFor="booking_form_phone_number">
                        <Translate>Phone</Translate>
                      </label>
                      <input
                        {...input}
                        type="text"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="booking_form_phone_number"
                        placeholder="e.g. +374 93 852323"
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="country">
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="booking_form_country">
                        <Translate>Country of residence (optional)</Translate>
                      </label>
                      <select
                        {...input}
                        id="booking_form_country"
                        className={`custom-select custom-select-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}>
                        <option value="">
                          {translateManually({ text: 'Choose a country' }, this.props.translate)}
                        </option>
                        {countryList.map(ctr => (
                          <option key={ctr.code} value={ctr.code}>
                            {ctr.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="learn_root">
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="booking_form_learn_root">
                        <Translate>How did you learn about our website?</Translate>
                      </label>
                      <select
                        {...input}
                        id="booking_form_learn_root"
                        className={`custom-select custom-select-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}>
                        <option value="">
                          {translateManually({ text: 'Select a channel' }, this.props.translate)}
                        </option>
                        <option value="fb">Facebook</option>
                        <option value="friends">{translateManually({ text: 'Friends' }, this.props.translate)}</option>
                        <option value="other">{translateManually({ text: 'Other' }, this.props.translate)}</option>
                      </select>
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <div className="mb-3 mt-3">
                <h2 className="text-left text-capitalize w-100">
                  <Translate>Payment</Translate>
                </h2>
              </div>

              <Field name="card_name" validate={Validator.required}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="booking_form_card_name">
                        <Translate>Cardholder name</Translate>
                      </label>
                      <input
                        {...input}
                        type="text"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="booking_form_card_name"
                        placeholder="e.g. John Smith"
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="card_number" validate={composeValidators(Validator.required, Validator.creditCard)}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="booking_form_card_number">
                        <Translate>Card number</Translate>
                      </label>
                      <input
                        {...input}
                        onChange={({ target: { value } }) => {
                          let content = value.split(' ');

                          const isNotInt = content.reduce((isNotInt, val) => {
                            return isNotInt || isNaN(Number(val));
                          }, false);

                          if (isNotInt || content.length > 4) {
                            return;
                          }

                          const last = content[content.length - 1];

                          if (last.length > 4) {
                            content[content.length - 1] = last.slice(0, 4);
                          }

                          let newContent = content.join(' ');

                          if (value.length > input.value.length && content.length < 4 && last.length > 3) {
                            newContent += ' ';
                          }

                          input.onChange(newContent);
                        }}
                        type="text"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="booking_form_card_number"
                        placeholder="xxxx xxxx xxxx xxxx"
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <div className="form-row mx-0">
                <div className="col-6">
                  <Field name="expire_date" validate={composeValidators(Validator.required, Validator.creditCardDate)}>
                    {({ input, meta }) => (
                      <div className="form-group w-100">
                        <label htmlFor="booking_form_expire_date">
                          <Translate>Expitation date</Translate>
                        </label>
                        <input
                          {...input}
                          onChange={({ target: { value } }) => {
                            let content = value.split('/');

                            const isNotInt = content.reduce((isNotInt, val) => {
                              return isNotInt || isNaN(Number(val));
                            }, false);

                            if (isNotInt) {
                              return;
                            }

                            if (content[0].length < 2 || (!content[1] && value.length < input.value.length)) {
                              content = content[0];
                            } else {
                              content = `${content[0]}/${(content[1] || '').slice(0, 4)}`;
                            }

                            input.onChange(content);
                          }}
                          type="text"
                          className={`form-control form-control-lg ${meta.touched &&
                            (meta.error ? 'is-invalid' : 'is-valid')}`}
                          id="booking_form_expire_date"
                          placeholder="mm/yyyy"
                          required
                        />
                        <div className="invalid-feedback">
                          <Translate>{meta.error}</Translate>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6">
                  <Field
                    name="cvc_code"
                    validate={composeValidators(Validator.required, Validator.number, Validator.maxNumberValue(3))}>
                    {({ input, meta }) => (
                      <div className="form-group w-100">
                        <label htmlFor="booking_form_expire_date">
                          <Translate>CVC code</Translate>
                        </label>
                        <input
                          {...input}
                          type="text"
                          className={`form-control form-control-lg ${meta.touched &&
                            (meta.error ? 'is-invalid' : 'is-valid')}`}
                          id="booking_form_cvc_code"
                          placeholder="xxx"
                          required
                        />
                        <div className="invalid-feedback">
                          <Translate>{meta.error}</Translate>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>

              <div className="form-row clearfix mx-0 mt-3">
                <hr className="w-100 mt-0 background-yellow" />
                <div className="col">
                  <p className="text-left m-0 font-size-18">
                    <Translate>Total price</Translate>:
                  </p>
                </div>
                <div className="col">
                  <p className="text-right m-0 font-size-18 font-weight-500">AMD {area?.booking_amount} </p>
                </div>
                <hr className="w-100 background-yellow" />
              </div>

              <div className="form-row mx-0 mt-3">
                <button
                  type="submit"
                  className="btn btn-secondary text-uppercase mx-auto p-3 font-size-19 w-75"
                  disabled={submitting}>
                  <Translate>Book now</Translate>
                </button>
              </div>

              <div className="d-block mt-3">
                {successMessage ? (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                ) : null}

                {errorMessage ? (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                ) : null}
              </div>
            </form>
          )}
        />
      </div>
    );
  }

  handleSubmit = (data, form) => {
    const {
      name,
      email,
      phone_number: phone,
      country,
      learn_root: channel,
      card_name,
      card_number,
      expire_date: card_expiry,
      cvc_code: card_cvv,
    } = data;

    const areaId = this.props.area?.id;

    ApiService.bookTour({
      area: areaId,
      name,
      email,
      phone,
      country,
      channel,

      card_name,
      card_number,
      card_expiry,
      card_cvv,
    })
      .then(data => {
        this.setState({
          successMessage: <Translate>You have successfully booked a tour.</Translate>,
          errorMessage: null,
        });
        form.pauseValidation();
        form.reset();

        // form.resumeValidation();
      })
      .catch(payload => {
        this.setState({
          successMessage: null,
          errorMessage: payload.data?.error ? (
            <Translate>{payload.data?.error}</Translate>
          ) : (
            <Translate>Something has gone wrong.</Translate>
          ),
        });
      });
  };
}

export default withLocalize(BookingForm);
