const pagesData = {
  home: {
    classList: ["vr-page"]
  },
  point: {
    classList: ["vr-page"]
  },
  trail: {
    classList: ["vr-page"]
  }
};

export default pagesData;
