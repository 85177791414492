import React, { Component } from 'react';

import 'aframe';

import { Entity } from 'aframe-react';

import VRSpace from '../../aframe/vrSpace';
import VRPlayer from '../../aframe/vrPlayer';

class VRVideoExperience extends Component {
  render() {
    const { data, player, setSpacePlayer, showPlayer } = this.props;

    return (
      <>
        <VRSpace space="video" setPlayer={setSpacePlayer} />

        <Entity className="scene-wrapper" position="0 2 0">
          <VRPlayer
            rotation={{ x: -10 }}
            position={{ y: -1, z: -2 }}
            placement={{ refresh: Date.now() }}
            visible={showPlayer}
            data={data}
            player={player}
          />
        </Entity>
      </>
    );
  }
}

export default VRVideoExperience;
