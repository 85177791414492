import './index.scss';

import React, { Component } from 'react';
import axios from 'axios';
import Translate from '../Translate';

const AppId = 'daee319402ff9129fc8c944bdcf7a75e';

class Weather extends Component {
  state = {
    dailyTemp: [],
    precipitation: null,
    humidity: null,
    pressure: null,
    averageTemp: null,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lat !== this.props.lat || prevProps.lon !== this.props.lon) {
      this.loadData();
    }
  }

  loadData = () => {
    const { lat, lon } = this.props;

    const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${AppId}&units=metric`;

    axios
      .get(apiUrl)
      .then(payload => {
        const list = payload.data.list;

        const today = list[0];

        const precipitation = today.weather[0].main;
        const humidity = Math.round(today.main.humidity);
        const pressure = Math.round(today.main.pressure);
        const averageTemp = Math.round(list.reduce((clc, item) => (clc += item.main.temp), 0) / 40);

        const dailyTemp = [];

        for (let i = 0; i < list.length; i += 8) {
          dailyTemp.push({
            dt: list[i].dt,
            value: Math.round(list[i].main.temp),
          });
        }

        this.setState({
          dailyTemp,
          precipitation,
          humidity,
          pressure,
          averageTemp,
        });
      })
      .catch(e => console.log(e));
  };

  render() {
    const { dailyTemp, precipitation, humidity, pressure, averageTemp } = this.state;

    return (
      <div className="weather-information">
        <div className="d-block mb-3">
          <div className="row mb-2">
            <p className="color-green">
              <Translate>Weather Forecast</Translate>
            </p>
          </div>
          <div className="row">
            {dailyTemp.map(day => (
              <div className="col" key={day.dt}>
                <p className="font-weight-bold">
                  {day.value}
                  <span className="align-top">&deg;</span>
                  <span>C</span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="d-block">
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item">
              <div className="d-block mb-3">
                <p className="color-green mb-2">
                  <Translate>Average temperature</Translate>
                </p>
                <p className="font-weight-bold">
                  {averageTemp}
                  <span className="align-top">&deg;</span>
                  <span>C</span>
                </p>
              </div>
              <div className="d-block mb-3">
                <p className="color-green mb-2">
                  <br />
                  <Translate>Humidity</Translate>
                </p>
                <p className="font-weight-bold">{humidity}%</p>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-block mb-3">
                <p className="color-green mb-2">
                  <br />
                  <Translate>Precipitation</Translate>
                </p>
                <p className="font-weight-bold">{precipitation}</p>
              </div>
              <div className="d-block mb-3">
                <p className="color-green mb-2">
                  <Translate>Atmospheric Pressure</Translate>
                </p>
                <p className="font-weight-bold">{pressure}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Weather;
