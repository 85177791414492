import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sponsors from '../../components/Sponsors';
import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';
import Translate from '../../components/Translate';
import { withLocalize } from 'react-localize-redux';

const aboutContentByLang = {
  en: (
    <>
      <p className="font-size-18">Dear Adventurers,</p>
      <p className="font-size-18">
        We are here to enhance your experience in protected areas of Armenia through our website and help you to plan
        your adventure trips in Armenian nature.
      </p>
      <p className="font-size-18">
        The protected areas of Armenia are famous for their natural and cultural heritage sites, stunning landscapes, as
        well as an extensive network of hiking trails and ecotourism. If you love hiking, discover Dilijan National
        Park’s trails that connect natural and architectural monuments where you can also engage in several fun
        activities such as a rope park and a zipline that passes over the lake Parz. Not far from Lake Parz, in a
        breeding centre occupying 10 hectares of forested land, you can meet the Red Deer and have a chance to
        communicate with them at the supplementary station. The species is now listed in the Red Data Book of Armenia as
        critically endangered.
      </p>
      <p className="font-size-18">
        A great experience is to visit Khosrov Forest State Reserve, where conservation traditions in Armenia started in
        the IVth century. It is home for more than half of the Armenian flora and endemic species, many of which are
        listed in the Red Data Book of Armenia, such as the Caucasian Leopard and the Brown Bear.
      </p>
      <p className="font-size-18">
        You also have an opportunity to visit Lake Sevan, one of the largest freshwater high-altitude lakes in the
        world, located in the Sevan National Park. Don't be surprised to encounter plenty of Armenian gulls at Lake
        Sevan - it hosts one of their largest colonies. The other site where you can meet these endangered birds is Lake
        Arpi National Park, located in the northwestern part of the country. The park is the habitat of the rare Black
        stork, Greylag goose and Common crane and the only place in Armenia where you can find the Dalmatian pelican.
        Further on your lake-themed journey, travel to magnificent gorges and crystal lakes of Zangezur Biosphere
        Complex and Planned Tatev National park. Here you can also have a once- in-a-lifetime experience of canyoning,
        while facing a wide variety of obstacles, jumps, abseils and a long swim.
      </p>
      <p className="font-size-18">We protect the treasures, so you can find it. Enjoy your trips with this platform!</p>
    </>
  ),
  hy: (
    <>
      <p className="font-size-18">Հարգելի արկածասերներ,</p>
      <p className="font-size-18">
        մենք այստեղ ենք ընդլայնելու ձեր փորձը Հայաստանի բնության պահպանվող տարածքներում: Այս կայքի միջոցով առաջարկում
        ենք օգնել ձեզ Հայաստանի բնության գրկում պլանավորելու ձեր արկածային ճամփորդությունները։
      </p>
      <p className="font-size-18">
        Մեր հատուկ պահպանվող տարածքները հայտնի են բնության եւ մշակութային ժառանգություններով, հիասքանչ բնատեսարաններով,
        արշավային արահետներով եւ էկոտուրիզմով։ Եթե արշավների սիրահար եք, ապա բացահայտեք Դիլիջան ազգային պարկի՝ բնության
        եւ ճարտարապետական հուշարձաններն իրար կապող արահետները, որտեղ կգտնեք նաեւ ժամանցի տարբերակներ՝ պարանների այգի կամ
        զիփլայն, որ անցնում է Պարզ լիճ վրայով: Պարզ լճին շատ մոտ՝ 10 հա անտառային տարածք զբաղեցնող բազմացման կենտրոնում,
        կարող եք հանդիպել նաեւ ազնվացեղ եղջերուների ու հաղորդակցվել նրանց հետ։ Ազնվացեղ եղջերուն ընդգրկված է Հայաստանի
        Կարմիր գրքում որպես կրիտիկական վիճակում գտնվող տեսակ:
      </p>
      <p className="font-size-18">
        Հիանալի տարբերակ է այցելելու նաեւ Խոսրովի անտառ պետական արգելոց. այստեղ պահպանման ավանդույթները սկսվել են դեռ IV
        դարից: Արգելոցի տարածքում աճում է Հայաստանի բուսական աշխարհի ավելի քան 50%-ը, այստեղ են բնակվում նաեւ Կարմիր
        գրքում գրանցված մի շարք հազվագյուտ կենդանիներ՝ կովկասյան ընձառյուծ, գորշ արջ։
      </p>
      <p className="font-size-18">
        Դուք նաեւ հնարավորություն ունեք այցելելու Սեւանա լիճ, որն աշխարհի բարձրլեռնային քաղցրահամ խոշոր լճերից մեկն է,
        գտնվում է Սեւան ազգային պարկում։ Չզարմանաք, եթե այստեղ տեսնեք բազմաթիվ որորներ. Սեւանա լճում է հայակական
        որորների ամենամեծ գաղութներից մեկը։ Մյուս հատվածը, որտեղ կհանդիպեք այս հազվագյուտ թռչուններին, Արփի լիճ ազգային
        պարկն է՝ երկրի հյուսիս-արեւմտյան մասում։ Պարկում կարելի է հանդիպել նաեւ հազվագյուտ սեւ արագիլի, մոխրագույն սագի
        ու մոխրագույն կռունկի, պարկը Հայաստանում նաեւ միակ բնակավայրն է գանգրափետուր հավալուսնի համար։ Լճերի թեմայով
        ճանապարհորդությունը շարունակելու համար այցելեք նաեւ Զանգեզուր կենսոլորտային համալիրի եւ պլանավորված Տաթեւ
        ազգային պարկի հեքիաթային կիրճերն ու բյուրեղյա լճերը։ Այստեղ ձեզ սպասում է կյանքի մի անմոռանալի փորձություն՝
        կիրճագնացություն. բազմաթիվ խոչընդոտների հաղթահարում, մագլցում, ցատկ, պարանով վայրեջք ու երկար լող։
      </p>
      <p className="font-size-18">
        Մենք պահպանում ենք գանձերը, որ դուք որոնեք դրանք։ Վայելեք ձեր ճամփորդությունները այս հարթակի միջոցով։
      </p>
    </>
  ),
  ru: (
    <>
      <p className="font-size-18">Дорогие искатели приключений,</p>
      <p className="font-size-18">
        мы здесь, чтобы расширить ваши представления об охраняемых территориях Армении и помочь запланировать ваше
        путешествие на лоне величественной армянской природы.
      </p>
      <p className="font-size-18">
        Охраняемые территории Армении известны своими природными и культурными достопримечательностями, удивительными
        пейзажами, тропами для походов и экотуризмом. Если вы являетесь любителями походов, то раскройте для себя тропы
        Дилижанского национального парка, связывающие природу и архитектурные памятники. Здесь вы также найдете такие
        увлекательные варианты для досуга, как парк канатов или зиплайн, который проходит над озером Парз. Близ озера
        Парз, в центре разведения Благородных оленей, занимающем лесной массив площадью 10 га, вы можете контактировать
        с оленями из специально адаптированной станции. Благородный олень включен в Красную книгу Армении как вид,
        находящийся под угрозой исчезновения.
      </p>
      <p className="font-size-18">
        Мы советуем также посетить Хосровский государственный заповедник, где традиции по охране природы известны с IV
        века. На территории заповедника сосредоточено более 50% флоры Армении, а также обитает ряд животных, включенных
        в Красную книгу: преднеазиатский леопард и бурый медведь.
      </p>
      <p className="font-size-18">
        Вы можете также посетить одно из крупнейших высокогорных пресноводных озер в мире – озеро Севан, которое
        находится в Севанском Национальном парке. Не удивляйтесь, если увидите здесь много чаек: в Севане находится одна
        из самых больших колоний Армянских чаек. Вы можете встретить этих редких птиц также в Национальном парке Озеро
        Арпи, расположенном в северо-западной части страны. Парк является домом для редкого Черного аиста, Серого гуся и
        Серого журавля, а также единственной в Армении средой обитания Кудрявого пеликана. Продолжая путешествие по
        озерам, не забудьте о роскошных ущельях и кристальных озерах биосферного комплекса «Зангезур» и запланированного
        национального парка «Татев». Здесь вас ждет захватывающий экстрим: каньонинг, преодоление многочисленных
        препятствий, лазание, прыжки, спуск по веревке и плавание на длительных дистанциях.
      </p>
      <p className="font-size-18">
        Мы храним сокровища, чтобы вы смогли их найти. Наслаждайтесь путешествиями с нашей платформой!
      </p>
    </>
  ),
};

class About extends Component {
  componentDidMount() {
    // document.querySelector('html').classList.remove('a-fullscreen');
    this.props.setUIParam('page', 'about');
    Loader.hideGroup('pageLoader');
  }

  componentDidUpdate() {
    Loader.hideGroup('pageLoader');
  }

  render() {
    const { activeLanguage } = this.props;

    const about_content = aboutContentByLang[activeLanguage.code] || null;

    return (
      <>
        <div className="container-header">
          <h1>
            <Translate>About the project</Translate>
          </h1>
        </div>
        <div className="container-inner">
          <div className="row">
            <div className="col col-12 col-lg-8 col-xl-8">{about_content}</div>
            <div className="col col-12 col-lg-4 col-xl-4">
              <h4 className="font-weight-semi-bold font-size-18">
                <Translate>SPONSORS</Translate>:
              </h4>
              <Sponsors mode="block" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUIParam(key, val) {
      dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(About));
