import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';

import 'aframe';

// import { Entity } from 'aframe-react';

import VRVideoExperience from './vrVideoExperience';
import VRTourExperience from './vrTourExperience';

class VRExperience extends Component {
  constructor(props) {
    super(props);

    this.state = { showPlayer: true };

    this.startClick = 0;
  }

  componentDidMount() {
    this.setState({
      showPlayer: true,
    });
    this.registerEvents();

    this.setTimer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.sky !== this.props.sky) {
      this.unRegisterEvents(prevProps.sky);
      this.registerEvents();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.unRegisterEvents();
  }

  render() {
    const { data, ...rest } = this.props;
    const { showPlayer } = this.state;

    if (isEmpty(data)) {
      return null;
    }

    let playerData = { urls: null };
    let isVideoExp = false;

    if (data.video_experience) {
      isVideoExp = true;
      playerData.urls = data.video_experience.urls;
    } else {
      playerData = data.tour_experience;
    }

    return isVideoExp ? (
      <VRVideoExperience data={playerData} showPlayer={showPlayer} {...rest} />
    ) : (
      <VRTourExperience data={playerData} showPlayer={showPlayer} {...rest} />
    );
  }

  registerEvents = () => {
    const { sky } = this.props;
    if (!sky) {
      return;
    }

    sky.addEventListener('mousedown', this.handleMouseDown);
    sky.addEventListener('mouseup', this.handleMouseUp);

    sky.addEventListener('mouseenter', this.handleMouseEnter);
    sky.addEventListener('mouseleave', this.handleMouseLeave);
  };

  unRegisterEvents = sky => {
    if (!sky) {
      sky = this.props.sky;
    }

    if (!sky) {
      return;
    }

    sky.removeEventListener('mousedown', this.handleMouseDown);
    sky.removeEventListener('mouseup', this.handleMouseUp);

    sky.removeEventListener('mouseenter', this.handleMouseEnter);
    sky.removeEventListener('mouseleave', this.handleMouseLeave);
  };

  setTimer = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ showPlayer: false });
    }, 5000);
  };

  handleMouseDown = () => {
    this.startClick = Date.now();

    clearTimeout(this.timer);
  };

  handleMouseUp = () => {
    if (Date.now() - this.startClick <= 200) {
      this.setState(prevState => ({ showPlayer: !prevState.showPlayer }));
    }

    this.setTimer();
  };

  handleMouseEnter = () => {
    this.setTimer();
  };

  handleMouseLeave = () => {
    clearTimeout(this.timer);
  };
}

export default VRExperience;
