import isNaN from 'lodash/isNaN';

export const composeValidators = (...validators) => {
  return value =>
    validators.reduce((error, validator) => {
      return error || validator(value);
    }, undefined);
};

class Validator {
  constructor() {
    throw new Error('Class does not support object creation!');
  }

  static required(value) {
    return value ? undefined : 'Required';
  }

  static number(value) {
    value = Number(value);
    return isNaN(value) ? 'Must be a number' : undefined;
  }

  static minNumberValue(min) {
    return value => {
      const ln = value.toString().length;
      return this.number(value) || ln >= min ? undefined : `Should be greater than ${min}`;
    };
  }

  static maxNumberValue(max) {
    return value => {
      const ln = value.toString().length;
      return this.number(value) || ln <= max ? undefined : `Should be smaller than ${max}`;
    };
  }

  static email(value) {
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regExp.test(value) ? undefined : `Provide correct email`;
  }

  static phone(value) {
    if (!value) return undefined;

    const regExp = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;

    return regExp.test(value) ? undefined : `Provide correct phone number`;
  }

  static creditCard(value) {
    value = value.toString().replace(/ /g, '');

    const regExp = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

    return regExp.test(value) ? undefined : `Provide valid credit card number`;
  }

  static creditCardCvc(value) {
    return undefined;
  }

  static creditCardDate(value) {
    return undefined;
  }
}

export default Validator;
