import { registerComponent } from 'aframe';

registerComponent('image-adaptation', {
  schema: {
    width: {
      type: 'number',
      default: 1,
    },
    height: {
      type: 'number',
      default: 1,
    },
    animation: {
      type: 'boolean',
      default: false,
    },
  },

  init: function init() {
    // console.info("image-adaptation:: init", this.data);
  },

  update: function update() {
    this._adaptImage();
  },
  tick: function tick() {
    const { map } = this.el.getObject3D('mesh').material;

    if (map && map.image.complete && map.image !== this.image) {
      this.image = map.image;
      this._adaptImage();
    }
  },

  _adaptImage: function _adaptImage() {
    if (!this.image) {
      return;
    }

    const repeat = {
      x: 1,
      y: 1,
    };

    const offset = {
      x: 0,
      y: 0,
    };

    const { material } = this.el.getObject3D('mesh');
    const { width, height } = this.data;

    if (!isNaN(width) && !isNaN(height)) {
      let originW;
      let originH;

      const rat = this.image.height / this.image.width;

      if (rat < 1) {
        originH = width;
        originW = originH / rat;
      } else {
        originW = width;
        originH = originW * rat;
      }

      repeat.x = Math.min(width / originW, 1);

      repeat.y = Math.min(height / originH, 1);

      offset.x = (1 - repeat.x) / 2;
      offset.y = (1 - repeat.y) / 2;
    }

    material.map.offset.set(offset.x, offset.y);
    material.map.repeat.set(repeat.x, repeat.y);
  },
});
