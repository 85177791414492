import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { localizeReducer } from 'react-localize-redux';

import uiReducer from './ui';
import vrReducer from './vr';
import areaReducer from './area';
import trailReducer from './trail';
import pointReducer from './point';
import storyReducer from './story';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    localize: localizeReducer,
    ui: uiReducer,
    vr: vrReducer,
    point: pointReducer,
    trail: trailReducer,
    area: areaReducer,
    story: storyReducer,
  });

export default rootReducer;
