import './index.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Helmet from 'react-helmet';

import classNames from 'classnames';

import PAGES_DATA from '../../constants/pagesData';

import AppHeader from './header';
import AppFooter from './footer';

import BigLoader from '../../components/BigLoader';
import { isMobile } from '../../utils';

class App extends Component {
  render() {
    const { children } = this.props;

    const classList = ['App', ...this.getPageClassList()].join(' ');

    const isMobileDevice = isMobile();

    return (
      <div className={classList}>
        <AppHeader />

        <main className={classNames('App-content container', { 'App-content-mobile': isMobileDevice })}>
          <BigLoader />
          {children}
        </main>
        <div className="d-block w-100" style={{ height: '1px' }} />
        {!isMobileDevice ? <AppFooter /> : null}
      </div>
    );
  }

  getPageClassList() {
    const { activePage } = this.props;

    const pageData = PAGES_DATA[activePage];

    const classList = pageData ? pageData.classList || [] : [];

    return [`${activePage}-page`, ...classList];
  }
}

function mapStateToProps(state) {
  return {
    activePage: state.ui.page,
  };
}

export default connect(mapStateToProps)(App);
