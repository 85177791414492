import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */
export const GET_STORY = 'GET_STORY';
export const GET_STORIES = 'GET_STORIES';
export const GET_SUBTITLES = 'GET_SUBTITLES';

export const RESET_STORY = 'RESET_STORY';
/**
 * THUNKS
 */
export const getStories = pointId => dispatch => {
  return dispatch(
    createAction(GET_STORIES, ApiService.getStories(pointId), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getStory = storyId => dispatch => {
  return dispatch(
    createAction(GET_STORY, ApiService.getStory(storyId), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getSubtitles = storyId => dispatch => {
  return dispatch(
    createAction(GET_SUBTITLES, ApiService.getSubtitles(storyId), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const resetStory = () => dispatch => dispatch(createAction(RESET_STORY));
