import { VR_HOTSPOT_DEFAULT, VR_HOTSPOT_GROUND, VR_HOTSPOT_AIR } from '../constants/assets';

function wrapToArrayIfItDidNot(param) {
  return Array.isArray(param) ? param : [param];
}

function mergeEventObjects(src = {}, dest = {}) {
  const obj = { ...dest };

  for (const key in src) {
    if (dest[key]) {
      obj[key] = [...wrapToArrayIfItDidNot(dest[key]), ...wrapToArrayIfItDidNot(src[key])];
    } else {
      obj[key] = src[key];
    }
  }

  return obj;
}

function getSpaceType(spaceData) {
  if (spaceData.box !== null) {
    return 'photo';
  }

  if (spaceData.video_urls !== null) {
    return 'video';
  }

  return 'unknown';
}

function getNumberSign($n) {
  const sign = ($n > 0) - ($n < 0);
  return sign === 0 ? 1 : sign;
}

function isVisible(obj) {
  if (obj && obj.visible) {
    return obj.parent ? isVisible(obj.parent) : true;
  }

  return false;
}

function numberToShow(x) {
  return (x < 10 ? `0${x}` : x).toString();
}

function cleanProps(props) {
  props = { ...props };
  const keys = Object.keys(props);

  for (const key of keys) {
    if (key[0] === '$') {
      delete props[key];
    }
  }

  return props;
}

function getIcon(animal) {
  let icon = '';
  switch (animal) {
    case 'gull':
      icon = '#vr-icon-gull';
      break;
    case 'bear':
      icon = '#vr-icon-bear';
      break;
    case 'chamois':
      icon = '#vr-icon-chamois';
      break;
    case 'tiger':
      icon = '#vr-icon-tiger';
      break;
    default:
      break;
  }

  return icon;
}

function getIconFromLocal(iconUrl) {
  let icon = iconUrl.split('/');
  icon = icon[icon.length - 1];
  let res = iconUrl;

  switch (icon) {
    case 'hotspot.png':
      res = '#' + VR_HOTSPOT_DEFAULT;
      break;
    // case 'hotspot-active.png':
    // res = '#stories360-icon-vr-hotspot-active';
    // break;
    case 'helicopter2.png':
    case 'helicopter.png':
      res = '#' + VR_HOTSPOT_AIR;
      break;
    case 'ground.png':
      res = '#' + VR_HOTSPOT_GROUND;
      break;
    default:
      break;
  }
  return res;
}

function getUrlParamsFromString(str) {
  return new Map(
    str
      .slice(str.indexOf('?') + 1)
      .split('&')
      .map(kv => kv.split('='))
  );
}

function wordwrap(str = '', width = 75) {
  if (!str) {
    return str;
  }

  str = str.replace(/\n+/gu, '\n');
  str = str.replace(/\s+/gu, ' ');

  let regex = `(.|\\s){1,${width}}(\\b|\\W)`;

  return str.match(RegExp(regex, 'gu'));
}

function getTextFragments(text) {
  let output = wordwrap(text, 60 * 6);

  return output;
}

function isMobile() {
  var check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

function convertDMSToDD(dms) {
  let dd = Number(dms);

  if (isNaN(dd)) {
    let parts = dms.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/);
    let degrees = parseFloat(parts[0]);
    let minutes = parseFloat(parts[1]);
    let seconds = parseFloat(parts[2].replace(',', '.'));
    let direction = parts[3];

    dd = degrees + minutes / 60 + seconds / (60 * 60);

    if (direction == 'S' || direction == 'W') {
      dd = dd * -1;
    } // Don't do anything for N or E
  }

  return dd;
}

export {
  wordwrap,
  getTextFragments,
  getIconFromLocal,
  isVisible,
  mergeEventObjects,
  getSpaceType,
  getNumberSign,
  numberToShow,
  cleanProps,
  getIcon,
  getUrlParamsFromString,
  isMobile,
  convertDMSToDD,
};
