import React, { Component } from 'react';
import 'aframe';

import { Entity } from 'aframe-react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getSpaceType } from '../../utils';
import VRScene from '../../components/aframe/vrScene';
import VRSpace from '../../components/aframe/vrSpace';
import VRStory from '../../components/aframe/vrStory';
import VRStoryPlayer from '../../components/aframe/vrStoryPlayer';
import VRStoryDetail from '../../components/aframe/vrStoryDetail';
import { setSpacePlayer } from '../../store/actions/vr';
import VRHotspot from '../../components/aframe/vrHotspot';

class VRPoint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedStory: null,
    };
  }

  openStory = story => {
    this.setState(prevState => ({
      ...prevState,
      openedStory: story,
    }));
  };

  closeStory = () => {
    this.setState(prevState => ({
      ...prevState,
      openedStory: null,
    }));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.point.id !== prevProps.point.id) {
      this.closeStory();
    }
  }

  render() {
    const { stories = [], point = {}, setSpacePlayer, history } = this.props;
    const { openedStory } = this.state;

    const pointVRContent = point.data || {};

    const { spots } = pointVRContent;

    const pointType = getSpaceType(pointVRContent);

    const data = pointType === 'photo' ? pointVRContent.box : pointVRContent.video_urls;

    return (
      <VRScene
        lookAt={{
          x: -pointVRContent.vlookat || 0,
          y: -pointVRContent.hlookat || 0,
        }}>
        <VRSpace data={data} space={pointType} setPlayer={setSpacePlayer} />

        <Entity className="scene-wrapper" position="0 2 0">
          {openedStory ? (
            openedStory.audio_url ? (
              <VRStoryPlayer storyId={openedStory.id} closeStory={this.closeStory} />
            ) : (
              <VRStoryDetail storyId={openedStory.id} closeStory={this.closeStory} />
            )
          ) : null}

          <Entity className="scene-objects">
            {stories.map(story => (
              <VRStory
                key={story.id}
                visible={!openedStory || openedStory.id === story.id}
                image={story.images[0].url}
                title={story.title}
                id={story.id}
                position={{
                  x: 0,
                  y: 0,
                  z: -5,
                }}
                rotation={{
                  x: -story.atv,
                  y: -story.ath,
                  z: 0,
                }}
                events={{
                  click: () => {
                    this.openStory(story);
                  },
                }}
              />
            ))}

            {spots.map(spt => (
              <VRHotspot
                key={spt.id}
                data={spt}
                events={{ click: () => history.push(`/${point.area.url}/${spt.to_point.url}`) }}
              />
            ))}
          </Entity>
        </Entity>
      </VRScene>
    );
  }
}

function mapStateToProps(state) {
  return {
    player: state.vr.spacePlayer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpacePlayer: player => {
      dispatch(setSpacePlayer(player));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VRPoint));
