import isPromise from 'is-promise';
import ErrorService from '../../services/error-service';

const ErrorMiddleware = () => {
  return next => action => {
    if (!isPromise(action.payload) && action.error && action.meta.__PARSABLE_ERROR) {
      return next({ ...action, payload: ErrorService.parse(action.payload) });
    }

    if (isPromise(action.payload) && action.meta.__HANDLE_ERROR) {
      return next(action).catch((error = {}) => {
        if (error instanceof Error) {
          console.error('HANDLED_ERROR\n', error);
        }
        return { ...error, __HANDLED_ERROR: true };
      });
    }

    return next(action);

    // If not a promise, continue on
    // if (!isPromise(action.payload)) {
    //   return next(action);
    // }

    /*
     * Another solution would would be to include a property in `meta`
     * and evaulate that property.
     *
     * if (action.meta.globalError === true) {
     *   // handle error
     * }
     *
     * The error middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `catch`.
     */

    // Dispatch initial pending promise, but catch any errors
    // return next(action).catch(error => {
    //   console.log('parse error', error);
    //   return error;
    // });
  };
};

export default ErrorMiddleware;
