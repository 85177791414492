import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getArea, getAreas } from '../../store/actions/area';
import { getTrails } from '../../store/actions/trail';
import Weather from '../../components/Weather';
import rain from '../../assets/rain.svg';
import { Link } from 'react-router-dom';
import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';
import Translate from '../../components/Translate';

import NotFound from '../error';
import { convertDMSToDD } from '../../utils';

class Area extends Component {
  state = {
    showErrorPage: false,
  };

  componentDidMount() {
    this.props.setUIParam('page', 'area');
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isDataLoaded) {
      Loader.hideGroup('pageLoader');
    }

    const { areaId } = this.props.match.params;
    const { areaId: old_id } = prevProps.match.params;
    if (old_id && areaId !== old_id) {
      this.loadData();
    }
  }

  render() {
    const { showErrorPage } = this.state;

    if (showErrorPage) {
      return <NotFound />;
    }

    const { area, trails } = this.props;

    const trailItems = trails.length ? (
      trails.map(trail => (
        <div className="col col-12 col-lg-4 col-xl-4" key={trail.id}>
          <div className="card w-100">
            <Link to={`/${area.url}/trail-${trail.url}`} className="text-decoration-none">
              <div className="img-wrapper">
                <img src={trail.image_url} className="card-img-top" alt={trail.description} />
              </div>

              <div className="card-body px-0">
                <p className="card-text text-left">{trail.title}</p>
              </div>
            </Link>
          </div>
        </div>
      ))
    ) : (
      <div className="text-center">No trails found.</div>
    );

    const {
      about,
      bio_diversity,
      ecosystem,
      culture_and_traditions,
      how_to_get_there,
      culture_images = [],
      gallery_images = [],
      latitude = '',
      longitude = '',
    } = area;

    return (
      <>
        <div className="container-header">
          <div>
            <Link
              to={`/${area.url}/${area.default_point?.url}`}
              className="btn background-green-light color-white back-to-360view">
              <Translate>Back to 360View</Translate>
            </Link>
          </div>

          {/*
          area info
          Biodiversity
          Ecosystems
          Culture and Traditions
          How to get there
          Trails
          Services
          Gallery
          */}

          <h1>{area.name}</h1>
          <ul className="nav sub-nav">
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#area-info-section" rel="noopener noreferrer">
                <Translate>AREA INFO</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#biodiversity-section" rel="noopener noreferrer">
                <Translate>BIODIVERSITY</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#ecosystem-section" rel="noopener noreferrer">
                <Translate>Ecosystems</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link font-family-cuprum"
                href="#culture_and_traditions-section"
                rel="noopener noreferrer">
                <Translate>Culture and Traditions</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#how_to_get-section" rel="noopener noreferrer">
                <Translate>How to get there</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#trails-section" rel="noopener noreferrer">
                <Translate>TRAILS</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#hotels-section" rel="noopener noreferrer">
                <Translate>HOTELS</Translate>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link font-family-cuprum" href="#gallery-section" rel="noopener noreferrer">
                <Translate>GALLERY</Translate>
              </a>
            </li>
          </ul>
        </div>
        <div className="container-inner">
          <section className="row pb-0" id="area-info-section">
            <div className="col col-12 col-lg-8 col-xl-8">
              <div className="d-block">
                <h4>
                  <Translate>AREA INFO</Translate>
                </h4>
                <p>{about}</p>
              </div>

              <div className="d-block pb-0" id="biodiversity-section">
                <h4>
                  <Translate>bio diversity</Translate>
                </h4>
                <p>{bio_diversity}</p>
              </div>

              <div className="d-block pb-0" id="ecosystem-section">
                <h4>
                  <Translate>ecosystem</Translate>
                </h4>
                <p>{ecosystem}</p>
              </div>

              <div className="d-block pb-0" id="culture_and_traditions-section">
                <h4>
                  <Translate>culture and traditions</Translate>
                </h4>
                <p>{culture_and_traditions}</p>
              </div>

              <div className="d-block pb-5" id="how_to_get-section">
                <h4>
                  <Translate>how to get there</Translate>
                </h4>
                <p>{how_to_get_there}</p>
              </div>
            </div>
            <div className="col col-12 col-lg-4 col-xl-4">
              <address>
                <strong className="font-size-15">
                  <Translate>Hot-lines and contact details</Translate>:
                </strong>
                <br />
                <span className="color-green-light font-size-14">{area.phone_number}</span>
                <br />
                <br />
                <strong className="font-size-15">
                  <Translate>GPS coordinates</Translate>
                </strong>
                <br />
                <span className="font-size-14">
                  {area.latitude}, {area.longitude}
                </span>
              </address>
              <Weather lat={convertDMSToDD(latitude)} lon={convertDMSToDD(longitude)} />

              <div className="d-block">
                <p className="color-green mb-2">
                  <Translate>Opening Hours</Translate>
                </p>
                <p className="font-size-14 mb-0">
                  <Translate>Hours</Translate>: {area.working_hours}
                </p>
              </div>
            </div>
          </section>

          {area.bookable ? (
            <section className="row mx-0 py-4 background-concrete border-radius-8">
              <Link
                to={`/booking/${area.url}`}
                className="btn btn-secondary text-uppercase mx-auto p-3 font-size-19 w-50">
                <Translate>BOOK A VISIT</Translate>
              </Link>
            </section>
          ) : null}

          <section className="d-block py-5" id="trails-section">
            <div className="row mb-3">
              <h2 className="text-center text-uppercase w-100">
                <Translate>TRAILS</Translate>
              </h2>
            </div>
            <div className="row">{trailItems}</div>
          </section>

          <section className="d-block background-concrete border-radius-8 px-4 py-5" id="hotels-section">
            <div className="row mb-3">
              <h2 className="text-center text-uppercase w-100">
                <Translate>culture</Translate>
              </h2>
            </div>
            <div className="row">
              {culture_images.map(img => (
                <div className="col col-12 col-lg-4 col-xl-4" key={img.url}>
                  <a href={img.link} target="_blank" rel="noopener noreferrer" className="card w-100">
                    <img src={img.url} className="card-img-top" alt={img.name} />

                    <div className="card-body px-0">
                      <p className="card-text text-left color-green">{img.name}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </section>

          <section className="d-block py-5" id="gallery-section">
            <div className="row mb-3">
              <h2 className="text-center text-uppercase w-100">
                <Translate>gallery</Translate>
              </h2>
            </div>
            <div className="row">
              {gallery_images.map(img => (
                <div className="col col-12 col-lg-4 col-xl-4" key={img.url}>
                  <a href={img.url} target="_blank" rel="noopener noreferrer" className="card w-100">
                    <div className="img-wrapper">
                      <img src={img.url} className="img card-img-top" alt={img.name} />
                    </div>
                    <div className="card-body px-0">
                      <p className="card-text text-left">{img.name}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>
      </>
    );
  }

  loadData = () => {
    Loader.addToGroup(Loader.show(), 'areaPage');
    const { areaId } = this.props.match.params;

    return Promise.all([this.props.getAreas(), this.props.getArea(areaId), this.props.getTrails(areaId)])
      .finally(() => Loader.hideGroup('areaPage'))
      .then(d => {
        for (let i = 0; i < d.length; i++) {
          if (d[i].statusCode === 404) {
            this.setState({
              showErrorPage: true,
            });
            break;
          }
        }
      });
  };
}

function mapStateToProps(state) {
  return {
    isDataLoaded: state.trail.list.loaded && state.area.current.loaded,
    area: state.area.current.data,
    trails: state.trail.list.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAreas() {
      return dispatch(getAreas());
    },
    getArea(areaId) {
      return dispatch(getArea(areaId));
    },
    getTrails(areaId) {
      return dispatch(getTrails(areaId));
    },
    setUIParam(key, val) {
      return dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Area);
