import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getAreas } from '../../store/actions/area';
import Translate from '../Translate';

class AreaMobileMenu extends Component {
  componentDidMount() {
    this.props.getAreas();
  }

  render() {
    const { areas } = this.props;
    return (
      <div className="mobile-sub-menu">
        <ul className="nav navbar-nav px-3 py-2 d-block limit-height">
          <li className="nav-item">
            <span className="color-yellow-light text-uppercase font-size-12">
              <Translate>Areas</Translate>
            </span>
          </li>
          {areas.map(item => (
            <li className="nav-item" key={item.id}>
              <NavLink className="nav-link" to={`/${item.url}`}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    areas: state.area.list.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAreas() {
      return dispatch(getAreas());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaMobileMenu);
