import React, { Component } from "react";
import "aframe";

import { Entity } from "aframe-react";
import Player from "../../../lib/Player";

class VideoSpace extends Component {
  constructor(props) {
    super(props);

    this.player = new Player("video");

    props.setPlayer(this.player);
  }

  componentWillUnmount() {
    this.player.destroy();
    delete this.player;
    this.props.setPlayer(null);
  }

  render() {
    return (
      <Entity
        primitive="a-videosphere"
        radius="205"
        rotation="0 -90 0"
        material={{ src: this.player.media, shader: "flat" }}
        segments-width="64"
        segments-height="64"
      />
    );
  }
}

export default VideoSpace;
