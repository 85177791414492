import AFRAME, { registerComponent } from 'aframe';

import Interactive from '../../../services/Interactive';

AFRAME.components.visible.Component.prototype.update = function update(oldData) {
  if (this.data !== oldData) {
    this.el.object3D.visible = this.data;
    Interactive.update();
  }
};

export default registerComponent('interactive', {
  schema: {
    enable: {
      type: 'boolean',
      default: true,
    },
    cursor: {
      type: 'string',
      default: 'pointer',
    },
  },

  init: function init() {
    this.cursorStyle = null;

    this._bindMethods();

    this.el.addEventListener('mouseenter', this._handleMouseEnter);

    this.el.addEventListener('mouseleave', this._handleMouseLeave);

    Interactive.addObject(this.el);
  },

  remove: function remove() {
    this.el.removeEventListener('mouseenter', this._handleMouseEnter);
    this.el.removeEventListener('mouseleave', this._handleMouseLeave);

    Interactive.removeObject(this.el);
  },

  update: function update(oldData) {
    // because when we don't assign value to attribute react by default set it true
    if (this.data === true) {
      this.data = this.nextData;
    }

    const { enable } = this.data;
    const el = this.el;

    if (enable !== oldData.enable) {
      if (enable) {
        Interactive.addObject(this.el);
      } else {
        Interactive.removeObject(this.el);
      }
    }
  },

  _handleMouseEnter: function _handleMouseEnter() {
    const canvas = this.el.sceneEl.canvas;
    this.cursorStyle = canvas.style.cursor;
    if (this.cursorStyle !== this.data.cursor) {
      canvas.style.cursor = this.data.cursor;
    }
  },
  _handleMouseLeave: function _handleMouseLeave() {
    const canvas = this.el.sceneEl.canvas;
    canvas.style.cursor = this.cursorStyle;
    this.cursorStyle = null;
  },

  _bindMethods: function _bindMethods() {
    this._handleMouseEnter = this._handleMouseEnter.bind(this);
    this._handleMouseLeave = this._handleMouseLeave.bind(this);
  },
});
