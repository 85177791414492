import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { getTrails } from '../../store/actions/trail';

class TrailMobileMenu extends Component {
  componentDidMount() {
    const { match } = this.props;
    const { areaId } = match.params;
    this.props.getTrails(areaId);
  }

  render() {
    const {
      trails,
      match: {
        params: { areaId },
      },
    } = this.props;
    return (
      <div className="mobile-sub-menu">
        <ul className="nav navbar-nav px-3 py-2 d-block limit-height">
          {trails.map(item => (
            <li className="nav-item" key={item.id}>
              <NavLink className="nav-link" to={`/${areaId}/trail-${item.url}`}>
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trails: state.trail.list.data,
    area: state.area.current.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTrails(areaId) {
      return dispatch(getTrails(areaId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrailMobileMenu));
