import * as actionTypes from '../actions/trail';

const initialState = {
  current: {
    loaded: false,
    error: null,
    data: {},
  },
  list: {
    loaded: false,
    error: null,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    /*  GET TRAIL PART  */
    case `${actionTypes.GET_TRAIL}_LOADING`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_TRAIL}_ERROR`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: action.payload,
        },
      };
    }
    case `${actionTypes.GET_TRAIL}_SUCCESS`: {
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload.data,
          loaded: true,
          error: null,
        },
      };
    }
    /*  GET TRAILS PART  */
    case `${actionTypes.GET_TRAILS}_LOADING`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_TRAILS}_ERROR`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: true,
          error: action.payload,
        },
      };
    }
    case `${actionTypes.GET_TRAILS}_SUCCESS`: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          loaded: true,
          error: null,
        },
      };
    }
    default:
      return state;
  }
};
