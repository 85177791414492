import React, { Component } from "react";

import omit from "lodash/omit";
import "aframe";
import { Entity } from "aframe-react";

import "./components/interactive";
import { mergeEventObjects } from "../../utils";

class VRButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };
  }

  handleMouseEnter = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: true
    }));
  };

  handleMouseLeave = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: false
    }));
  };

  render() {
    const { isActive } = this.state;

    const {
      disable = false,
      events,
      style,
      styleActive,
      position = "0 0 0.001",
      primitive = "a-plane"
    } = this.props;

    const defaultStyle = {
      color: null,
      img: null,
      width: 0.1,
      height: 0.1,
      ...style
    };
    const activeStyle = {
      ...defaultStyle,
      ...styleActive
    };

    const btnStyle = isActive ? activeStyle : defaultStyle;

    const btnMt = { shader: "flat", opacity: disable ? 0.7 : 1 };

    if (btnStyle.img) {
      btnMt.transparent = true;
      btnMt.src = btnStyle.img;
    } else if (btnStyle.color) {
      btnMt.color = btnStyle.color;
      btnMt.transparent = false;
    }

    const eventsList = mergeEventObjects(
      {
        mouseenter: this.handleMouseEnter,
        mouseleave: this.handleMouseLeave
      },
      events
    );

    return (
      <Entity
        {...omit(this.props, [
          "position",
          "interactive",
          "primitive",
          "width",
          "height",
          "material"
        ])}
        interactive={{ enable: !disable }}
        events={eventsList}
        className="player-control-button"
        primitive={primitive}
        width={btnStyle.width}
        height={btnStyle.height}
        position={position}
        material={btnMt}
      >
        {this.props.children}
      </Entity>
    );
  }
}

export default VRButton;
