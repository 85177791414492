import React, { Component } from 'react';

import 'aframe';
import isFunction from 'lodash/isFunction';

import { Entity } from 'aframe-react';
import VRButton from '../vrButton';
import VRImage from '../vrImage';

import '../components/interactive';

class VRGallery extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      current: props.current || 0,
      prevActive: null,
      active: props.active || null,
      itemsRatio: {},
    };
    /*
    el.setAttribute("animation__zoomin", ``);

    el.setAttribute(
      "animation__rotin",
      `property: rotation; to: 15 0 0; dur:500;`
    );

    el.setAttribute(
      "animation__movein",
      `property: position; to:${el.object3D.position.x} ${height / 2 +
        0.2} 0.1; dur:500;`
    );*/

    this.__config = {
      list: [],
      displayCount: 3,
      current: 0,
      prevActive: null,
      active: null,
      mode: '',
      width: 0.5,
      height: 0.5,
      margin: 0.1,
      position: '',
      rotation: '',
      ...props,
      ...this.state,
    };
  }

  handleLeftClick = () => {
    const { list, displayCount, current } = this.__config;
    if (list.length > displayCount && current !== 0) {
      this.setState(prevState => ({
        ...prevState,
        current: prevState.current - 1,
      }));
    }
  };

  handleRightClick = () => {
    const { list, displayCount, current } = this.__config;
    if (list.length > displayCount && current < list.length - displayCount) {
      this.setState(prevState => ({
        ...prevState,
        current: prevState.current + 1,
      }));
    }
  };

  handleImageClick = index => {
    this.setState(prevState => {
      const active = prevState.active === index ? null : index;

      if (isFunction(this.props.onImageClick)) {
        this.props.onImageClick(active);
      }

      return {
        ...prevState,
        prevActive: prevState.active,
        active,
      };
    });
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (this.mounted && nextProps.current !== this.props.current) {
      this.setState(prevState => ({
        ...prevState,
        current: nextProps.current,
      }));
    }

    if (this.mounted && nextProps.active !== this.props.active) {
      this.setState(prevState => ({
        ...prevState,
        prevActive: prevState.active,
        active: nextProps.active,
      }));
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    this.__config = { ...this.__config, ...nextProps, ...nextState };
  }

  render() {
    const {
      list,
      displayCount,
      current,
      width,
      height,
      margin,
      position,
      rotation,
      mode,

      active,
      prevActive,
    } = this.__config;

    const activeList = list.slice(current, displayCount + current);

    const arrowX = ((width + margin) * displayCount) / 2 - 0.05;

    return (
      <Entity className="gallery-wrapper" rotation={rotation} position={position}>
        <Entity className="gallery-content">
          {list.map((item, i) => {
            const index = activeList.indexOf(item);
            const x = -(((displayCount - 1) * (width + margin)) / 2) + index * (width + margin);

            let animations = {};
            let isZoomed = false;

            const ratio = this.state.itemsRatio[item.id] || 1;
            const scaleX = 3;
            const scaleY = ratio * scaleX;

            if (mode === 'zoom') {
              isZoomed = active === i;

              if (isZoomed) {
                animations = {
                  animation__zoomIn: {
                    property: 'scale',
                    to: `${scaleX} ${scaleY} ${scaleX}`,
                    dur: 500,
                  },
                  animation__rotateIn: {
                    property: 'object3D.rotation.x',
                    to: `15`,
                    dur: 500,
                  },
                  animation__moveIn: {
                    property: 'object3D.position.y',
                    to: (height * 4) / 2 + 0.1,
                    dur: 500,
                  },
                };
              } else if (prevActive === i) {
                animations = {
                  animation__zoomOut: {
                    property: 'scale',
                    to: `1 1 1`,
                    dur: 300,
                  },
                  animation__rotateOut: {
                    property: 'object3D.rotation.x',
                    from: `15`,
                    to: `0`,
                    dur: 200,
                  },
                  animation__moveOut: {
                    property: 'object3D.position.y',
                    to: `0`,
                    dur: 300,
                  },
                };
              }
            }

            return (
              <Entity key={item.id} visible={index > -1} position={{ x }}>
                <Entity {...animations}>
                  <VRImage
                    src={item.image}
                    saveRatio
                    width={width}
                    height={height}
                    interactive
                    {...(isZoomed
                      ? {
                          'image-adaptation': {
                            width: 'auto',
                            height: 'auto',
                          },
                        }
                      : {})}
                    events={{
                      click: () => {
                        this.handleImageClick(i);
                      },
                    }}
                    onLoad={img => {
                      this.setState(st => ({
                        ...st,
                        itemsRatio: {
                          ...st.itemsRatio,
                          [item.id]: img.height / img.width,
                        },
                      }));
                    }}
                  />
                  <Entity
                    primitive="a-plane"
                    width={width}
                    height="0.02"
                    position={{ y: -height / 2 + 0.01, z: 0.001 }}
                    material={{ shader: 'flat', color: '#aa452b' }}
                  />
                </Entity>
              </Entity>
            );
          })}
        </Entity>
        <Entity className="gallery-controls">
          <VRButton
            disable={list.length <= displayCount || current === 0}
            events={{ click: this.handleLeftClick }}
            position={{ x: -arrowX, z: 0.01 }}
            style={{ img: '#vr-arrow-left' }}
          />

          <VRButton
            disable={list.length <= displayCount || current + displayCount > list.length - 1}
            events={{ click: this.handleRightClick }}
            position={{ x: arrowX, z: 0.01 }}
            style={{ img: '#vr-arrow-right' }}
          />
        </Entity>
      </Entity>
    );
  }
}

export default VRGallery;
