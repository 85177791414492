import React, { Component } from 'react';

import { THREE } from 'aframe';

import { Entity } from 'aframe-react';

import { numberToShow } from '../../../utils';

import isNaN from 'lodash/isNaN';

import '../components/interactive';

class VRTimeline extends Component {
  constructor(props) {
    super(props);

    this.timelineWrapper = React.createRef();

    this.state = {
      isActive: false,
    };

    this.defaultStyle = {
      mainColor: '#3EA89E',
      buttonColor: '#A7CB56',
    };
  }

  handleMouseEnter = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: true,
    }));
  };

  handleMouseLeave = () => {
    this.setState(prevState => ({
      ...prevState,
      isActive: false,
    }));
  };

  handleClick = ({ detail }) => {
    const wrapper = this.timelineWrapper.current.el;

    const wrapperWidth = Number(wrapper.getAttribute('width'));

    const wrapperX = Math.abs(wrapper.object3D.position.x);

    const worldClickCoord = detail.intersection.point;
    const clickCoord = wrapper.object3D.parent.worldToLocal(
      new THREE.Vector3(worldClickCoord.x, worldClickCoord.y, worldClickCoord.z)
    );

    const coordByPr = (clickCoord.x + wrapperX + wrapperWidth / 2) / wrapperWidth; // 0 - 1 range

    this.props.handleChange(coordByPr);
  };

  render() {
    const { isActive } = this.state;

    const { mainColor, buttonColor } = this.defaultStyle;

    const { current, total, position = '0 0 0.001' } = this.props;

    const totalMin = Math.floor(total / 60) || 0;
    const totalSec = Math.floor(total - totalMin * 60) || 0;

    const currentMin = Math.floor(current / 60) || 0;
    const currentSec = Math.floor(current - currentMin * 60) || 0;

    const wrapperWidth = 1;
    const wrapperHeight = isActive ? 0.02 : 0.015;

    let width = (wrapperWidth * current) / total;
    let x = -(wrapperWidth / 2 - width / 2);

    if (isNaN(width) || width === 0) {
      width = wrapperWidth;
      x = 0;
    }

    return (
      <Entity className="timeline-wrapper" position={position}>
        <Entity
          className="timeline"
          primitive="a-plane"
          material="shader: flat; transparent: true; opacity: 0"
          width={wrapperWidth}
          height="0.07"
          interactive
          events={{
            mouseenter: this.handleMouseEnter,
            mouseleave: this.handleMouseLeave,
            click: this.handleClick,
          }}
        />
        <Entity
          ref={this.timelineWrapper}
          className="timeline-background"
          primitive="a-plane"
          width={wrapperWidth}
          height={wrapperHeight}
          material="color: #ddeede; shader: flat">
          <Entity
            className="timeline-fill"
            primitive="a-plane"
            position={{ x, z: 0.001 }}
            width={width}
            height={wrapperHeight}
            material="color: #ffffff; shader: flat"
          />
        </Entity>
        <Entity className="timeline-counter" position="0.6 0 0.001">
          <Entity
            className="timeline-counter-current"
            text={{
              value: `${numberToShow(currentMin)}:${numberToShow(currentSec)}`,
              align: 'center',
              wrapCount: 40,
              color: '#ddeede',
            }}
          />
          <Entity
            primitive="a-plane"
            position="0.075 0 0.001"
            width="0.005"
            height="0.04"
            material="color: #ddeede; shader: flat"
          />
          <Entity
            position="0.15 0 0"
            className="timeline-counter-total"
            text={{
              value: `${numberToShow(totalMin)}:${numberToShow(totalSec)}`,
              align: 'center',
              wrapCount: 40,
              color: '#ddeede',
            }}
          />
        </Entity>
      </Entity>
    );
  }
}

export default VRTimeline;
