import React, { Component } from 'react';

import isNaN from 'lodash/isNaN';

import 'aframe';

import '../components/interactive';

import { Entity } from 'aframe-react';
import VRTimeline from './vrTimeline';
import VRQuality from './vrQuality';
import VRButton from '../vrButton';

class VRPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTime: 0,
      currentTime: 0,
      storedTime: 0,
      isPlaying: !!props.autoplay,
      isMuted: !!props.mute,
    };

    if (props.player) {
      this.updatePlayer();
    }
  }

  defaultStyle = {
    mainColor: '#3EA89E',
    buttonColor: '#A7CB56',
  };

  componentDidUpdate(prevProps) {
    const { player } = this.props;
    if (player && player !== prevProps.player) {
      this.updatePlayer();
    }
  }

  componentWillUnmount() {
    this.unregisterEvents();
  }

  render() {
    const { mainColor, buttonColor } = this.defaultStyle;
    const { player, data, target = 'video', ...rest } = this.props;

    const { totalTime, currentTime, isPlaying, isMuted } = this.state;

    return (
      <Entity className="player-wrapper" {...rest}>
        <Entity
          interactive="cursor: default;"
          primitive="a-plane"
          color={mainColor}
          height="0.2"
          width="1.8"
          material="shader: flat; transparent: true; opacity: 1"
        />

        <Entity>
          <VRButton
            events={{ click: this.handlePlayBtnClick }}
            style={{
              color: buttonColor,
              width: 0.14,
              height: 0.16,
            }}
            position={{ x: -0.81, z: 0.001 }}>
            {isPlaying ? (
              <Entity>
                <Entity
                  primitive="a-plane"
                  width="0.012"
                  height="0.06"
                  material="color: #ffffff"
                  position="-0.015 0 0.001"
                />
                <Entity
                  primitive="a-plane"
                  width="0.012"
                  height="0.06"
                  material="color: #ffffff"
                  position="0.015 0 0.001"
                />
              </Entity>
            ) : (
              <Entity
                scale="0.6 0.6 0.6"
                vertex-a="0 0.02 0"
                vertex-b="-0.05 -0.05 0"
                vertex-c="0.05 -0.05 0"
                primitive="a-triangle"
                material="color: #ffffff"
                position="0.01 0 0.001"
                rotation="0 0 -90"
              />
            )}
          </VRButton>

          <VRTimeline
            current={currentTime}
            total={totalTime}
            handleChange={this.handleTimeChange}
            position={{ x: target === 'video' ? -0.2 : -0.1, z: 0.001 }}
          />
          {target === 'video' ? (
            <>
              <VRButton
                events={{ click: this.handleMuteBtnClick }}
                position={{ x: 0.68, z: 0.001 }}
                style={{ img: isMuted ? '#vr-volume-off' : '#vr-volume-on' }}
              />
              <VRQuality player={player} options={data.urls} position={{ x: 0.8, z: 0.001 }} />
            </>
          ) : null}
        </Entity>
      </Entity>
    );
  }

  handlePlayBtnClick = () => {
    const { player } = this.props;

    if (player) {
      if (player.getPaused()) {
        player.play();
      } else {
        player.pause();
      }
    }
  };

  handleMuteBtnClick = () => {
    const { player } = this.props;

    if (player) {
      if (player.getMuted()) {
        player.unmute();
      } else {
        player.mute();
      }
    }
  };

  handleTimeChange = percent => {
    const { totalTime } = this.state;
    const { player } = this.props;

    let time = totalTime * percent;

    if (time > totalTime) {
      time = totalTime;
    }

    player.setCurrentTime(time);
  };

  updatePlayer() {
    this.unregisterEvents();
    this.registerEvents();
  }

  registerEvents() {
    const { player } = this.props;

    player.on('ready', this.__handleReady);
    player.on('play', this.__handlePlay);
    player.on('pause', this.__handlePause);
    player.on('ended', this.__handlePause);

    player.on('state-change:src', this.__handleSrcChange);
    player.on('state-change:mute', this.__handleMuteChange);

    player.on('timeupdate', this.__handleTimeupdate);
    player.on('buffered', this.__handleBuffered);
  }

  unregisterEvents() {
    const { player } = this.props;

    if (!player) {
      return;
    }

    player.off('ready', this.__handleReady);
    player.off('play', this.__handlePlay);
    player.off('pause', this.__handlePause);
    player.off('ended', this.__handlePause);

    player.off('state-change:src', this.__handleSrcChange);
    player.off('state-change:mute', this.__handleMuteChange);

    player.off('timeupdate', this.__handleTimeupdate);
    player.off('buffered', this.__handleBuffered);
  }

  __handleReady = () => {
    const { isPlaying, storedTime } = this.state;
    const { player } = this.props;

    if (player && isPlaying) {
      player.setCurrentTime(storedTime);
      player.play();
    }
  };

  __handlePlay = () => {
    this.setState(prevState => ({
      ...prevState,
      isPlaying: true,
    }));
  };

  __handlePause = () => {
    this.setState(prevState => ({
      ...prevState,
      isPlaying: false,
    }));
  };

  __handleSrcChange = ({ currentTime }) => {
    this.setState(prevState => ({
      ...prevState,
      storedTime: currentTime,
    }));
  };

  __handleMuteChange = () => {
    this.setState(prevState => ({
      ...prevState,
      isMuted: this.props.player.getMuted(),
    }));
  };

  __handleTimeupdate = ({ seconds, duration }) => {
    this.setState(prevState => ({
      ...prevState,
      currentTime: isNaN(seconds) ? 0 : seconds,
      totalTime: isNaN(duration) ? 0 : duration,
    }));
  };

  __handleBuffered = ({ percent }) => {
    // console.log("percent::", percent);
  };
}

export default VRPlayer;
