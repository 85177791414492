import './index.scss';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { history } from '../../../config/redux-store';
import { NavLink, Link } from 'react-router-dom';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, Card, CardBody } from 'reactstrap';

import { isMobile } from '../../../utils';

import Translate from '../../../components/Translate';
import Language from '../../../components/Language';
import Social from '../../../components/Social';
import SubMenu from '../../../components/SubMenu';
import MobileSubMenu from '../../../components/MobileSubMenu';
import Sponsors from '../../../components/Sponsors';
import TrailDetails from '../../../components/Modals/TrailDetails';

import { ReactComponent as Arrow } from '../../../assets/arrow-left.svg';
import { ReactComponent as ArrowDown } from '../../../assets/arrow-down.svg';
import logo from '../../../assets/logo.png';
import logoMobile from '../../../assets/logo-mobile.png';

class AppHeader extends Component {
  state = {
    collapsed: true,
  };

  componentDidMount() {
    history.listen(() => {
      this.toggleNavbar(true);
    });
  }

  render() {
    const { collapsed } = this.state;
    const { ui, trail } = this.props;

    const year = new Date().getFullYear();

    const isMobileDevice = isMobile();

    const mobileMenu = (
      <Navbar fixed="top" className="navbar-mobile">
        <div className="w-100 d-flex px-3 py-2 navbar-mobile--top">
          <NavbarBrand href="/" className="mr-auto">
            <img src={logoMobile} className="d-inline-block img-fluid align-top" alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        </div>
        <Collapse isOpen={!collapsed} navbar>
          <MobileSubMenu />
          <div className="d-flex px-3 navbar--lang-and-soc justify-content-between">
            <Social className="" />
            <Language className="" />
          </div>
          <Nav className="px-3 py-2" navbar>
            <NavItem>
              <NavLink className="nav-link" to="/about">
                <Translate>About the project</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <a className="nav-link" href="https://forum.protectedareas.am/" target="_blank" rel="noopener noreferrer">
                <Translate>Forum</Translate>
              </a>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/terms">
                <Translate>Policy/Terms page</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/contact">
                <Translate>Contacts</Translate>
              </NavLink>
            </NavItem>
          </Nav>
          <div className="navbar--copyright">
            <span className="navbar-text copyright-text">
              &copy; {year} <Translate>Ministry of Nature Protection</Translate>
            </span>
          </div>
          <Sponsors mode="in-header" className="navbar--sponsors justify-content-between" />
        </Collapse>
      </Navbar>
    );

    const mobileTrailMenu = (
      <Navbar fixed="top" className="navbar-mobile">
        <div className="w-100 d-flex p-0 navbar-mobile--top">
          <Card className="card-component w-100 d-flex p-0 h-100 background-green-light">
            <CardBody className="">
              <div className="media h-100 align-items-center clearfix">
                <Link
                  className="nav-link background-yellow align-self-stretch d-flex align-items-center mr-2"
                  to={`/${trail?.area?.url}/${trail?.area?.default_point?.url}`}>
                  <Arrow className="mr-1" />
                </Link>
                <div className="media-body py-3 family-cuprum font-weight-bold font-size-18 line-height-1 color-zanah">
                  {trail?.title || <Translate>Loading</Translate>}
                </div>
                <div className="align-self-stretch d-flex align-items-center" onClick={this.toggleNavbar}>
                  {/* <ArrowDown className="mr-3 ml-1" /> */}
                  <ArrowDown className={`${!collapsed ? 'rotate-180' : ''} mr-3 ml-1`} />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <TrailDetails
          buttonClass="btn d-block p-3 w-100 btn-secondary font-size-14"
          buttonLabel={<Translate>TRAIL DETAILS</Translate>}
        />
        <Collapse isOpen={!collapsed} navbar>
          <MobileSubMenu />
        </Collapse>
      </Navbar>
    );

    return (
      <header className={classNames('App-header', { 'App-header--mobile': isMobileDevice })}>
        {isMobileDevice ? (
          ui.page === 'trail' ? (
            mobileTrailMenu
          ) : (
            mobileMenu
          )
        ) : (
          <>
            <nav className="navbar p-0 px-4">
              <NavLink className="navbar-brand p-0 position-absolute top-0" to="/">
                <div className="logo-background align-content-center text-center">
                  <img src={logo} className="d-inline-block img-fluid align-top" alt="" />
                </div>
              </NavLink>

              <Language className="position-absolute top-3 right-4" />
              <Social className="position-absolute top-9 right-4" />
            </nav>
            <SubMenu />
          </>
        )}
      </header>
    );
  }

  toggleNavbar = p => {
    this.setState(state => ({
      collapsed: typeof p === 'boolean' ? p : !state.collapsed,
    }));
  };
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    trail: state.trail.current.data,
  };
}

export default connect(mapStateToProps)(AppHeader);
