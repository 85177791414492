import React, { Component } from 'react';

import 'aframe';

import { Entity } from 'aframe-react';

import './components/look-controls';
import './components/map-controls';

class VRCamera extends Component {
  render() {
    const { controlTo, lookAt } = this.props;

    return controlTo ? (
      <Entity id="rig" rotation="-45 0 0">
        <Entity
          primitive="a-camera"
          far="1000"
          fov="20"
          look-controls={{ enabled: false, touchEnabled: false }}
          map-controls={{ target: controlTo }}
          position="0 0 0"
        />
      </Entity>
    ) : (
      <Entity id="rig" position="0 2 0">
        <Entity
          primitive="a-camera"
          look-controls={{
            enabled: true,
            touchEnabled: true,
            reverseMouseDrag: true,
            reverseTouchDrag: false,
            lookAt,
          }}
          position="0 0 0"
        />
      </Entity>
    );
  }
}

export default VRCamera;
