import axios from 'axios';
import storage from '../lib/storage';
import { wordwrap } from '../utils';
const { REACT_APP_API_URL: API_URL } = process.env;

const axiosInstant = axios.create({
  baseURL: API_URL,
});

// axiosInstant.interceptors.request.use(config => {
//   config.headers["X-API-KEY"] = API_KEY;
//   config.headers["SITE-ID"] = PARTNER_ID;
//   return config;
// });

axiosInstant.interceptors.response.use(
  response => {
    // Do something with response data
    const data = response.data || {};

    if (!data.success) {
      return Promise.reject({
        statusCode: response.status,
        statusText: response.statusText,
        data,
      });
    }

    return {
      statusCode: response.status,
      statusText: response.statusText,
      ...data,
    };
  },
  error => {
    // Do something with response error
    // console.log(error, error.response);

    return Promise.reject({
      statusCode: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
      // ...(error.response.data || {}),
    });
  }
);

class ApiService {
  constructor(_axios = axios.create()) {
    this._axios = _axios;
  }

  _request = (path, { method = 'GET', data, query = {}, headers, cancelToken } = {}) => {
    if (method.toLowerCase() === 'get' && !query) {
      query = data;
    }

    query._locale = storage.get('_locale');

    return this._axios.request({
      url: path,
      method,
      data,
      params: query,
      headers,
      cancelToken,
    });
  };

  getAreas = () => {
    return this._request(`/areas`);
  };

  getArea = (areaId = '') => {
    return this._request(`/areas/${areaId}`);
  };

  getTrails = (areaId = '') => {
    return this._request(`/trails`, {
      query: {
        area: areaId,
      },
    });
  };

  getTrail = (trailId = '') => {
    return this._request(`/trails/${trailId}`);
  };

  getPoints = (areaId = '') => {
    return this._request(`/points`, {
      query: {
        area: areaId,
      },
    });
  };

  getPoint = (pointId = '') => {
    return this._request(`/points/${pointId}`);
  };

  getStories = (pointId = '') => {
    return this._request(`/stories`, {
      query: {
        point: pointId,
      },
    });
  };

  getStory = (storyId = '') => {
    return this._request(`/stories/${storyId}`);
  };

  getSubtitles = async (storyId = '') => {
    const story = await this.getStory(storyId);

    let result;
    if (story.data.subtitles_url) {
      result = await axios.get(story.data.subtitles_url);
    } else {
      return new Promise((resolve, reject) => {
        const audio = new Audio();

        audio.oncanplay = () => {
          const duration = audio.duration;
          let description = (story.data.description || '').replace(/\n+/g, ' ');
          description = wordwrap(description, 35);

          const interval = duration / description.length || 0;

          const data = {
            fragments: [],
          };

          description.map((row, i) => {
            data.fragments.push({
              begin: (interval * i).toFixed(2),
              end: (interval * (i + 1)).toFixed(2),
              children: [],
              id: 'f00000' + i,
              language: storage.get('_locale'),
              lines: [row],
            });
          });

          return resolve(data);
        };

        audio.onerror = () => reject();
        audio.src = story.data.audio_url;
      });
    }

    return result.data;
  };

  getTranslations = (lang = 'en') => {
    return this._request(`/translations`, { method: 'GET', data: { _locale: lang } });
  };

  sendMessage = data => {
    return this._request(`/contact`, {
      data,
      method: 'POST',
    });
  };

  bookTour = data => {
    return this._request(`/book`, {
      data,
      method: 'POST',
    });
  };
}

const apiService = new ApiService(axiosInstant);

export { ApiService };

export default apiService;
