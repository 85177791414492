import './index.scss';
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ReactComponent as Close } from '../../assets/close.svg';
import Translate from '../Translate';

class VideoModal extends Component {
  toggle = () => {
    this.props.close();
  };

  render() {
    const { className, show, url } = this.props;

    const closeBtn = (
      <button type="button" onClick={this.toggle} className="close" aria-label="Close">
        <Close />
      </button>
    );
    return (
      <Modal
        isOpen={show}
        toggle={this.toggle}
        size="lg"
        scrollable
        centered
        wrapClassName="App-modal App-modal--video"
        className={className}>
        <ModalHeader close={closeBtn}>
          <Translate>Watch video</Translate>
        </ModalHeader>
        <ModalBody>
          <div className="modal-body-content">
            <div className="row mx-0">
              <div className="col">
                <iframe
                  title="story video"
                  frameBorder="0"
                  vspace="0"
                  hspace="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  scrolling="auto"
                  style={{
                    width: '100%',
                    minHeight: '300px',
                    maxWidth: '700px',
                    margin: '0 auto',
                    padding: '0 20px',
                    display: 'block',
                  }}
                  src={url}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default VideoModal;
