import map from 'lodash/map';

import React, { Component } from 'react';

import 'aframe';
import { Entity } from 'aframe-react';

import VRSelect from '../vrSelect';
import isFunction from 'lodash/isFunction';

// import "./components/interactive";

class VRQuality extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuality: '2048p',
    };
  }

  handleChange = item => {
    this.setState(prevState => ({
      ...prevState,
      activeQuality: item.key,
    }));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { player, options } = this.props;
    if (player && options) {
      player.setSrc(options[this.state.activeQuality]);
    }
  }

  render() {
    const { activeQuality } = this.state;

    const { disable = false, position = { x: 0, y: 0, z: 0.001 }, options = {} } = this.props;

    const selectOpts = map(options, (value, key) => {
      if (key !== 'hls' && key !== 'auto' && key !== '8k') {
        return {
          key,
          value: key,
        };
      }
    }).filter(o => !!o);

    return (
      <VRSelect
        handleChange={this.handleChange}
        position={position}
        disable={disable}
        options={selectOpts}
        selected={activeQuality}
        style={{ img: '#vr-quality' }}
      />
    );
  }
}

export default VRQuality;
