import "./index.scss";
import React, { Component } from "react";
import { connect } from "react-redux";

import DefaultMenu from "./DefaultMenu";
import AreaMenu from "./AreaMenu";
import PointMenu from "./PointMenu";
import TrailMenu from "./TrailMenu";

class SubMenu extends Component {
  getSubMenuComponent() {
    const { ui } = this.props;
    let component = null;

    if (!ui.page) {
      return component;
    }

    switch (ui.page) {
      case "home":
        component = null;
        break;
      case "area":
        component = <AreaMenu />;
        break;
      case "point":
        component = <PointMenu />;
        break;
      case "trail":
        component = <TrailMenu />;
        break;
      default:
        component = <DefaultMenu />;
        break;
    }

    return component;
  }

  render() {
    return this.getSubMenuComponent();
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui
  };
}

export default connect(mapStateToProps)(SubMenu);
