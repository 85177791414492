import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import 'aframe';
import { connect } from 'react-redux';
import { getStory, resetStory } from '../../store/actions/story';

import ThirdPartyVideoService from '../../services/third-party-video-service';

import { getTextFragments, getUrlParamsFromString } from '../../utils';
import { Entity } from 'aframe-react';
import VRButton from './vrButton';
import VRGallery from './vrGallery';

import VideoModal from '../Modals/Video';

class VRStoryDetail extends Component {
  state = {
    activeImage: 0,
    activePage: 1,
    videoThumbnail: null,
    videoModalShow: false,
  };
  galleryList = [];

  close = () => {
    this.props.closeStory();
  };

  componentDidMount() {
    const { storyId } = this.props;
    this.props.getStory(storyId);
  }

  componentWillUnmount() {
    this.props.resetStory();
  }

  componentDidUpdate(prevProps) {
    const { story } = this.props;

    if (story?.video_url !== prevProps.story?.video_url || (prevProps.story?.video_url && !this.state.videoThumbnail)) {
      if (story?.video_url) {
        ThirdPartyVideoService.getInfo(story.video_url)
          .then(video => {
            let imgSrc = video.thumbnail + '?videoEmbedSrc=' + encodeURIComponent(video.embedUrl);
            this.setState({ videoThumbnail: imgSrc });
          })
          .catch(data => {
            console.log('ThirdPartyVideo.getInfo:reject', data);
          });
      } else {
        this.setState({ videoThumbnail: null });
      }
    }
  }

  render() {
    const { story } = this.props;

    if (isEmpty(story)) {
      return null;
    }

    const { activeImage, activePage, videoThumbnail, videoModalShow } = this.state;

    const position = {
      x: 0,
      y: 0,
      z: -2,
    };
    const rotation = {
      x: 0,
      y: -story.ath,
      z: 0,
    };

    const { description, images, title } = story;

    const descriptionFragments = getTextFragments(description);

    this.galleryList = images.map(img => ({
      id: img.url,
      thumbnail: img.url,
      image: img.url,
    }));

    if (videoThumbnail) {
      this.galleryList.push({
        id: videoThumbnail,
        thumbnail: videoThumbnail,
        image: videoThumbnail,
        isVideo: true,
      });
    }

    const videoEmbedSrc = getUrlParamsFromString(this.galleryList[activeImage]?.image).get('videoEmbedSrc');

    return (
      <Entity className="story-detail">
        {videoModalShow ? (
          <VideoModal close={this.handleModalClose} url={decodeURIComponent(videoEmbedSrc)} show={videoModalShow} />
        ) : null}

        <Entity className="story-detail-pivot" rotation={rotation}>
          <Entity className="story-detail-rig" position={position} rotation={{ x: -10 }}>
            <Entity className="story-detail-container">
              <Entity
                interactive="cursor: default;"
                primitive="a-plane"
                color="#ffffff"
                height="0.8"
                width="1.5"
                position="0 0.4 0">
                <Entity height="0.8" width="1.5" primitive="a-image" src={this.galleryList[activeImage]?.image} />
              </Entity>

              <VRGallery
                width={0.3}
                height={0.2}
                displayCount={4}
                margin={0.05}
                position={{ y: 0, z: 0.001 }}
                list={this.galleryList}
                onImageClick={this.handleImageClick}
              />

              <Entity
                interactive="cursor: default;"
                primitive="a-plane"
                color="#ffffff"
                height="1"
                width="1.5"
                position="0 -0.5 0">
                <Entity
                  position="0 0.35 0.01"
                  text={{
                    width: 1.3,
                    value: title,
                    align: 'left',
                    baseline: 'top',
                    wrapCount: 45,
                    lineHeight: 35,
                    color: '#2c2b2c',
                    font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
                  }}
                />
                <Entity
                  position="0 0.25 0.01"
                  text={{
                    width: 1.3,
                    // height: 0.7,
                    value: descriptionFragments[activePage - 1],
                    align: 'left',
                    baseline: 'top',
                    wrapCount: 45,
                    lineHeight: 35,
                    color: '#2c2b2c',
                    font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
                  }}
                />
              </Entity>

              <Entity
                interactive="cursor: default;"
                primitive="a-plane"
                color="#2c2b2c"
                height="0.15"
                width="1.5"
                material="shader: flat;"
                position="0 -1.11 0">
                <Entity
                  position="0 0.015 0.01"
                  text={{
                    width: 1.3,
                    value: 'Story',
                    align: 'left',
                    wrapCount: 45,
                    color: '#ffffff',
                    font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
                  }}
                />

                <Entity
                  position="-0.2 0.015 0.01"
                  text={{
                    width: 1.3,
                    value: `Page ${activePage} of ${descriptionFragments.length}`,
                    align: 'right',
                    wrapCount: 45,
                    color: '#ffffff',
                    font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
                  }}
                />

                <Entity position="0.6 0 0.001">
                  <VRButton
                    events={{ click: () => this.handlePageChange(activePage > 1 ? activePage - 1 : activePage) }}
                    position="-0.055 0 0"
                    style={{
                      color: '#ffffff',
                    }}
                    primitive="a-circle"
                    radius="0.05">
                    <Entity
                      primitive="a-plane"
                      width="0.03"
                      height="0.03"
                      position="0 0 0.001"
                      material={{
                        shader: 'flat',
                        transparent: true,
                        src: '#vr-arrow-left',
                      }}
                    />
                  </VRButton>
                  <VRButton
                    events={{
                      click: () =>
                        this.handlePageChange(
                          activePage < descriptionFragments.length ? activePage + 1 : descriptionFragments.length
                        ),
                    }}
                    position="0.055 0 0"
                    style={{
                      color: '#ffffff',
                    }}
                    primitive="a-circle"
                    radius="0.05">
                    <Entity
                      primitive="a-plane"
                      width="0.03"
                      height="0.03"
                      position="0 0 0.001"
                      material={{
                        shader: 'flat',
                        transparent: true,
                        src: '#vr-arrow-right',
                      }}
                    />
                  </VRButton>
                </Entity>
              </Entity>

              <VRButton
                events={{ click: this.close }}
                position={{ y: -1.32 }}
                style={{
                  color: '#ffffff',
                }}
                primitive="a-circle"
                radius="0.07">
                <Entity
                  primitive="a-plane"
                  width="0.04"
                  height="0.04"
                  position="0 0 0.001"
                  material={{
                    shader: 'flat',
                    transparent: true,
                    src: '#vr-close',
                  }}
                />
              </VRButton>
            </Entity>
          </Entity>
        </Entity>
      </Entity>
    );
  }

  handlePageChange = toPage => {
    this.setState({ activePage: toPage });
  };

  handleImageClick = index => {
    if (index !== null) {
      const videoModalShow = this.galleryList[index]?.isVideo;

      this.setState({ activeImage: index, videoModalShow });
    }
  };

  handleModalClose = () => {
    this.setState({ videoModalShow: false });
  };
}

VRStoryDetail.propTypes = {
  closeStory: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    story: state.story.current.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStory(storyId) {
      dispatch(getStory(storyId));
    },
    resetStory() {
      dispatch(resetStory());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VRStoryDetail);
