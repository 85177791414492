import './index.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AreaMobileMenu from './AreaMobileMenu';
import TrailMobileMenu from './TrailMobileMenu';

class SubMenu extends Component {
  getSubMenuComponent() {
    const { ui } = this.props;
    let component = null;

    if (!ui.page) {
      return component;
    }

    switch (ui.page) {
      case 'home':
      case 'area':
      case 'point':
        component = <AreaMobileMenu />;
        break;
      case 'trail':
        component = <TrailMobileMenu />;
        break;
      default:
        component = null;
        break;
    }

    return component;
  }

  render() {
    return this.getSubMenuComponent();
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
  };
}

export default connect(mapStateToProps)(SubMenu);
