import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';

import Home from './home';
import About from './about';
import Contact from './contact';
import Terms from './terms';
import Booking from './booking';
import Trail from './trail';
import Point from './point';
import Area from './area';
import NotFound from './error';
import storage from '../lib/storage';
import Loader from '../services/Loader';

import RouteWithMainLayout from '../layouts/main/route';
import ApiService from '../services/api-service';
import { withLocalize } from 'react-localize-redux';

class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.showPageLoader();

    props.history.listen(() => {
      this.showPageLoader();
    });

    let lang = storage.get('_locale');

    if (!lang) {
      lang = 'en';
      storage.set('_locale', lang);
    }

    ApiService.getTranslations(lang).then(res => {
      this.props.addTranslationForLanguage(res.data, lang);
      this.props.setActiveLanguage(lang);
    });
  }

  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <Switch>
          <RouteWithMainLayout path="/" component={Home} exact />

          <RouteWithMainLayout path="/about" component={About} exact />
          <RouteWithMainLayout path="/contact" component={Contact} exact />
          <RouteWithMainLayout path="/terms" component={Terms} exact />

          <RouteWithMainLayout path="/booking/:areaId" component={Booking} exact />

          <RouteWithMainLayout path="/:areaId" component={Area} exact />

          <RouteWithMainLayout path="/:areaId/trail-:trailId" component={Trail} exact />

          <RouteWithMainLayout path="/:areaId/:pointId" component={Point} exact />

          <RouteWithMainLayout component={NotFound} />
        </Switch>
      </ConnectedRouter>
    );
  }

  showPageLoader = () => {
    Loader.hideGroup('pageLoader');
    Loader.addToGroup(Loader.show('routeChange'), 'pageLoader');
  };
}

AppRoutes.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default withLocalize(AppRoutes);
