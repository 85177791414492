import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withLocalize } from 'react-localize-redux';

import { ReactComponent as Close } from '../assets/close.svg';

// import ApiService from '../services/api-service';
import storage from '../lib/storage';
import { isMobile } from '../utils';

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      languageList: [
        { key: 'en', value: 'en' },
        { key: 'hy', value: 'arm' },
        { key: 'ru', value: 'ru' },
      ],
    };
  }

  setActiveLanguage = (lang = 'en') => {
    if (lang !== this.props.activeLanguage.code) {
      storage.set('_locale', lang);
      window.location.reload();

      // ApiService.getTranslations(lang).then(res => {
      //   this.props.addTranslationForLanguage(res.data, lang);
      //   this.props.setActiveLanguage(lang);
      // });
    }
  };

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  render() {
    const { className, activeLanguage } = this.props;
    const { collapse, languageList } = this.state;

    const content = isMobile() ? (
      <div className={`header-dropdown language-dropdown ${className}`}>
        <div className="btn-group" role="group">
          {languageList.map(lang => (
            <button
              key={lang.key}
              type="button"
              className={`btn btn-light p-1 rounded-circle font-size-14 mr-1 text-uppercase ${
                lang.key === activeLanguage.code ? 'active' : ''
              }`}
              onClick={() => {
                this.setActiveLanguage(lang.key);
              }}>
              {lang.value}
            </button>
          ))}
        </div>
      </div>
    ) : (
      <div className={`header-dropdown language-dropdown ${className}`}>
        <Dropdown direction="left" isOpen={collapse} toggle={this.toggle}>
          <DropdownToggle
            // onClick={this.toggle}
            tag="div"
            className="d-block btn-toggle">
            <button type="button" className="btn btn-light p-2 rounded-circle text-uppercase">
              {collapse ? <Close className="normalize-svg hoverable" /> : activeLanguage.code}
            </button>
          </DropdownToggle>
          <DropdownMenu>
            <div className="btn-group" role="group">
              {languageList.map(lang => (
                <button
                  key={lang.key}
                  type="button"
                  className={`btn btn-light p-1 rounded-circle font-size-14 mr-1 text-uppercase ${
                    lang.key === activeLanguage.code ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.setActiveLanguage(lang.key);
                  }}>
                  {lang.value}
                </button>
              ))}
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );

    return content;
  }
}

export default withLocalize(Language);
