import React, { Component } from 'react';
import 'aframe';
import { Entity } from 'aframe-react';

import VRImage from '../vrImage';

import isEqual from 'lodash/isEqual';

import { facesInfo } from '../../../constants/vr';
import Loader from '../../../services/Loader';

class PhotoSpace extends Component {
  constructor(props) {
    super(props);
    this.showLoader();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!isEqual(nextProps.data, this.props.data)) {
      this.showLoader();
    }
  }

  render() {
    const { data = {} } = this.props;

    return (
      <Entity>
        {facesInfo.map(face => (
          <VRImage
            key={face.pos}
            onLoad={this.handleLoad}
            width="200"
            height="200"
            src={data[face.pos]}
            material={{ transparent: false }}
            position={face.position}
            rotation={face.rotation}
            id={`vr-app-space-photo-face-main-${face.pos}`}
          />
        ))}
      </Entity>
    );
  }

  showLoader = () => {
    // console.log("showLoader =>>>>>>>>>>")
    this.loadedCount = 0;
    this.loader = Loader.show('photoSpaceLoader');
  };

  handleLoad = evt => {
    // console.log("handleLoad =<<<<<<<<<<<<<<<<<");
    if (++this.loadedCount === facesInfo.length) {
      // console.log("loaded =<<<<<<<<<<<<<<<<<");
      Loader.hide(this.loader);
    }
  };
}

export default PhotoSpace;
