import * as errorCodes from '../constants/error-codes';

class ErrorService {
  parse = data => {
    // const fields = ErrorService.parseFields(data);
    // const message = ErrorService.parseMessage(data);

    return {
      // fields,
      message: data.data,
    };
  };

  parseFields = data => {
    return data.fields;
  };

  parseMessage = data => {
    switch (data.code) {
      case errorCodes.NOT_FOUND.code:
        return errorCodes.NOT_FOUND.message;

      default:
        return data.message;
    }
  };
}

const errorService = new ErrorService();

export { ErrorService };

export default errorService;
