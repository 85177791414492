import React, { Component } from 'react';

import 'aframe';
import 'aframe-gif-shader';

import { Entity } from 'aframe-react';

import isFunction from 'lodash/isFunction';

import './components/image-adaptation';

class VRImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };

    this.loaderImage = '#vr-loader';
  }

  static defaultProps = {
    width: 0.5,
    height: 0.5,
  };

  handleImageLoadStart = () => {
    this.setState(prevState => ({
      ...prevState,
      isLoaded: false,
    }));
  };

  handleImageLoadEnd = () => {
    this.setState(prevState => ({
      ...prevState,
      isLoaded: true,
    }));

    if (isFunction(this.props.onLoad)) {
      this.props.onLoad(this.image);
    }
  };

  loadImage(src = '') {
    // console.log("Loadimage => :", src, this.image?.src)
    switch (true) {
      case src instanceof Image && this.image !== src: {
        delete this.image;
        this.image = src;
        return this.handleImageLoadEnd(this.image);
      }

      case !this.image || this.image.src !== src: {
        delete this.image;
        this.image = new Image();
        this.image.crossOrigin = 'anonymous';
        this.image.onloadstart = this.handleImageLoadStart;
        this.image.onload = this.handleImageLoadEnd;
        this.image.src = src;

        break;
      }

      default:
        break;
    }
  }

  componentDidMount() {
    this.loadImage(this.props.src);
  }

  componentDidUpdate() {
    this.loadImage(this.props.src);
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.onloadstart = undefined;
      this.image.onload = undefined;
      delete this.image;
    }
  }

  render() {
    const { isLoaded } = this.state;
    const { loader = this.loaderImage, width, height, src, material = {}, saveRatio, ...props } = this.props;

    const mt = {
      transparent: true,
      shader: 'flat',
      ...material,
    };

    if (this.image && isLoaded) {
      mt.src = this.image;
    } else {
      mt.src = loader;
      mt.shader = 'gif';
    }

    return (
      <Entity
        primitive="a-plane"
        material={mt}
        width={width}
        height={height}
        {...(saveRatio ? { 'image-adaptation': { width, height } } : {})}
        {...props}
      />
    );
  }
}

export default VRImage;
