import { isVisible } from '../utils';

class Interactive {
  constructor() {
    throw new Error('Class does not support object creation!');
  }

  static className = 'raycastable-object';

  static objects = [];

  static raycaster = null;

  static addObject(elm) {
    if (this.objects.indexOf(elm) === -1) {
      this.objects = [...this.objects, elm];
    }
    this.update();
  }

  static removeObject(elm) {
    this.objects = this.objects.filter(item => item !== elm);
    this.update();
  }

  static update() {
    if (this.raycaster) {
      this.raycaster.refreshObjects();
    }
  }

  static setRaycaster(raycaster) {
    this.raycaster = raycaster;

    this.raycaster.refreshObjects = this.__refreshObjects.bind(this);

    this.update();
  }

  static __refreshObjects() {
    (function refreshObjects(objects) {
      let data = this.data;
      let els; // If objects not defined, intersect with everything.

      if (objects) {
        els = objects;
      } else {
        els = data.objects ? this.el.sceneEl.querySelectorAll(data.objects) : this.el.sceneEl.querySelectorAll('*');
      }

      this.objects = this.flattenObject3DMaps(els);
      this.dirty = false;
    }.call(this.raycaster, this.objects.filter(obj => isVisible(obj.object3D))));
  }
}

export default Interactive;
