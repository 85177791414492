import './index.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import Trails from '../Trails';
import Map from '../Map';
import Marker from '../Map/Marker';
import { convertDMSToDD } from '../../utils';

import Translate from '../Translate';
import { isMobile } from '../../utils';

import { ReactComponent as Arrow } from '../../assets/arrow-left.svg';

class RightBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: true,
    };
  }

  handleToggle = () => {
    this.setState(prevState => ({
      ...prevState,
      isOpened: !prevState.isOpened,
    }));
  };

  handleMarkerClick = point => {
    this.props.history.push(`/${point.area.url}/${point.url}`);
  };

  render() {
    const { area, points, point, className } = this.props;
    const { isOpened } = this.state;

    const isMobileDevice = isMobile();

    let center;

    if (area.latitude || area.longitude) {
      center = {
        lat: convertDMSToDD(area.latitude),
        lng: convertDMSToDD(area.longitude),
      };
    }

    return (
      <Card
        className={classNames(
          'right-bar card-component clearfix position-absolute right-0 top-0',
          {
            'right-bar-mobile': isMobileDevice,
          },
          className
        )}>
        {isMobileDevice ? (
          <Card className="card-component background-green-light">
            <CardBody className="">
              <div className="media align-items-center clearfix">
                <Link className="nav-link background-yellow align-self-stretch d-flex align-items-center mr-2" to="/">
                  <Arrow className="mr-1" />
                </Link>
                <div className="media-body py-3 family-cuprum font-weight-bold font-size-18 line-height-1 color-zanah">
                  {area.name || <Translate>Loading</Translate>}
                </div>
                <img width="40" src={area.image_url} alt={area.name} className="img float-right my-2 ml-1 mr-2" />
              </div>
            </CardBody>
          </Card>
        ) : null}
        <CardHeader className="cursor-pointer" onClick={e => (!isMobileDevice ? this.handleToggle(e) : undefined)}>
          <Translate>{isOpened ? 'AREA MAP' : 'AREA MAP, TRAILS and DETAILS'}</Translate>
          {!isMobileDevice ? (
            <span className="float-right font-size-14">
              <Translate>{isOpened ? 'HIDE' : 'SHOW'}</Translate>
            </span>
          ) : null}
        </CardHeader>
        <Collapse isOpen={isOpened}>
          <CardBody className="p-0 position-relative" style={{ zIndex: 1 }}>
            <Map center={center} kmlUrl={area.kml_url} zoom={area.map_zoom_level}>
              {points.map(pt => (
                <Marker
                  key={pt.id}
                  onClick={() => {
                    this.handleMarkerClick(pt);
                  }}
                  active={pt.id === point.id}
                  lat={pt.data.latitude}
                  lng={pt.data.longitude}
                  label={pt.name}
                />
              ))}
            </Map>

            {isMobileDevice ? (
              <Card className="card-component background-green">
                <CardBody className="px-4 py-3">
                  <button
                    type="button"
                    className="btn btn-secondary text-uppercase mx-auto p-2 font-size-19 w-100 m-0"
                    onClick={() => this.props.onEnable360()}>
                    <Translate>Watch in 360</Translate>
                  </button>
                </CardBody>
              </Card>
            ) : null}

            <Trails />

            <Card className="card-component background-green">
              <CardBody className="p-0">
                <Link
                  disabled={!area.id}
                  to={`/${area.url}`}
                  className="btn btn-secondary text-uppercase mx-auto p-2 font-size-19 w-100 btn--more">
                  <Translate>LEARN MORE</Translate>
                </Link>
              </CardBody>
            </Card>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    area: state.area.current.data,
    point: state.point.current.data,
    points: state.point.list.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RightBar));
