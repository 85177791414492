import React, { Component } from 'react';

import 'aframe';

import { Entity } from 'aframe-react';

import './components/map-layout-controller';

class VRMap extends Component {
  constructor(props) {
    super(props);

    this.map = React.createRef();
  }

  componentDidMount() {
    this.props.setMapElement(this.map.current.el);
  }

  render() {
    const { mapActiveArea } = this.props;
    const activeAreaName = mapActiveArea ? mapActiveArea.name : '';

    return (
      <Entity id="vr-map-wrapper" position="0 0 0" scale="3 3 3">
        <Entity
          id="map-object"
          model-loader="src: #vr-map"
          model-material="textures: map_0, #vr-map-material, *, #vr-map-tree-material"
          ref={this.map}
        />

        <Entity
          id="map-layer-object"
          model-loader="src: #vr-map-layer"
          position="0 -0.05 0"
          animation__move_up={{
            property: 'position',
            from: '0 -0.05 0',
            to: `0 0 0`,
            dur: 300,
            easing: 'linear',
            startEvents: 'highlight-area:show',
          }}
          animation__move_down={{
            property: 'position',
            from: `0 0 0`,
            to: `0 -0.05 0`,
            dur: 200,
            easing: 'linear',
            startEvents: 'highlight-area:hide',
          }}
          model-material="color: #cccccc"
          map-layout-controller={{ highlightedArea: activeAreaName }}
        />
      </Entity>
    );
  }
}

export default VRMap;
