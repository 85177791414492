import * as actionTypes from '../actions/story';

const initialState = {
  current: {
    loaded: false,
    error: null,
    data: {},
    subtitles: [],
  },
  list: {
    loaded: false,
    error: null,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    /* RESET STORY PART */
    case `${actionTypes.RESET_STORY}`: {
      return {
        ...state,
        current: {
          ...initialState.current,
        },
      };
    }

    /*  GET STORY PART  */
    case `${actionTypes.GET_STORY}_LOADING`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_STORY}_ERROR`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: action.payload,
        },
      };
    }
    case `${actionTypes.GET_STORY}_SUCCESS`: {
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload.data,
          loaded: true,
          error: null,
        },
      };
    }

    /*  GET STORIES PART  */
    case `${actionTypes.GET_STORIES}_LOADING`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_STORIES}_ERROR`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: true,
          error: action.payload,
        },
      };
    }
    case `${actionTypes.GET_STORIES}_SUCCESS`: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          loaded: true,
          error: null,
        },
      };
    }

    /*  GET SUBTITLES PART  */
    // case `${actionTypes.GET_SUBTITLES}_LOADING`: {
    //   return {
    //     ...state,
    //     current: {
    //       ...state.current,
    //       loaded: false,
    //       error: null,
    //     },
    //   };
    // }
    case `${actionTypes.GET_SUBTITLES}_ERROR`: {
      return {
        ...state,
        current: {
          ...state.current,
          subtitles: [],
          // loaded: true,
          // error: action.payload,
        },
      };
    }
    case `${actionTypes.GET_SUBTITLES}_SUCCESS`: {
      return {
        ...state,
        current: {
          ...state.current,
          subtitles: action.payload,
          // loaded: true,
          // error: null,
        },
      };
    }
    default:
      return state;
  }
};
