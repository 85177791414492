import { getUrlParamsFromString } from '../utils';
import axios from 'axios';

class ThirdPartyVideoService {
  decodeQueryString(queryString) {
    let key, keyValPair, keyValPairs, r, val, _i, _len;
    r = {};
    keyValPairs = queryString.split('&');
    for (_i = 0, _len = keyValPairs.length; _i < _len; _i++) {
      keyValPair = keyValPairs[_i];
      key = decodeURIComponent(keyValPair.split('=')[0]);
      val = decodeURIComponent(keyValPair.split('=')[1] || '');
      r[key] = val;
    }
    return r;
  }

  decodeStreamMap(url_encoded_fmt_stream_map) {
    let quality, sources, stream, type, urlEncodedStream, _i, _len, _ref;
    sources = {};
    _ref = url_encoded_fmt_stream_map.split(',');
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      urlEncodedStream = _ref[_i];
      stream = this.decodeQueryString(urlEncodedStream);
      type = stream.type.split(';')[0];
      quality = stream.quality.split(',')[0];
      stream.original_url = stream.url;
      stream.url = '' + stream.url + '&signature=' + stream.sig;
      sources['' + type + ' ' + quality] = stream;
    }
    return sources;
  }

  getInfo(url) {
    let providerInfo = this.getProviderInfo(url);
    let id = this.getIdFromUrl(url);

    return new Promise((resolve, reject) => {
      if (providerInfo.name === 'vimeo' || providerInfo.name === 'youtube') {
        axios
          .get('https://noembed.com/embed', { params: { format: 'json', url: url } })
          .then(function({ data }) {
            console.log('request result : ', data);
            let providerName = data.provider_name.toLowerCase();
            let thumbnail, embedUrl;

            embedUrl = providerInfo.embedUrl.replace(/\${{videoId}}/g, id);

            thumbnail = data.thumbnail_url || '';

            if (providerName === 'vimeo') {
              /// 539380594_640.webp
              thumbnail = thumbnail.split('/');

              let imgName = thumbnail[thumbnail.length - 1];

              thumbnail[thumbnail.length - 1] = imgName.split('_')[0] + '_640.jpg';

              thumbnail = thumbnail.join('/');
            } else if (providerName === 'youtube') {
              // thumbnail = thumbnail.split('/');

              // thumbnail[thumbnail.length - 1] = 'sddefault.jpg';

              // thumbnail = thumbnail.join('/');

              thumbnail = 'https://cors-anywhere.herokuapp.com/' + thumbnail;
            }

            let resData = {
              embedUrl: embedUrl,
              thumbnail: thumbnail,
            };

            return resolve(resData);
          })
          .catch(() => {
            return reject('video not found');
          });
      } else {
        return reject('video provider not supported');
      }
    });
  }

  getProviderInfo(url) {
    let info = {
      name: 'unknown',
      url: 'unknown',
      embedUrl: 'unknown',
    };

    if (url.includes('vimeo.com')) {
      info.name = 'vimeo';
      info.url = 'https://www.vimeo.com';
      info.embedUrl = 'https://player.vimeo.com/video/${{videoId}}?title=0&byline=0&portrait=0';
    } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
      info.name = 'youtube';
      info.url = 'https://www.youtube.com';
      info.embedUrl = 'https://www.youtube.com/embed/${{videoId}}?rel=0&showinfo=0&color=white&iv_load_policy=3';
    }

    return info;
  }

  getIdFromUrl(url) {
    let id = -1;

    let providerInfo = this.getProviderInfo(url);

    if (providerInfo.name === 'vimeo') {
      id = url.split('/');
      id = id[id.length - 1];
    } else if (providerInfo.name === 'youtube') {
      let params = getUrlParamsFromString(url);

      if (params.has('v')) {
        id = params.get('v');
      } else {
        id = url.split('/').slice(-1);
      }
    }

    return id;
  }
}

export default new ThirdPartyVideoService();
