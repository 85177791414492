/*
const PREFIX = "WWF-";
export const VR_MAP = `${PREFIX}VR-MAP`;
export const VR_MAP_LAYER = `${PREFIX}VR-MAP-LAYER`;
export const VR_MAP_MATERIAL = `${PREFIX}VR-MAP-MATERIAL`;
export const VR_MAP_TREE_MATERIAL = `${PREFIX}VR-MAP-TREE-MATERIAL`;
export const VR_CLOUD = `${PREFIX}VR-CLOUD`;
export const VR_CLOUD_TRANSPARENT = `${PREFIX}VR-CLOUD-TRANSPARENT`;
export const VR_STORK = `${PREFIX}VR-STORK`;
export const VR_AIR_BALLOON = `${PREFIX}VR-AIR-BALLOON`;
export const VR_ITEM_IMG = `${PREFIX}VR-ITEM-IMG`;
export const VR_LOADER = `${PREFIX}VR-LOADER`;
export const VR_POINTER_TRIANGLE = `${PREFIX}VR-POINTER-TRIANGLE`;
export const VR_POINTER_TRIANGLE_ACTIVE = `${PREFIX}VR-POINTER-TRIANGLE-ACTIVE`;
export const VR_PLAY = `${PREFIX}VR-PLAY`;
export const VR_QUALITY = `${PREFIX}VR-QUALITY`;
export const VR_CLOSE = `${PREFIX}VR-CLOSE`;
export const VR_VOLUME_ON = `${PREFIX}VR-VOLUME-ON`;
export const VR_VOLUME_OFF = `${PREFIX}VR-VOLUME-OFF`;
export const VR_ARROW_LEFT = `${PREFIX}VR-ARROW-LEFT`;
export const VR_ARROW_RIGHT = `${PREFIX}VR-ARROW-RIGHT`;
export const VR_ICON_GULL = `${PREFIX}VR-ICON-GULL`;
export const VR_ICON_BEAR = `${PREFIX}VR-ICON-BEAR`;
export const VR_ICON_TIGER = `${PREFIX}VR-ICON-TIGER`;
export const VR_ICON_CHAMOIS = `${PREFIX}VR-ICON-CHAMOIS`;
*/

const PREFIX = '';
export const VR_MAP = `${PREFIX}vr-map`;
export const VR_MAP_LAYER = `${PREFIX}vr-map-layer`;
export const VR_MAP_MATERIAL = `${PREFIX}vr-map-material`;
export const VR_MAP_TREE_MATERIAL = `${PREFIX}vr-map-tree-material`;
export const VR_CLOUD = `${PREFIX}vr-cloud`;
export const VR_CLOUD_TRANSPARENT = `${PREFIX}vr-cloud-transparent`;
export const VR_STORK = `${PREFIX}vr-stork`;
export const VR_AIR_BALLOON = `${PREFIX}vr-air-balloon`;
export const VR_ITEM_IMG = `${PREFIX}vr-item-img`;
export const VR_LOADER = `${PREFIX}vr-loader`;
export const VR_POINTER_TRIANGLE = `${PREFIX}vr-pointer-triangle`;
export const VR_POINTER_TRIANGLE_ACTIVE = `${PREFIX}vr-pointer-triangle-active`;
export const VR_PLAY = `${PREFIX}vr-play`;
export const VR_QUALITY = `${PREFIX}vr-quality`;
export const VR_CLOSE = `${PREFIX}vr-close`;
export const VR_VOLUME_ON = `${PREFIX}vr-volume-on`;
export const VR_VOLUME_OFF = `${PREFIX}vr-volume-off`;
export const VR_ARROW_LEFT = `${PREFIX}vr-arrow-left`;
export const VR_ARROW_RIGHT = `${PREFIX}vr-arrow-right`;
export const VR_ICON_GULL = `${PREFIX}vr-icon-gull`;
export const VR_ICON_BEAR = `${PREFIX}vr-icon-bear`;
export const VR_ICON_TIGER = `${PREFIX}vr-icon-tiger`;
export const VR_ICON_CHAMOIS = `${PREFIX}vr-icon-chamois`;

export const VR_HOTSPOT_DEFAULT = `${PREFIX}vr-hotspot-default`;
export const VR_HOTSPOT_GROUND = `${PREFIX}vr-hotspot-ground`;
export const VR_HOTSPOT_AIR = `${PREFIX}vr-hotspot-air`;
