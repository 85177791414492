import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTrail } from '../../store/actions/trail';
import { getArea } from '../../store/actions/area';
import VRTrail from './vr';
import { setParam } from '../../store/actions/ui';
import Loader from '../../services/Loader';
import NotFound from '../error';

class Trail extends Component {
  state = {
    showErrorPage: false,
  };

  componentDidMount() {
    this.props.setUIParam('page', 'trail');
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isDataLoaded, match } = this.props;

    if (match.params.trailId !== prevProps.match.params.trailId) {
      this.loadData();
    } else if (isDataLoaded) {
      Loader.hideGroup('pageLoader');
    }
  }

  render() {
    const { showErrorPage } = this.state;

    if (showErrorPage) {
      return <NotFound />;
    }
    const { trail } = this.props;

    return (
      <>
        <VRTrail data={trail} />
      </>
    );
  }

  loadData = () => {
    Loader.addToGroup(Loader.show(), 'trailPage');

    const { trailId, areaId } = this.props.match.params;

    return Promise.all([this.props.getArea(areaId), this.props.getTrail(trailId)])
      .finally(() => Loader.hideGroup('trailPage'))
      .then(d => {
        for (let i = 0; i < d.length; i++) {
          if (d[i].statusCode === 404) {
            this.setState({
              showErrorPage: true,
            });
            break;
          }
        }
      });
  };
}

function mapStateToProps(state) {
  return {
    isDataLoaded: state.trail.current.loaded && state.area.current.loaded,
    trail: state.trail.current.data,
    area: state.area.current.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTrail(trailId) {
      return dispatch(getTrail(trailId));
    },
    getArea(areaId) {
      return dispatch(getArea(areaId));
    },
    setUIParam(key, val) {
      return dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Trail);
