import React from 'react';
import { connect } from 'react-redux';

import { getAreas } from '../../store/actions/area';

import { setParam } from '../../store/actions/ui';

import VRHome from './vr';
import Loader from '../../services/Loader';

class Home extends React.Component {
  componentDidMount() {
    this.props.setUIParam('page', 'home');
    this.props.getAreas();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isDataLoaded) {
      Loader.hideGroup('pageLoader');
    }
  }

  render() {
    return (
      <>
        <VRHome areas={this.props.areas} />

        <div
          className="position-absolute"
          style={{
            zIndex: -9999,
            left: -9999,
            top: -9999,
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  // console.log(state);
  return {
    isDataLoaded: state.area.list.loaded,
    areas: state.area.list.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAreas() {
      dispatch(getAreas());
    },
    setUIParam(key, val) {
      dispatch(setParam(key, val));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
