import React, { Component } from 'react';

import 'aframe';
import { Entity } from 'aframe-react';

import { mergeEventObjects } from '../../utils';

import './components/interactive';
// import "./components/renderOrder";

class VRPointer extends Component {
  constructor(props) {
    super(props);

    // create a ref to store the <a-entity> DOM element
    this.itemEntity = React.createRef();

    this.state = {
      isActive: false,
    };

    this.defaultData = {
      mainColor: '#DFC78B',
      borderColor: '#C86754',
      arrowSrc: '#vr-pointer-triangle',
    };
    this.activeData = {
      mainColor: '#C86754',
      borderColor: '#DFC78B',
      arrowSrc: '#vr-pointer-triangle-active',
    };
  }

  handleMouseEnter = () => {
    this.itemEntity.current.el.emit('pointer_pause');
    const { title, image, area } = this.props;

    this.props.setMapActiveArea({
      title,
      image,
      name: area,
    });

    this.setState(prevState => ({
      ...prevState,
      isActive: true,
    }));
  };

  handleMouseLeave = () => {
    this.itemEntity.current.el.emit('pointer_resume');

    this.props.setMapActiveArea(null);

    this.setState(prevState => ({
      ...prevState,
      isActive: false,
    }));
  };

  render() {
    const { title, image, position, events } = this.props;

    const { isActive } = this.state;
    const { defaultData, activeData } = this;

    const mainColor = isActive ? activeData.mainColor : defaultData.mainColor;
    const borderColor = isActive ? activeData.borderColor : defaultData.borderColor;
    const arrowSrc = isActive ? activeData.arrowSrc : defaultData.arrowSrc;

    const eventsList = mergeEventObjects(
      {
        mouseenter: this.handleMouseEnter,
        mouseleave: this.handleMouseLeave,
      },
      events
    );

    return (
      <Entity
        scale="0.8 0.8 0.8"
        ref={this.itemEntity}
        className="item"
        animation__move_up={{
          property: 'position',
          from: `${position.x} ${position.y} ${position.z}`,
          to: `${position.x} ${position.y + 0.1} ${position.z}`,
          dur: 1000,
          easing: 'linear',
          dir: 'alternate',
          loop: true,
          pauseEvents: 'pointer_pause',
          resumeEvents: 'pointer_resume',
        }}>
        <Entity className="item-pivot" rotation="-45 0 0">
          <Entity
            text={{
              width: 1.7,
              value: title,
              align: 'right',
              wrapCount: 12,
              lineHeight: 30,
              font: '/assets/fonts/WeblySleek_UI_SemiBold_Regular.fnt',
            }}
            position="-1.35 0 0"
            className="item-text"
          />
          <Entity
            className="item-circle"
            geometry={{ primitive: 'circle', radius: 0.4, segments: 32 }}
            material={{ side: 'double', shader: 'flat', color: mainColor }}
            position="0 0 0.005"
            interactive
            events={eventsList}>
            <Entity
              className="item-circle-border"
              position="0 0 0.05"
              geometry={{ primitive: 'circle', radius: 0.36, segments: 32 }}
              material={{ side: 'double', shader: 'flat', color: borderColor }}
            />
            <Entity
              className="item-image"
              position="0 0 0.1"
              geometry={{ primitive: 'circle', radius: 0.33, segments: 64 }}
              material={{ side: 'double', shader: 'flat', src: image }}
            />
            <Entity
              className="item-triangle"
              position="0 -0.5 0"
              geometry={{ primitive: 'plane', width: 0.36, height: 0.36 }}
              material={{
                side: 'double',
                shader: 'flat',
                src: arrowSrc,
                transparent: true,
              }}
            />
          </Entity>
        </Entity>
      </Entity>
    );
  }
}

export default VRPointer;
