import { registerComponent, THREE } from 'aframe';
import Interactive from '../../../services/Interactive';
import Loader from '../../../services/Loader';
import { isMobile } from '../../../utils';

const $html = document.querySelector('html');

$html.classList.remove('a-fullscreen');

export default registerComponent('scene-controller', {
  schema: {},

  init: function init() {
    const { raycaster } = this.el.components;

    Interactive.setRaycaster(raycaster);

    Loader.hideGroup('vrScene');

    setTimeout(() => {
      $html.classList.add('a-fullscreen');
    }, 500);

    // this.__handleContextLost = this.__handleContextLost.bind(this);

    // this.el.canvas.addEventListener('webglcontextlost', this.__handleContextLost);

    // this.__handleContextLost();
  },
  remove: function remove() {
    // this.el.pause();

    $html.classList.remove('a-fullscreen');

    // this.el.canvas.removeEventListener('webglcontextlost', this.__handleContextLost);

    // THREE.Cache.clear();

    if (isMobile()) {
      this.el.renderer.forceContextLoss();
    }

    // this.__handleContextLost();
  },

  __handleContextLost: function() {
    const gl = this.el.canvas.getContext('webgl');

    console.log('gl: ', gl);

    if (gl) {
      const ext = gl.getExtension('WEBGL_lose_context');

      console.log('ext: ', ext);

      if (ext) {
        ext.loseContext();
      }
    }
  },
});
