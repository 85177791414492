import AFRAME, { THREE, ANIME } from 'aframe';

// Get a reference to the component we want to extend.
const lookControls = AFRAME.components['look-controls'];
const lookControlsComponent = lookControls.Component;

// To avoid recalculation at every mouse movement tick
const PI_2 = Math.PI / 2;

/**
 * Update orientation for mobile, mouse drag, and headset.
 * Mouse-drag only enabled if HMD is not active.
 */
lookControlsComponent.prototype.updateOrientation = (function() {
  var poseMatrix = new THREE.Matrix4();

  return function updateOrientation(system = false) {
    var hmdEuler = this.magicWindowDeltaEuler;
    var object3D = this.el.object3D;
    var pitchObject = this.pitchObject;
    var yawObject = this.yawObject;
    var pose;
    var sceneEl = this.el.sceneEl;

    // In VR mode, THREE is in charge of updating the camera pose.
    if (sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected()) {
      // With WebXR THREE applies headset pose to the object3D matrixWorld internally.
      // Reflect values back on position, rotation, scale for getAttribute to return the expected values.
      if (sceneEl.hasWebXR) {
        pose = sceneEl.renderer.xr.getCameraPose();
        if (pose) {
          poseMatrix.elements = pose.transform.matrix;
          poseMatrix.decompose(object3D.position, object3D.rotation, object3D.scale);
        }
      }
      return;
    } else {
      object3D.updateMatrix();
    }

    // // WebXR API updates applies headset pose to the object3D matrixWorld internally.
    // // Reflect values back on position, rotation, scale so setAttribute returns expected values.
    // if (sceneEl.is('vr-mode') && sceneEl.hasWebXR) {
    //     pose = sceneEl.renderer.vr.getCameraPose();
    //     if (pose) {
    //         poseMatrix.elements = pose.poseModelMatrix;
    //         poseMatrix.decompose(object3D.position, object3D.rotation, object3D.scale);
    //     }
    // } else {
    //     object3D.updateMatrix();
    // }

    this.updateMagicWindowOrientation();

    if (system || !this.hmdO) {
      this.hmdO = { x: hmdEuler.x, y: hmdEuler.y };
    }

    // On mobile, do camera rotation with touch events and sensors.

    const newX = hmdEuler.x - this.hmdO.x + pitchObject.rotation.x;
    const newY = hmdEuler.y - this.hmdO.y + yawObject.rotation.y;

    if (object3D.rotation.x !== newX || object3D.rotation.y !== newY) {
      object3D.rotation.x = newX;
      object3D.rotation.y = newY;
      window.document.dispatchEvent(new CustomEvent('camera-rotate', { detail: { x: newX, y: newY } }));
    }
  };
})();

lookControlsComponent.prototype.update = function update(oldData) {
  const data = this.data;

  // Disable grab cursor classes if no longer enabled.
  if (data.enabled !== oldData.enabled) {
    this.updateGrabCursor(data.enabled);
  }

  // Reset pitch and yaw if disabling HMD.
  if (oldData && !data.hmdEnabled && !oldData.hmdEnabled) {
    this.pitchObject.rotation.set(0, 0, 0);
    this.yawObject.rotation.set(0, 0, 0);
  }

  if (oldData && !data.pointerLockEnabled !== oldData.pointerLockEnabled) {
    this.removeEventListeners();
    this.addEventListeners();
    if (this.pointerLocked) {
      this.exitPointerLock();
    }
  }

  if (data.lookAt !== oldData.lookAt) {
    this.lookAt(data.lookAt);
  }
};

/**
 * Register mouse down to detect mouse drag.
 */
lookControlsComponent.prototype.onMouseDown = function onMouseDown(evt) {
  if (!this.data.enabled) {
    return;
  }
  // Handle only primary button.
  if (evt.button !== 0) {
    return;
  }

  if (this.InertiaAnimation) {
    this.InertiaAnimation.pause();
    delete this.InertiaAnimation;
  }

  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  this.mouseDown = true;
  this.previousMouseEvent = evt;
  this.showGrabbingCursor();

  if (this.data.pointerLockEnabled && !this.pointerLocked) {
    if (canvasEl.requestPointerLock) {
      canvasEl.requestPointerLock();
    } else if (canvasEl.mozRequestPointerLock) {
      canvasEl.mozRequestPointerLock();
    }
  }
};

/**
 * Translate mouse drag into rotation.
 *
 * Dragging up and down rotates the camera around the X-axis (yaw).
 * Dragging left and right rotates the camera around the Y-axis (pitch).
 */
lookControlsComponent.prototype.onMouseMove = function onMouseMove(event) {
  let movementX;
  let movementY;
  const { pitchObject, previousMouseEvent, yawObject } = this;

  // Not dragging or not enabled.
  if (!this.data.enabled || (!this.mouseDown && !this.pointerLocked)) {
    return;
  }

  // Calculate delta.
  if (this.pointerLocked) {
    movementX = event.movementX || event.mozMovementX || 0;
    movementY = event.movementY || event.mozMovementY || 0;
  } else {
    movementX = event.screenX - previousMouseEvent.screenX;
    movementY = event.screenY - previousMouseEvent.screenY;
  }
  this.previousMouseEvent = event;

  this.deltaMovement = {
    movementX,
    movementY,
  };

  // Calculate rotation.
  const direction = this.data.reverseMouseDrag ? 1 : -1;
  yawObject.rotation.y += movementX * 0.002 * direction;
  pitchObject.rotation.x += movementY * 0.002 * direction;
  pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
};

/**
 * Register mouse up to detect release of mouse drag.
 */
lookControlsComponent.prototype.onMouseUp = function onMouseUp() {
  this.mouseDown = false;
  this.hideGrabbingCursor();
  this.moveWithInertia();
};

/**
 * Translate touch move to Y-axis rotation.
 */
lookControlsComponent.prototype.onTouchMove = function(evt) {
  let direction;
  let canvas = this.el.sceneEl.canvas;
  let deltaY, deltaX;
  let pitchObject = this.pitchObject;
  let yawObject = this.yawObject;

  if (!this.touchStarted || !this.data.touchEnabled) {
    return;
  }

  deltaY = (2 * Math.PI * (evt.touches[0].pageX - this.touchStart.x)) / canvas.clientWidth;
  deltaX = (2 * Math.PI * (evt.touches[0].pageY - this.touchStart.y)) / canvas.clientHeight;

  direction = this.data.reverseTouchDrag ? 1 : -1;
  // Limit touch orientaion to to yaw (y axis).
  yawObject.rotation.y -= deltaY * 0.5 * direction;

  pitchObject.rotation.x -= deltaX * 0.5 * direction;
  pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));

  this.touchStart = {
    x: evt.touches[0].pageX,
    y: evt.touches[0].pageY,
  };
};

lookControlsComponent.prototype.moveWithInertia = function moveWithInertia() {
  if (this.InertiaAnimation || !this.deltaMovement) {
    return;
  }

  const { pitchObject, yawObject } = this;
  const direction = this.data.reverseMouseDrag ? 1 : -1;

  const { movementX, movementY } = this.deltaMovement;

  if (!movementX && !movementY) {
    return;
  }

  const animProps = {
    y: yawObject.rotation.y,
    x: pitchObject.rotation.x,
  };

  this.InertiaAnimation = ANIME({
    targets: animProps,

    y: yawObject.rotation.y + movementX * 0.006 * direction,
    x: pitchObject.rotation.x + movementY * 0.006 * direction,

    duration: 500,
    autoplay: true,
    easing: 'easeOutQuart',
    update: anim => {
      yawObject.rotation.y = animProps.y;
      pitchObject.rotation.x = animProps.x;
      pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
    },
  });

  this.deltaMovement = {
    movementX: 0,
    movementY: 0,
  };
};

lookControlsComponent.prototype.lookAt = function lookAt(rotation) {
  if (!rotation) return;
  const { x, y, z } = rotation;

  this.pitchObject.rotation.x = THREE.Math.degToRad(x);

  this.yawObject.rotation.y = THREE.Math.degToRad(y);

  this.updateOrientation(true);
};

/**
 * Function to look at some global point
 * @param pointToLook global point
 * @param deltaY
 */
lookControlsComponent.prototype.lookAtPoint = function(pointToLook, deltaY) {
  if (AFRAME.utils.device.isMobile() || !deltaY) deltaY = 0;
  let direction = new THREE.Vector3()
    .subVectors(
      this.el.object3D.parent.parent.worldToLocal(pointToLook),
      this.el.object3D.parent.localToWorld(new THREE.Vector3(0, 0, 0))
    )
    .normalize();

  this.pitchObject.rotation.x = Math.asin(direction.y) > 0.25 ? 0.25 : Math.asin(direction.y); //rotateX;

  this.yawObject.rotation.y = Math.atan2(direction.x, direction.z) + Math.PI + deltaY;

  this.updateOrientation(true);
};

lookControlsComponent.prototype.lookAtDirection = function(direction) {
  this.pitchObject.rotation.x = Math.asin(direction.y); //rotateX;

  this.yawObject.rotation.y = Math.atan2(direction.x, direction.z) + Math.PI;

  this.updateOrientation(true);
};

lookControlsComponent.prototype.animateToPoint = function(
  point,
  callback,
  type = 'default',
  directionDelta = { x: 0, y: 0, z: 0 }
) {
  const self = this;

  if (type === 'distract') {
    let animProps = { alpha: 0.5 };

    AFRAME.ANIME({
      targets: animProps,
      alpha: 0,
      delay: 1500,
      easing: 'easeOutQuad',
      update: function(anim) {
        self.lookAtPoint(point, animProps.alpha);
      },
      complete: function(anim) {
        if (callback) callback();
      },
    });
  } else {
    let direction = new THREE.Vector3()
      .subVectors(
        this.el.object3D.parent.parent.worldToLocal(point),
        this.el.object3D.parent.localToWorld(new THREE.Vector3(0, 0, 0))
      )
      .normalize();

    direction.x += directionDelta.x;
    direction.y += directionDelta.y;
    direction.z += directionDelta.z;

    let animProps = this.el.sceneEl.camera.getWorldDirection(new THREE.Vector3());

    AFRAME.ANIME({
      targets: animProps,

      x: direction.x,
      y: direction.y,
      z: direction.z,

      delay: 1000,
      easing: 'easeOutSine',
      update: function(anim) {
        self.lookAtDirection(animProps);
      },
      complete: function(anim) {
        if (callback) callback();
      },
    });
  }
};
