import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import testImg from '../../assets/item.jpg';

import {
  Card,
  CardHeader,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

import './index.scss';
import { Link } from 'react-router-dom';
import Translate from '../Translate';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = { activeIndex: 0 };
    this.animating = false;
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;

    const { trails } = this.props;

    const nextIndex = this.state.activeIndex === trails.length - 1 ? 0 : this.state.activeIndex + 1;

    this.setState(prevState => ({
      ...prevState,
      activeIndex: nextIndex,
    }));
  };

  previous = () => {
    if (this.animating) return;

    const { trails } = this.props;

    const nextIndex = this.state.activeIndex === 0 ? trails.length - 1 : this.state.activeIndex - 1;
    this.setState(prevState => ({
      ...prevState,
      activeIndex: nextIndex,
    }));
  };

  goToIndex = newIndex => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { trails } = this.props;

    if (!trails.length) {
      // return null;
    }

    // console.log(trails);

    const { activeIndex } = this.state;

    const slides = trails.map(item => (
      <CarouselItem
        className="card card-trail w-100"
        tag="div"
        key={item.id}
        onExiting={this.onExiting}
        onExited={this.onExited}>
        <div className="card-body px-0">
          <Link to={`/${item.area.url}/trail-${item.url}`}>
            <img src={item.image_url} className="card-img card-img-top" alt={item.description} />
            <p className="card-text card-text-bottom text-left family-cuprum">{item.title}</p>
          </Link>
        </div>
      </CarouselItem>
    ));

    return (
      <Card className="p-0 border-0 card-component card-trails">
        <CardHeader className="clearfix">
          <Translate>TRAIL</Translate>
          {trails.length ? (
            <>
              {` ${activeIndex + 1}`}
              <div className="carousel-controls position-absolute">
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  className="carousel-control cursor-pointer"
                  onClickHandler={this.previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  className="carousel-control cursor-pointer"
                  onClickHandler={this.next}
                />
              </div>
            </>
          ) : null}
        </CardHeader>
        <CardBody className="position-relative">
          {trails.length ? (
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              // interval={false}
            >
              <CarouselIndicators items={trails} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
              {slides}
            </Carousel>
          ) : (
            <p className="card-text text-center font-weight-normal color-zanah family-cuprum opacity-1">
              No trails found
            </p>
          )}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    trails: state.trail.list.data,
  };
}

export default connect(mapStateToProps)(withRouter(Index));
