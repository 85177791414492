import React, { Component } from 'react';

import 'aframe';

import { Entity } from 'aframe-react';

import VRSpace from '../../aframe/vrSpace';
import VRPlayer from '../../aframe/vrPlayer';

import isElement from 'lodash/isElement';
import isString from 'lodash/isString';
import kebabCase from 'lodash/kebabCase';
import { getSpaceType } from '../../../utils';
import Loader from '../../../services/Loader';

class TourPlayer {
  constructor(options = {}) {
    this.subscribers = {};

    this.media = {
      currentTime: 0,
      duration: 0,
      loop: false,
      muted: false,
      paused: true,
      ...options,
    };

    this._registerEvents();
  }

  destroy() {
    this._unregisterEvents();
    this.emit('destroy');
    this.subscribers = {};
  }

  off(eventName, handler) {
    const list = this.subscribers[eventName];

    const index = list ? list.indexOf(handler) : -1;

    if (index !== -1) {
      this.subscribers[eventName].splice(index, 1);
    }
  }

  on(eventName, handler) {
    if (this.subscribers[eventName]) {
      this.subscribers[eventName].push(handler);
    } else {
      this.subscribers[eventName] = [handler];
    }
  }

  emit(eventName, data, ...args) {
    const list = this.subscribers[eventName];
    if (list) {
      list.map(fn => fn.call(this.media, data, ...args));
    }
  }

  setSrc() {}

  setDuration(duration = '') {
    this.media.duration = duration;
    this.setCurrentTime(0);

    // this.emit("state-change:src", {
    //   currentTime: this.media.currentTime,
    //   duration: this.media.duration,
    //   prevSrc: this.media.src
    // });

    this.__handleReady();
  }

  play() {
    this.media.paused = false;

    this.timerId = setInterval(this.__handleTimeupdate, 1000);

    this.__handlePlaying();
    // return this.media.play();
  }

  pause() {
    this.media.paused = true;

    clearInterval(this.timerId);

    this.__handlePause();
    // return this.media.pause();
  }

  getPaused() {
    return this.media.paused;
  }

  getCurrentTime() {
    return this.media.currentTime;
  }

  setCurrentTime(time) {
    this.media.currentTime = time;
    this.emit('state-change:time');

    this.emit('timeupdate', {
      seconds: this.media.currentTime,
      duration: this.media.duration,
    });
  }

  getDuration() {
    return this.media.duration;
  }

  getVolume() {
    // return this.media.currentTime;
  }

  setVolume(value) {
    // this.media.volume = value / 100;
    // this.emit("state-change:volume");
  }

  mute() {
    this.media.muted = true;
    this.emit('state-change:mute');
  }

  unmute() {
    this.media.muted = false;
    this.emit('state-change:mute');
  }

  getMuted() {
    return this.media.muted;
  }

  getLoop() {
    return this.media.loop;
  }

  setLoop(value) {
    this.media.loop = value;
    this.emit('state-change:loop');
  }

  __handleReady = evt => {
    this.emit('ready');
    this.__handleTimeupdate();
  };

  __handleError = evt => {
    this.emit('error', evt);
  };

  __handlePlaying = () => {
    this.emit('play');
  };

  __handlePause = () => {
    this.emit('pause');
  };

  __handleEnded = () => {
    this.emit('ended');

    this.media.currentTime = 0;
  };

  __handleTimeupdate = () => {
    if (this.media.currentTime >= this.media.duration) {
      this.__handleEnded();
      return this.pause();
    }

    this.media.currentTime += 1;

    this.emit('timeupdate', {
      seconds: this.media.currentTime,
      duration: this.media.duration,
    });
  };

  __handleProgress = () => {
    // this.emit("buffered", {
    //   percent: this.media.buffered.length
    // });
  };

  _registerEvents() {
    this.__handleReady();
  }

  _unregisterEvents() {
    clearInterval(this.timerId);
  }
}

const SLIDE_DURATION = 5000;

class VRTourExperience extends Component {
  constructor(props) {
    super(props);

    this.player = new TourPlayer();

    this.imagesCache = [];

    this.loader = 'VRTourExperience';

    this.state = {
      activeIndex: 0,
    };
  }

  componentDidMount() {
    const points = this.props.data ? this.props.data.points : undefined;
    this.loadImages(points);

    this.player.on('timeupdate', this._handleTick);
  }

  componentWillUnmount() {
    Loader.hide(this.loader);
    this.player.destroy();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.id !== prevProps.data.id) {
      this.loadImages(this.props.data.points);
    }
  }

  _handleTick = ({ seconds, duration }) => {
    const newIndex = Math.floor(seconds / (SLIDE_DURATION / 1000));

    // console.log("newIndex", newIndex);

    if (this.state.activeIndex !== newIndex) {
      this.setState({ activeIndex: newIndex });
    }
  };

  loadImages = (points = []) => {
    this.loader = Loader.show(this.loader);
    Loader.addToGroup(this.loader, 'vrScene');

    Promise.all(
      points
        .map((pt, index) => {
          const ptType = getSpaceType(pt);

          const ptData = ptType === 'photo' ? pt.box : pt.video_urls;

          this.imagesCache[index] = {
            type: 'photo',
            data: {},
          };

          if (ptType === 'photo') {
            return Object.keys(ptData).map(key => {
              return new Promise((res, rej) => {
                const timg = new Image();
                timg.crossOrigin = 'anonymous';
                // timg.onloadstart = this.handleImageLoadStart;
                timg.onload = res;
                timg.onerror = rej;
                timg.src = ptData[key];

                this.imagesCache[index].data[key] = timg;
              });
            });
          } else {
            return new Promise((res, rej) => {
              this.imagesCache[index].data = ptData;
              res();
            });
          }
        })
        .flat()
    ).then(vls => {
      Loader.hide(this.loader);
      this.forceUpdate();
    });

    this.player.setDuration(points.length * (SLIDE_DURATION / 1000));

    this.forceUpdate();
  };

  render() {
    const { setSpacePlayer, showPlayer } = this.props;

    if (!this.imagesCache.length) {
      return null;
    }

    const activeIndex = Math.min(this.state.activeIndex, this.imagesCache.length - 1);

    const spaceData = this.imagesCache[activeIndex].data;
    const pointType = this.imagesCache[activeIndex].type;

    return (
      <>
        <VRSpace data={spaceData} space={pointType} setPlayer={setSpacePlayer} />

        <Entity className="scene-wrapper" position="0 2 0">
          <VRPlayer
            rotation={{ x: -10 }}
            position={{ y: -1, z: -2 }}
            placement={{ refresh: Date.now() }}
            visible={showPlayer}
            player={this.player}
            target="slideshow"
          />
        </Entity>
      </>
    );
  }
}

export default VRTourExperience;
