import createAction from './create-action';

export const SET_SKY_ELEMENT = 'SET_SKY_ELEMENT';
export const SET_MAP_ACTIVE_AREA = 'SET_MAP_ACTIVE_AREA';
export const SET_MAP_ELEMENT = 'SET_MAP_ELEMENT';
export const SET_SPACE_PLAYER = 'SET_SPACE_PLAYER';

export const setMapActiveArea = data => dispatch => {
  dispatch(createAction(SET_MAP_ACTIVE_AREA, data));
};

export const setMapElement = data => dispatch => {
  dispatch(createAction(SET_MAP_ELEMENT, data));
};

export const setSpacePlayer = data => dispatch => {
  dispatch(createAction(SET_SPACE_PLAYER, data));
};

export const setSkyElement = data => dispatch => {
  dispatch(createAction(SET_SKY_ELEMENT, data));
};
