import { registerComponent, THREE } from 'aframe';
import 'aframe-extras';

export default registerComponent('model-loader', {
  schema: {
    src: { type: 'model', default: '' },
    type: { type: 'string', default: 'glb' },
  },
  init: function() {
    const dracoLoader = this.system && this.system.getDRACOLoader();
    this.object3D = this.el.object3D;
    this.model = null;

    if (dracoLoader) {
      this.loader.setDRACOLoader(dracoLoader);
    }
  },
  update: function() {
    let { src, type } = this.data;
    if (!src) {
      return;
    }

    this.remove();

    switch (type) {
      case 'glb':
      case 'gltf':
        this._loadGLTFModel(src);
        break;
      default:
        break;
    }
  },

  remove: function() {
    if (!this.model) {
      return;
    }
    this.el.removeObject3D('mesh');
  },

  _loadGLTFModel: function(url) {
    // Instantiate a loader
    const loader = new THREE.GLTFLoader();

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    //     THREE.DRACOLoader.setDecoderPath('/examples/js/libs/draco');
    //     loader.setDRACOLoader(new THREE.DRACOLoader());

    // Optional: Pre-get Draco WASM/JS module, to save time while parsing.
    //     THREE.DRACOLoader.getDecoderModule();

    // Load a glTF resource
    loader.load(
      // resource URL
      url,
      // called when the resource is loaded
      gltfModel => {
        this.model = gltfModel.scene || gltfModel.scenes[0];
        this.model.animations = gltfModel.animations;
        this.el.setObject3D('mesh', this.model);
        this.el.emit('model-loaded', { format: 'gltf', model: this.model });
      },
      // called while loading is progressing
      xhr => {
        /*console.log(
          "_loadGLTFModel loading: ",
          (xhr.loaded / xhr.total) * 100,
          "% loaded"
        );*/
      },
      // called when loading has errors
      error => {
        console.log('_loadGLTFModel: An error happened', error);
        this.el.emit('model-error', { format: 'gltf', src: url });
      }
    );
  },
});
