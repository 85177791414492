import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */
export const GET_TRAIL = 'GET_TRAIL';
export const GET_TRAILS = 'GET_TRAILS';

/**
 * THUNKS
 */
export const getTrails = areaId => dispatch => {
  return dispatch(
    createAction(GET_TRAILS, ApiService.getTrails(areaId), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getTrail = trailId => dispatch => {
  return dispatch(
    createAction(GET_TRAIL, ApiService.getTrail(trailId), {
      __PARSABLE_ERROR: true,
    })
  );
};
