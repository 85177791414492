import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/arrow-left.svg';
import TrailDetails from '../../components/Modals/TrailDetails';
import Translate from '../Translate';

class TrailMenu extends Component {
  render() {
    const { trail } = this.props;

    return (
      <nav className="sub-menu position-absolute p-0">
        <ul className="list-group">
          <li className="list-group-item sub-menu-breadcrumb clearfix">
            <Link className="nav-link" to={`/${trail?.area?.url}/${trail?.area?.default_point?.url}`}>
              <Arrow className="mr-1" />
              <Translate>Back to point</Translate>
            </Link>
          </li>
          <li className="navbar list-group-item">
            <ul className="nav flex-column">
              <li className="nav-item">
                <div className="media pb-3 clearfix">
                  <div className="media-body family-cuprum font-weight-bold color-zanah">{trail.title}</div>
                </div>
              </li>
              <li className="nav-item p-0">
                <TrailDetails
                  buttonClass="btn d-block p-3 w-100 btn-secondary font-size-14"
                  buttonLabel={<Translate>TRAIL DETAILS</Translate>}
                />
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    trail: state.trail.current.data,
  };
}

export default connect(mapStateToProps)(TrailMenu);
