/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';

import { Form, Field } from 'react-final-form';
import Validator, { composeValidators } from '../services/Validator';
import Translate, { translateManually } from './Translate';
import { withLocalize } from 'react-localize-redux';
import ApiService from '../services/api-service';

class ContactForm extends Component {
  state = {
    successMessage: null,
    errorMessage: null,
  };

  render() {
    const { successMessage, errorMessage } = this.state;

    return (
      <div className="d-block">
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <h2 className="text-left text-capitalize w-100">
                  <Translate>Send us a message</Translate>
                </h2>
              </div>

              <Field name="name" validate={Validator.required}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="contact_form_person_name">
                        <Translate>Your full name</Translate>
                      </label>
                      <input
                        {...input}
                        type="text"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="contact_form_person_name"
                        placeholder={translateManually({ text: 'Contact name' }, this.props.translate)}
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="email" validate={composeValidators(Validator.required, Validator.email)}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100">
                      <label htmlFor="contact_form_email">
                        <Translate>Email</Translate>
                      </label>
                      <input
                        {...input}
                        type="email"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="contact_form_email"
                        placeholder="e.g. john.smith@gmail.com"
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="phone_number" validate={composeValidators(Validator.phone)}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100 w-100">
                      <label htmlFor="contact_form_phone_number">
                        <Translate>Phone</Translate>
                      </label>
                      <input
                        {...input}
                        type="text"
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="contact_form_phone_number"
                        placeholder="e.g. +374 93 852323"
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name="text" validate={composeValidators(Validator.required)}>
                {({ input, meta }) => (
                  <div className="form-row mx-0">
                    <div className="form-group w-100 w-100">
                      <label htmlFor="contact_form_text">
                        <Translate>Your message</Translate>
                      </label>
                      <textarea
                        {...input}
                        style={{ minHeight: '100px', maxHeight: '400px' }}
                        className={`form-control form-control-lg ${meta.touched &&
                          (meta.error ? 'is-invalid' : 'is-valid')}`}
                        id="contact_form_text"
                        placeholder={translateManually({ text: 'Please type your message here' }, this.props.translate)}
                        required
                      />
                      <div className="invalid-feedback">
                        <Translate>{meta.error}</Translate>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <div className="form-row mx-0 mt-3">
                <button
                  type="submit"
                  className="btn btn-secondary text-uppercase mx-auto p-3 font-size-19 w-75"
                  disabled={submitting}>
                  <Translate>Send</Translate>
                </button>
              </div>

              <div className="d-block mt-3">
                {successMessage ? (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                ) : null}

                {errorMessage ? (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                ) : null}
              </div>
            </form>
          )}
        />
      </div>
    );
  }

  handleSubmit = ({ text, email, name, phone_number }, form) => {
    ApiService.sendMessage({
      name,
      email,
      phone: phone_number,
      message: text,
    })
      .then(data => {
        this.setState({
          successMessage: <Translate>Your message successfully received.</Translate>,
          errorMessage: null,
        });
        form.pauseValidation();
        form.reset();

        // form.resumeValidation();
      })
      .catch(data => {
        this.setState({
          successMessage: null,
          errorMessage: <Translate>Something has gone wrong.</Translate>,
        });
      });
  };
}

export default withLocalize(ContactForm);
