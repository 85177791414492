import './index.scss';

import 'aframe';
import 'aframe-extras';

import React from 'react';
import { AppContainer } from 'react-hot-loader';

// import ReactDOM from 'react-dom';
import { render as DOMRender } from 'react-snapshot';

import ErrorBoundary from './components/error-boundary';

import Root from './routes';
import store, { history } from './config/redux-store';
import * as serviceWorker from './serviceWorker';

const render = () => {
  DOMRender(
    <AppContainer>
      <ErrorBoundary>
        <Root history={history} store={store} />
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('App-root')
  );
};

render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./routes', () => render());
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
